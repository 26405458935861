import React, { useRef, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CInputGroup,
    CInputGroupText,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../components/PaginationFilter'
import PaginationBar from '../components/PaginationBar'
import { IPagingParam, pagingParamInitValue } from '../interface/pagination/IPagingParam'
import FilterDropDown from '../components/FilterDropDown'
import { verificationFilter } from '../util/converter/VerificationConverter'
import { Link } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from '../util/api'
import { ICertificationInfoResponse } from '../interface/IVerficationResponse'
import LoadingButton from '../components/button/LoadingButton'

const Verification = () => {
    const queryClient = useQueryClient()

    const corpNoRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const corpNameRef = useRef<HTMLInputElement>(null)

    const [partnerId, setPartnerId] = useState<string>('')
    const [certificationNo, setCertificationNo] = useState<string>('')

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [fetchingUrl, setFetchingUrl] = useState<string>('api/verification/certification?checked=false&')

    const [visible, setVisible] = useState<boolean>(false)

    const { data } = useQuery(['verification', fetchingUrl, pagingParam.size, pagingParam.page], () => {
        return axios.get(`${fetchingUrl}size=${pagingParam.size}&page=${pagingParam.page}`)
    })

    const list: ICertificationInfoResponse[] = data?.data.body
    const paginationDto = data?.data.body.pagination

    const search = useMutation(
        'search',
        (type: string) => {
            let body
            if (type === 'email') {
                body = { email: emailRef.current?.value.trim() }
            } else if (type === 'corpNo') {
                body = { corpNo: corpNoRef.current?.value.trim() }
            } else {
                body = { corpName: corpNameRef.current?.value.trim() }
            }
            return axios.post('api/verification/search', body)
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    queryClient.setQueryData(
                        ['verification', fetchingUrl, pagingParam.size, pagingParam.page],
                        (old: any) => {
                            return {
                                ...old,
                                data: {
                                    ...old.data,
                                    pagination: null,
                                    body: [data.data.body],
                                },
                            }
                        },
                    )
                }
            },
        },
    )

    const updateCertificationNo = useMutation(
        'setCertificationNo',
        () => {
            return axios.put('api/verification/certification-checked', {
                partnerId: partnerId,
                certificationNo: certificationNo,
            })
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    setVisible(false)
                    queryClient.invalidateQueries([
                        'verification',
                        fetchingUrl,
                        pagingParam.size,
                        pagingParam.page,
                    ])
                }
            },
        },
    )

    return (
        <>
            <div>
                <div className='d-flex flex-wrap'>
                    <CInputGroup className='mb-3 ' style={{ width: '300px' }}>
                        <CInputGroupText style={{ width: '100px' }}>사업자번호</CInputGroupText>
                        <CFormInput placeholder='사업자번호로 검색' ref={corpNoRef} />
                        <CButton
                            className='fw-bold '
                            color='primary'
                            style={{ width: '55px' }}
                            onClick={() => search.mutate('corpNo')}>
                            검색
                        </CButton>
                    </CInputGroup>
                    <CInputGroup className='mb-3 mx-3' style={{ width: '400px' }}>
                        <CInputGroupText style={{ width: '100px' }}>유저 Email</CInputGroupText>
                        <CFormInput placeholder='사용자 Email로 검색' ref={emailRef} />
                        <CButton
                            className='fw-bold'
                            color='primary'
                            style={{ width: '55px' }}
                            onClick={() => search.mutate('email')}>
                            검색
                        </CButton>
                    </CInputGroup>
                    <CInputGroup className='mb-3 me-3' style={{ width: '400px' }}>
                        <CInputGroupText style={{ width: '100px' }}>사업체명</CInputGroupText>
                        <CFormInput placeholder='사업체명으로 검색' ref={corpNameRef} />
                        <CButton
                            className='fw-bold'
                            color='primary'
                            style={{ width: '55px' }}
                            onClick={() => search.mutate('corpName')}>
                            검색
                        </CButton>
                    </CInputGroup>
                    <CCol xs='auto'>
                        <CButton
                            color='secondary'
                            onClick={() => {
                                queryClient.invalidateQueries([
                                    'verification',
                                    fetchingUrl,
                                    pagingParam.size,
                                    pagingParam.page,
                                ])
                            }}>
                            리스트 초기화
                        </CButton>
                    </CCol>
                </div>
            </div>

            {/** 리스트 부분 */}
            <CCard className='mb-4'>
                <CCardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <input
                            className='fs-3 fw-4 border-0'
                            value={'건설업인증 관리'}
                            readOnly={true}
                            disabled={true}
                        />
                        <div className='col-2 d-flex flex-column justify-content-end my-3'>
                            <PaginationFilter
                                color='secondary'
                                selected={pagingParam.size}
                                page={pagingParam.page}
                                onClick={setPagingParam}
                            />
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CTable striped hover>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col-3'>email</CTableHeaderCell>
                                <CTableHeaderCell scope='col-3'>사업체명</CTableHeaderCell>
                                <CTableHeaderCell scope='col-2'>연락처</CTableHeaderCell>
                                <CTableHeaderCell scope='col-2'>사업자번호</CTableHeaderCell>
                                <CTableHeaderCell scope='col-1'>
                                    <FilterDropDown
                                        name='여부'
                                        items={verificationFilter}
                                        requestUrl='api/verification/certification?checked='
                                        onClick={setFetchingUrl}></FilterDropDown>
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col-1'>
                                    {fetchingUrl.includes('checked=true') ? '면허번호' : '인증처리'}
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>

                        {list.length === 0 ? (
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell colSpan={7}>목록이 없습니다.</CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        ) : (
                            <CTableBody>
                                {list.map((partner) => (
                                    <CTableRow key={partner.id}>
                                        <CTableDataCell>
                                            <Link to={`/partner/${partner.id}`}>{partner.email}</Link>
                                        </CTableDataCell>

                                        <CTableDataCell>{partner.corpName}</CTableDataCell>
                                        <CTableDataCell>{partner.phoneNumber}</CTableDataCell>
                                        <CTableDataCell>{partner.corpNumber}</CTableDataCell>
                                        {partner.certificationNo == 'requested' || null ? (
                                            <CTableDataCell className='text-danger'>인증요청</CTableDataCell>
                                        ) : (
                                            <CTableDataCell>인증완료</CTableDataCell>
                                        )}
                                        <CTableDataCell>
                                            {partner.certificationNo == 'requested' ? (
                                                <CButton
                                                    className='fw-bold text-white'
                                                    color='info'
                                                    onClick={() => {
                                                        setPartnerId(partner.id)
                                                        setVisible(true)
                                                    }}>
                                                    인증처리
                                                </CButton>
                                            ) : (
                                                <span>{partner.certificationNo}</span>
                                            )}
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        )}
                    </CTable>
                    <PaginationBar {...paginationDto} size={pagingParam.size} onClick={setPagingParam} />
                </CCardBody>
            </CCard>
            <CModal
                alignment='center'
                visible={visible}
                onClose={() => {
                    setVisible(false)
                    setPartnerId('')
                    setCertificationNo('')
                }}>
                <CModalHeader>
                    <CModalTitle>건설업인증 번호 등록</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className='d-flex flex-column'>
                        <CForm>
                            <div>
                                <CFormLabel htmlFor='title'>건설업인증 번호를 등록해 주세요</CFormLabel>
                                <CFormInput
                                    type='text'
                                    id='title'
                                    name='title'
                                    onChange={(e) => {
                                        setCertificationNo(e.target.value)
                                    }}
                                />
                            </div>
                        </CForm>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setVisible(false)
                        }}>
                        취소
                    </CButton>
                    <LoadingButton
                        isLoading={updateCertificationNo.isLoading}
                        color='primary'
                        onClick={() => updateCertificationNo.mutate()}>
                        인증처리
                    </LoadingButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default Verification
