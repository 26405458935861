import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { CButton } from '@coreui/react'

interface IExcelDownloadButtonProps {
    csvData: string
    fileName: string
    className?: string
    disabled?: boolean
}

const ToExcelButton = ({ csvData, fileName, className = '', disabled }: IExcelDownloadButtonProps) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const flatObject = (obj: { [key: string]: any }, target: { [key: string]: string }, prefix: string) => {
        if (obj === null) {
            return
        }
        for (const [key, value] of Object.entries(obj)) {
            if (key === '__typename') continue
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    value.map((d) => {
                        if (typeof d === 'object') {
                            return flatObject(d, target, `${prefix}${key}.`)
                        } else {
                            return (target[`${prefix}${key}`] = d)
                        }
                    })
                } else {
                    flatObject(value, target, `${prefix}${key}.`)
                }
            } else {
                if (target[`${prefix}${key}`] !== undefined) {
                    target[`${prefix}${key}`] = target[`${prefix}${key}`] + `, ${value}`
                } else {
                    target[`${prefix}${key}`] = value
                }
            }
        }
    }

    const exportToCSV = ({ csvData, fileName }: IExcelDownloadButtonProps) => {
        const flattenData: { [key: string]: string }[] = []
        const parseData: { [key: string]: any }[] = JSON.parse(csvData)
        parseData.map((data) => {
            const flattenObject: { [key: string]: string } = {}
            flatObject(data, flattenObject, '')
            return flattenData.push(flattenObject)
        })
        const ws = XLSX.utils.json_to_sheet(flattenData)
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }
    return (
        <>
            <CButton
                disabled={disabled}
                className={className}
                onClick={() => exportToCSV({ csvData, fileName })}>
                엑셀 다운로드
            </CButton>
        </>
    )
}

export default ToExcelButton
