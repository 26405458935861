import { CTooltip } from '@coreui/react'

const SimpleRequestBadge = () => {
    return (
        <CTooltip content={'수리보수/시공만 요청입니다.'}>
            <div
                className={'btn btn-sm btn-success text-white fw-bold py-0 mx-1'}
                role={'textbox'}
                aria-label={'수리보수/시공만 요청입니다.'}
                style={{ cursor: 'default' }}>
                시공
            </div>
        </CTooltip>
    )
}

export default SimpleRequestBadge
