import { PointType } from '../interface/IPointResponse'

export const phoneNumberFormatter = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
}

export const currencyFormatter = (amount: string) => {
    return Intl.NumberFormat().format(Number(amount)) + ' 원'
}

export const asPeriodFormatter = (asYear: string, asMonth: string) => {
    return `${asYear === null || asYear === '' || asYear === '0' ? '' : `${asYear}년`}
     ${asMonth === null || asMonth === '' || asMonth === '0' ? '' : `${asMonth}개월`}`
}

export const carrierFormatter = (year: string, month: string) => {
    return `${year === null || year === '' || year === '0' ? '' : `${year}년`}
     ${month === null || month === '' || month === '0' ? '' : `${month}개월`}`
}

export const cardNumberFormatter = (cardNo: string) => {
    return `${cardNo.slice(0, 4)}-${cardNo.slice(4, 8)}-${cardNo.slice(8, 12)}-${cardNo.slice(12, 16)}`
}

export const installmentMonth = (month: string) => {
    if (month === '0') {
        return '일시불'
    } else {
        return month + '개월'
    }
}

export const pointTypeDescriptionFormatter = (state: PointType, description: string) => {
    switch (state) {
        case 'PURCHASE':
            return ' - 구매'
        case 'EVENT':
            return ' - 이벤트 ' + description
        case 'EXPIRED':
            return ' - 만료'
        case 'USE':
            return ' - '
        case 'ADJUST':
            return ' - 조정 ' + description
        default:
            return ''
    }
}
