import React from 'react'
import { CContainer, CHeader, CHeaderNav, CNavItem, CNavLink } from '@coreui/react'

const AppHeader = () => {
    return (
        <CHeader position='sticky' className='mb-4'>
            <CContainer fluid>
                <CHeaderNav className='d-none d-md-flex me-auto'>
                    <CNavItem>
                        <CNavLink href='https://www.popbill.com/' target='_blank' rel='noopener noreferrer'>
                            팝빌
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink
                            href='https://office.easypay.co.kr/'
                            target='_blank'
                            rel='noopener noreferrer'>
                            이지페이 관리자
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink href='https://www.toast.com/kr' target='_blank' rel='noopener noreferrer'>
                            NHN 클라우드
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink
                            href='https://partner.danalpay.com/'
                            target='_blank'
                            rel='noopener noreferrer'>
                            다날
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink href='http://kiscon.net/' target='_blank' rel='noopener noreferrer'>
                            국토교통부
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink href='https://bizno.net/' target='_blank' rel='noopener noreferrer'>
                            bisNo.net
                        </CNavLink>
                    </CNavItem>
                </CHeaderNav>
            </CContainer>
        </CHeader>
    )
}

export default AppHeader
