import React, { useState } from 'react'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import axios from '../../util/api'
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CTable,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { dayjs } from '../../util/dayjs'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { IReviewListForUserPage } from '../../interface/IReviewResponse'
import ImagePreviewList from '../../components/imgae/ImagePreviewList'

interface IUserReviewsProps {
    id: string | undefined
}

const UserReviews = ({ id }: IUserReviewsProps) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [reviewContent, setReviewContent] = useState<{ content: string; images: string[] }>({
        content: '',
        images: [],
    })

    const { data } = useQuery(['userReviews', id, pagingParam.page, pagingParam.size], () => {
        return axios.get(`/api/review/list-of/${id}?page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const list: IReviewListForUserPage[] = data?.data.body
    const pagination: IPagination = data?.data.pagination

    return (
        <div className='d-flex flex-column justify-content-center w-100'>
            <div className='d-flex justify-content-between mb-3 col-12'>
                <div className='col-3'>
                    <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
                </div>
                <div className='col-1 my-3 me-5'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-2'>
                                후기 등록일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                요청서
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                견적서
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래업체
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                점수
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                내용
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <tbody>
                        {list ? (
                            list.map((item) => (
                                <CTableRow key={item.id}>
                                    <CTableDataCell className='col-2'>
                                        {dayjs(item.createdAt).format('YYYY-MM-DD')}
                                    </CTableDataCell>
                                    <CTableDataCell className='col-2'>
                                        <div
                                            className='d-inline-block text-truncate'
                                            style={{ maxWidth: '100px' }}>
                                            <Link to={`/request/${item.requestId}`}>{item.requestId}</Link>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell className='col-2'>
                                        <div
                                            className='d-inline-block text-truncate'
                                            style={{ maxWidth: '100px' }}>
                                            <Link to={`/estimates/${item.estimateId}`}>
                                                {item.estimateId}
                                            </Link>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell className='col-2'>{item.corpName}</CTableDataCell>
                                    <CTableDataCell className='col-1'>{item.score}</CTableDataCell>
                                    <CTableDataCell
                                        className='col-3'
                                        onClick={() => {
                                            setVisible(true)
                                            setReviewContent({
                                                content: item.content,
                                                images: item.images,
                                            })
                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        {item.content.substring(0, 10) + '...'}
                                    </CTableDataCell>
                                </CTableRow>
                            ))
                        ) : (
                            <CTableRow>
                                <CTableDataCell colSpan={6}>작성된 리뷰가 없습니다.</CTableDataCell>
                            </CTableRow>
                        )}
                    </tbody>
                </CTable>
            </div>
            {/*모달*/}
            <div>
                <CModal alignment='center' visible={visible} onClose={() => setVisible(false)}>
                    <CModalHeader />
                    <CModalBody>
                        <div className={'d-flex justify-content-center rounded my-2 p-3'}>
                            {reviewContent.content}
                        </div>
                        {reviewContent.images && reviewContent.images.length > 0 && (
                            <ImagePreviewList
                                images={reviewContent.images.map((image, index) => {
                                    return { id: String(index), url: image }
                                })}
                            />
                        )}
                    </CModalBody>
                    <CModalFooter>
                        <CButton color='secondary' onClick={() => setVisible(false)}>
                            닫기
                        </CButton>
                    </CModalFooter>
                </CModal>
            </div>
        </div>
    )
}

export default UserReviews
