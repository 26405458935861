import React, { useEffect, useRef, useState } from 'react'
import axios from '../../util/api'
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import FilterDropDown from '../../components/FilterDropDown'
import { dayjs } from '../../util/dayjs'
import PaginationBar from '../../components/PaginationBar'
import { Link } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPagingParam } from '../../interface/pagination/IPagingParam'
import { IUserListResponse } from '../../interface/IUserResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import LoadingButton from '../../components/button/LoadingButton'

const User = () => {
    const queryClient = useQueryClient()

    const emailRef = useRef<HTMLInputElement>(null)
    const phoneRef = useRef<HTMLInputElement>(null)

    const [fetchingUrl, setFetchingUrl] = useState<string>('/api/user/list?')
    const [pagingParam, setPagingParam] = useState<IPagingParam>({
        page: '0',
        size: '10',
    })

    const { data } = useQuery(
        ['userList', fetchingUrl, pagingParam.page, pagingParam.size],
        () => {
            return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    )

    const list: IUserListResponse[] = data?.data.body
    const pagination: IPagination = data?.data.pagination ?? null

    const searchUser = useMutation(
        'searchUser',
        (paramType: string) => {
            let body: any = {}
            switch (paramType) {
                case 'email':
                    body = { email: emailRef.current?.value }
                    break
                case 'phoneNumber':
                    body = { phoneNumber: phoneRef.current?.value }
                    break
            }
            return axios.post('api/user/search', body)
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    ['userList', fetchingUrl, pagingParam.page, pagingParam.size],
                    (old: any) => {
                        return {
                            ...old,
                            data: {
                                ...old.data,
                                pagination: null,
                                body: data.data.body,
                            },
                        }
                    },
                )
            },
        },
    )

    useEffect(() => {
        data?.data.pagination &&
            setPagingParam({ ...pagingParam, page: String(Number(data?.data.pagination.number - 1)) })
    }, [data])

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>고객목록</h1>
            </div>

            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={emailRef} type='text' placeholder='email로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <LoadingButton
                                    type='button'
                                    className='mb-3'
                                    isLoading={searchUser.isLoading}
                                    onClick={() => searchUser.mutate('email')}>
                                    검색
                                </LoadingButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CFormInput ref={phoneRef} type='text' placeholder='전화번호로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <LoadingButton
                                    type='button'
                                    className='mb-3'
                                    isLoading={searchUser.isLoading}
                                    onClick={() => searchUser.mutate('phoneNumber')}>
                                    검색
                                </LoadingButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CButton
                                    color='secondary'
                                    onClick={() =>
                                        queryClient.invalidateQueries([
                                            'userList',
                                            fetchingUrl,
                                            pagingParam.page,
                                            pagingParam.size,
                                        ])
                                    }>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-2'>
                                고객명
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-3'>
                                email
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-3'>
                                연락처
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                <FilterDropDown
                                    name='상태'
                                    items={[
                                        { name: '활성', value: 'ACTIVE' },
                                        { name: '비활성', value: 'INACTIVE' },
                                    ]}
                                    requestUrl='/api/user/filter?state='
                                    onClick={setFetchingUrl}
                                />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                가입일
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item) => (
                            <CTableRow key={item.id}>
                                <CTableDataCell>
                                    <Link to={`/user/${item.id}`}>{item.name}</Link>
                                </CTableDataCell>
                                <CTableDataCell>{item.email}</CTableDataCell>
                                <CTableDataCell>{item.phoneNumber}</CTableDataCell>
                                <CTableDataCell>
                                    {item.state === 'PENDING'
                                        ? 'PENDING'
                                        : item.state === 'ACTIVE'
                                        ? '활성'
                                        : item.state === 'INACTIVE'
                                        ? '비활성'
                                        : '탈퇴'}
                                </CTableDataCell>
                                <CTableDataCell>{dayjs(item.createdAt).format('YY.MM.DD')}</CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>

            {/*페이징 indicator*/}
            {pagination && <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />}
        </div>
    )
}

export default User
