import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import { dayjs } from '../../util/dayjs'
import { Link } from 'react-router-dom'
import PaginationBar from '../../components/PaginationBar'
import React, { useEffect, useRef, useState } from 'react'
import axios from '../../util/api'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import ToExcelButton from '../../components/ToExcelButton'
import LabelAboveInput from '../../components/LabelAboveInput'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    defaultCardInfo,
    ICardInfoResponse,
    IPaymentListResponse,
    IVirtualInfoResponse,
} from '../../interface/IPaymentResponse'
import { currencyFormatter } from '../../util/formatter'
import PaymentRefundModal from '../../components/modal/PaymentRefundModal'

const Payment = () => {
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [exelData, setExelData] = useState<string>('')
    // 결제수단모달
    const [visibleOfPayMethod, setVisibleOfPayMethod] = useState<boolean>(false)
    const [payMethodModalData, setPayMethodModalData] = useState<ICardInfoResponse | IVirtualInfoResponse>(
        defaultCardInfo,
    )
    // 강제환불모달
    const [visibleOfRefund, setVisibleOfRefund] = useState<boolean>(false)
    const refundDataInit = {
        paymentId: '',
        pgCno: '',
        cancelPgCno: '',
        usedPoint: 0,
    }
    const [refundData, setRefundData] = useState<{
        paymentId: string
        pgCno: string
        cancelPgCno: string
        usedPoint: number
    }>(refundDataInit)

    const emailRef = useRef<HTMLInputElement>(null)

    const { data } = useQuery(
        ['paymentList', pagingParam.page, pagingParam.size],
        () => {
            return axios.get(`/api/payment/list?page=${pagingParam.page}&size=${pagingParam.size}`)
        },
        {
            keepPreviousData: true,
        },
    )

    const searchByEmail = useMutation(
        'searchByEmail',
        (email: string) => {
            return axios.post('/api/payment/search', { email: email })
        },
        {
            onSuccess: (res) => {
                queryClient.setQueryData(['paymentList', pagingParam.page, pagingParam.size], (old: any) => {
                    return {
                        ...old,
                        data: {
                            pagination: null,
                            body: res.data.body,
                        },
                    }
                })
            },
        },
    )

    const pagination = data?.data.pagination
    const list: IPaymentListResponse[] = data?.data.body

    useEffect(() => {
        if (data) {
            setExelData(JSON.stringify(data.data.body))
        }
    }, [data])

    return (
        <div className='container d-flex flex-column'>
            <div className='d-flex justify-content-between'>
                <h1>결제 관리</h1>
                <div>
                    <ToExcelButton csvData={exelData} fileName={'결제 내역'} />
                </div>
            </div>
            <p>관리자권한으로 환불처리를 하시려면 해당 결제id(pgCno)를 클릭하세요</p>

            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={emailRef} type='text' placeholder='고객 email로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => searchByEmail.mutate(emailRef.current?.value ?? '')}>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CButton
                                    color='secondary'
                                    onClick={() =>
                                        queryClient.invalidateQueries([
                                            'paymentList',
                                            pagingParam.page,
                                            pagingParam.size,
                                        ])
                                    }>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col'>갱신일</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>고객 email</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>대장 id</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>금액</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>요청 msg</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>상태</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>결제일</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>결제수단</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>결제 id(pgCno)</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>포인트사용/쿠폰사용</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>요청서</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item) => (
                            <CTableRow key={item.id}>
                                <CTableDataCell>{dayjs(item.updatedAt).format('YY.MM.DD')}</CTableDataCell>
                                <CTableDataCell>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '100px' }}>
                                        <Link to={`/user/${item.customerId}`}>{item.customerEmail}</Link>
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '100px' }}>
                                        <Link to={`/partner/${item.partnerId}`}>{item.providerName}</Link>
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>{currencyFormatter(item.amount)}</CTableDataCell>
                                <CTableDataCell>{item.statusMessage}</CTableDataCell>

                                <CTableDataCell>{item.resMsg}</CTableDataCell>
                                <CTableDataCell>{dayjs(item.approvalDate).format('YY.MM.DD')}</CTableDataCell>
                                <CTableDataCell>
                                    <a
                                        href='#'
                                        onClick={() => {
                                            setPayMethodModalData(
                                                item.paymentMethod === '카드'
                                                    ? item.cardInfo
                                                    : item.virtualAccountInfo,
                                            )
                                            setVisibleOfPayMethod(true)
                                        }}>
                                        {' '}
                                        {item.cardInfo ? '카드' : '에스크로'}
                                    </a>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <a
                                        href='#'
                                        onClick={() => {
                                            setRefundData({
                                                paymentId: item.id,
                                                pgCno: item.pgCno,
                                                cancelPgCno: '',
                                                usedPoint: Number.isNaN(item.usedPoint) ? 0 : item.usedPoint,
                                            })
                                            setVisibleOfRefund(true)
                                        }}>
                                        {' '}
                                        {item.pgCno}{' '}
                                    </a>
                                </CTableDataCell>
                                <CTableDataCell>
                                    {Number.isNaN(item.usedPoint) || item.usedPoint.toString().includes('c')
                                        ? 0
                                        : item.usedPoint}
                                    <br /> {Number.isNaN(item.usedCouponAmount) ? 0 : item.usedCouponAmount}
                                </CTableDataCell>
                                <CTableDataCell>
                                    <Link to={`/request/${item.requestId}`}>보기</Link>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>

            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
            {/*결제수단 모달*/}
            <CModal
                alignment='center'
                visible={visibleOfPayMethod}
                onClose={() => {
                    setVisibleOfPayMethod(false)
                    setPayMethodModalData(defaultCardInfo)
                }}>
                {payMethodModalData.hasOwnProperty('cardNo') && (
                    <>
                        <CModalHeader>
                            <CModalTitle>카드정보</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <LabelAboveInput
                                label={'카드번호'}
                                col={12}
                                value={'cardNo' in payMethodModalData ? payMethodModalData.cardNo : ''}
                            />
                            <LabelAboveInput
                                label={'카드사'}
                                col={12}
                                value={
                                    'issuerName' in payMethodModalData ? payMethodModalData.issuerName : ''
                                }
                            />
                            <LabelAboveInput
                                label={'할부개월'}
                                col={12}
                                value={
                                    'installmentMonth' in payMethodModalData
                                        ? payMethodModalData.installmentMonth
                                        : ''
                                }
                            />
                        </CModalBody>
                    </>
                )}
                {payMethodModalData.hasOwnProperty('bankCode') && (
                    <>
                        <CModalHeader>
                            <CModalTitle>가상계좌정보</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <LabelAboveInput
                                label={'은행명'}
                                col={12}
                                value={'bankName' in payMethodModalData ? payMethodModalData.bankName : ''}
                            />
                            <LabelAboveInput
                                label={'계좌주명'}
                                col={12}
                                value={
                                    'depositName' in payMethodModalData ? payMethodModalData.depositName : ''
                                }
                            />
                            <LabelAboveInput
                                label={'계좌번호'}
                                col={12}
                                value={'accountNo' in payMethodModalData ? payMethodModalData.accountNo : ''}
                            />
                            <LabelAboveInput
                                label={'입금기한'}
                                col={12}
                                value={
                                    'expireDate' in payMethodModalData ? payMethodModalData.expireDate : ''
                                }
                            />
                        </CModalBody>
                    </>
                )}
                <CModalFooter>
                    <CButton
                        color='primary'
                        onClick={() => {
                            setVisibleOfPayMethod(false)
                            setPayMethodModalData(defaultCardInfo)
                        }}>
                        확인
                    </CButton>
                </CModalFooter>
            </CModal>
            {/*강제환불모달*/}
            <PaymentRefundModal visible={visibleOfRefund} setVisible={setVisibleOfRefund} data={refundData} />
        </div>
    )
}

export default Payment
