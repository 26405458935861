import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import React from 'react'
import { IServiceSpecializationResponse } from '../interface/IServiceSettingResponse'

interface KindFilterDropDownProps {
    data: IServiceSpecializationResponse[]
    color?: string
    onClick: (uri: string) => void
}

const KindFilterDropDown = ({ data, color = 'secondary', onClick }: KindFilterDropDownProps) => {
    const [selected, setSelected] = React.useState('종류')

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setSelected(e.currentTarget.name)
        onClick(`/api/request/filter/kind?classification=${e.currentTarget.name}&`)
    }

    const list: { name: string; value: string }[] = data.map((item: IServiceSpecializationResponse) => {
        return {
            name: item.name,
            value: item.name,
        }
    })

    return (
        <CDropdown>
            <CDropdownToggle color={`${color}`}>{selected}</CDropdownToggle>
            <CDropdownMenu>
                {list
                    .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
                    .map(
                        (
                            item: {
                                name: string
                                value: string
                            },
                            index: number,
                        ) => {
                            return (
                                <CDropdownItem key={index} href='#' name={item.name} onClick={onClickHandler}>
                                    {item.name}
                                </CDropdownItem>
                            )
                        },
                    )}
            </CDropdownMenu>
        </CDropdown>
    )
}

export default React.memo(KindFilterDropDown)
