import axios from 'axios'
import apiUrl from './url'

const instance = axios.create({
    baseURL: apiUrl(),
})

instance.interceptors.request.use(
    (config) => {
        config.withCredentials = true
        // @ts-ignore
        if (localStorage.getItem('token')) {
            // @ts-ignore
            config.headers['Authorization'] = `${localStorage.getItem('token')}`
            config.headers['RefreshToken'] = `${localStorage.getItem('refreshToken')}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

instance.interceptors.response.use(
    (response) => {
        if (response.headers['authorization']) {
            localStorage.setItem('token', response.headers['authorization'])
        }
        if (response.headers['refreshToken']) {
            localStorage.setItem('refreshToken', response.headers['refreshToken'])
        }
        return response
    },
    (error) => {
        //
        return Promise.reject(error)
    },
)

export default instance
