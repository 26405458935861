export interface IPagination {
    number: number
    totalPages: number
    totalElements: number
    hasPreviousPage: boolean
    hasNextPage: boolean
    isFirstPage: boolean
    isLastPage: boolean
    beginPage: number
    endPage: number
}

export const paginationInitValue: IPagination = {
    number: 1,
    totalPages: 1,
    totalElements: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    isFirstPage: true,
    isLastPage: true,
    beginPage: 1,
    endPage: 1,
}
