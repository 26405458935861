import { CPopover, CWidgetStatsF } from '@coreui/react'
import YellowDot from '../dot/YellowDot'
import React, { ReactNode } from 'react'

interface IssuerWidgetProps {
    popOverText?: string
    color?: string
    icon: ReactNode
    title: string
    value: string
    valueSuffix?: string
    onClick?: () => void
    top?: string
    right?: string
}

const IssueWidget = ({
    popOverText = '클릭하여 알림 확인처리',
    color = 'primary',
    icon,
    title,
    value,
    valueSuffix = ' 건',
    onClick,
    top = '10px',
    right = '20px',
}: IssuerWidgetProps) => {
    return (
        <>
            <CPopover content={popOverText} placement={'top'} trigger={'hover'}>
                <CWidgetStatsF
                    className={'cursor-pointer'}
                    color={color}
                    icon={icon}
                    title={title}
                    value={`${value}${valueSuffix}`}
                    onClick={onClick}
                />
            </CPopover>
            {!!value && <YellowDot top={top} right={right} />}
        </>
    )
}

export default IssueWidget
