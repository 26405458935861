import React, { useEffect, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import PaginationBar from '../../components/PaginationBar'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import { dayjs } from '../../util/dayjs'
import ToExcelButton from '../../components/ToExcelButton'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from '../../util/api'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPointResponse } from '../../interface/IPointResponse'
import { emailValidator, numberValidator } from '../../util/validator'
import { useParams } from 'react-router'
import LoadingButton from '../../components/button/LoadingButton'
import { pointTypeConverter } from '../../util/converter/pointTypeConverter'

const PointUser = () => {
    const { userEmail } = useParams()
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [email, setEmail] = useState<string>('')
    const [emailInput, setEmailInput] = useState<string>('')

    const [givePointModalVisible, setGivePointModalVisible] = useState<boolean>(false)
    const [takePointModalVisible, setTakePointModalVisible] = useState<boolean>(false)
    const [adjustData, setAdjustData] = useState<{
        give: boolean
        userId: string
        description: string
        point: string
    }>({
        give: false,
        userId: '',
        description: '',
        point: '0',
    })

    const { data } = useQuery(
        ['pointListByUserEmail', email, pagingParam.page, pagingParam.size],
        () => {
            return axios.get(
                `/api/point/list/user?email=${email}&page=${pagingParam.page}&size=${pagingParam.size}`,
            )
        },
        {
            enabled: emailValidator(email),
        },
    )

    const adjust = useMutation(
        'adjustPoint',
        () => {
            return axios.put('/api/point/adjust', adjustData)
        },
        {
            onSuccess: (data) => {
                if (data.data.success) {
                    alert('포인트 조정이 완료되었습니다.')
                    queryClient.invalidateQueries([
                        'pointListByUserEmail',
                        email,
                        pagingParam.page,
                        pagingParam.size,
                    ])
                    setGivePointModalVisible(false)
                    setTakePointModalVisible(false)
                } else {
                    alert('포인트 조정에 실패하였습니다. 다시 시도해주세요.')
                }
            },
            onError: (error) => {
                alert('애러가 발생하여 포인트 조정에 실패하였습니다. 다시 시도해주세요.')
            },
        },
    )

    const pagination: IPagination = data?.data.pagiantion
    const totalPoint: number = data?.data.body.totalPoint
    const availablePoint: number = data?.data.body.available
    const expireSoon: number = data?.data.body.expireSoon
    const list: IPointResponse[] = data?.data.body.list

    useEffect(() => {
        if (userEmail && emailValidator(userEmail) && email !== userEmail) {
            setEmail(userEmail)
            setEmailInput(userEmail)
        }
    }, [])

    useEffect(() => {
        if (data) {
            setAdjustData({
                ...adjustData,
                userId: data.data.body.userId,
            })
        }
    }, [data])

    return (
        <>
            <div>
                <div className='d-flex justify-content-end mb-2'>
                    <ToExcelButton
                        disabled={!list}
                        csvData={JSON.stringify(list)}
                        fileName={'회원별 포인트내역'}
                        className='fw-bold text-white m-2'
                    />
                    <CButton
                        className='fw-bold text-white m-2'
                        color='info'
                        style={{ width: '125px' }}
                        onClick={() => {
                            setAdjustData({
                                ...adjustData,
                                give: true,
                            })
                            setGivePointModalVisible(true)
                        }}>
                        포인트 지급
                    </CButton>
                    <CButton
                        className='fw-bold text-white m-2'
                        color='danger'
                        style={{ width: '125px' }}
                        onClick={() => {
                            setAdjustData({
                                ...adjustData,
                                give: false,
                            })
                            setTakePointModalVisible(true)
                        }}>
                        포인트 회수
                    </CButton>
                </div>
            </div>
            <CCard className='mb-4'>
                <CCardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex fs-3 fw-4 border-0'>
                            <span>포인트관리 -회원별 관리</span>
                            <div className='d-flex justify-content-between'>
                                <CInputGroup className='ms-3' style={{ width: '500px' }}>
                                    <CInputGroupText style={{ width: '100px' }}>유저 Email</CInputGroupText>
                                    <CFormInput
                                        placeholder='사용자 Email로 검색'
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                    />
                                    <CButton
                                        className='fw-bold'
                                        color='primary'
                                        style={{ width: '55px' }}
                                        onClick={() => {
                                            if (emailValidator(emailInput)) {
                                                setEmail(emailInput)
                                            } else {
                                                alert('이메일 형식이 아닙니다.')
                                            }
                                        }}>
                                        검색
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                        <div className='col-2 d-flex flex-column justify-content-end my-3'>
                            <PaginationFilter
                                color='secondary'
                                selected={pagingParam.size}
                                page={pagingParam.page}
                                onClick={setPagingParam}
                            />
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <div className={'w-100 d-flex justify-content-end align-items-center me-4'}>
                        <CInputGroup className='mb-3 ' style={{ width: '250px' }}>
                            <CButton className='fw-bold text-white' color='info' style={{ width: '125px' }}>
                                총 포인트
                            </CButton>
                            <CFormInput readOnly={true} value={totalPoint ?? 0} />
                        </CInputGroup>
                        <CInputGroup className='mb-3 mx-3' style={{ width: '250px' }}>
                            <CButton
                                className='fw-bold text-white'
                                color='success'
                                style={{ width: '125px' }}>
                                사용가능
                            </CButton>
                            <CFormInput readOnly={true} value={availablePoint ?? 0} />
                        </CInputGroup>
                        <CInputGroup className='mb-3' style={{ width: '250px' }}>
                            <CButton className='fw-bold text-white' color='danger' style={{ width: '125px' }}>
                                만료예정
                            </CButton>
                            <CFormInput readOnly={true} value={expireSoon ?? 0} />
                        </CInputGroup>
                    </div>
                    <CTable striped hover>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col'>일자</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>획득</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>사용</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>만료</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>타입</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>비고</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {!data || list.length === 0 ? (
                                <CTableRow key={0}>
                                    <CTableDataCell colSpan={7}>포인트 정보가 없습니다.</CTableDataCell>
                                </CTableRow>
                            ) : (
                                list.map((item) => (
                                    <CTableRow key={item.id}>
                                        <CTableDataCell>
                                            {dayjs(item.createdAt).format('YY.MM.DD')}
                                        </CTableDataCell>
                                        <CTableDataCell>{item.gain}</CTableDataCell>
                                        <CTableDataCell>{item.use}</CTableDataCell>
                                        <CTableDataCell>{!item.expired ? '비만료' : '만료'}</CTableDataCell>
                                        <CTableDataCell>{pointTypeConverter(item.type)}</CTableDataCell>
                                        <CTableDataCell>{item.description}</CTableDataCell>
                                    </CTableRow>
                                ))
                            )}
                        </CTableBody>
                    </CTable>
                    {pagination && (
                        <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
                    )}
                </CCardBody>
            </CCard>
            {/**포인트 지급 시 나올 창 */}
            {data && (
                <>
                    <CModal
                        visible={givePointModalVisible}
                        onClose={() => {
                            setAdjustData({
                                ...adjustData,
                                description: '',
                                point: '0',
                            })
                            setGivePointModalVisible(false)
                        }}>
                        <CModalHeader>포인트 지급</CModalHeader>
                        <CModalBody>
                            <div>
                                <CInputGroup className='p-2'>
                                    <CInputGroupText style={{ width: '110px' }}>사유</CInputGroupText>
                                    <CFormInput
                                        placeholder='사유를 설명하세요'
                                        value={adjustData.description}
                                        onChange={(e) =>
                                            setAdjustData({
                                                ...adjustData,
                                                description: e.target.value,
                                            })
                                        }
                                    />
                                </CInputGroup>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <CInputGroup className='m-2' style={{ width: '480px' }}>
                                    <CInputGroupText id='basic-addon1' style={{ width: '110px' }}>
                                        지급 포인트
                                    </CInputGroupText>
                                    <CFormInput
                                        placeholder='지급 포인트'
                                        type='number'
                                        onChange={(e) => {
                                            if (numberValidator(e.target.value)) {
                                                setAdjustData({
                                                    ...adjustData,
                                                    point: e.target.value,
                                                })
                                            } else {
                                                alert('숫자만 입력 가능합니다.')
                                            }
                                        }}
                                    />
                                </CInputGroup>
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <LoadingButton
                                className='fw-bold text-white m-2'
                                color='info'
                                style={{ width: '125px' }}
                                isLoading={adjust.isLoading}
                                onClick={() => adjust.mutate()}>
                                지급하기
                            </LoadingButton>
                        </CModalFooter>
                    </CModal>
                    {/**포인트 회수 시 나올 창 */}
                    <CModal
                        visible={takePointModalVisible}
                        onClose={() => {
                            setAdjustData({
                                ...adjustData,
                                description: '',
                                point: '0',
                            })
                            setTakePointModalVisible(false)
                        }}>
                        <CModalHeader>포인트 회수</CModalHeader>
                        <CModalBody>
                            <div className=''>
                                <CInputGroup className='p-2'>
                                    <CInputGroupText style={{ width: '110px' }}>Description</CInputGroupText>
                                    <CFormInput
                                        placeholder='사유를 설명하세요'
                                        value={adjustData.description}
                                        onChange={(e) =>
                                            setAdjustData({
                                                ...adjustData,
                                                description: e.target.value,
                                            })
                                        }
                                    />
                                </CInputGroup>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <CInputGroup className='m-2' style={{ width: '480px' }}>
                                    <CInputGroupText style={{ width: '110px' }}>회수 포인트</CInputGroupText>
                                    <CFormInput
                                        placeholder='회수 포인트'
                                        type='number'
                                        onChange={(e) => {
                                            if (numberValidator(e.target.value)) {
                                                setAdjustData({
                                                    ...adjustData,
                                                    point: e.target.value,
                                                })
                                            } else {
                                                alert('숫자만 입력 가능합니다.')
                                            }
                                        }}
                                    />
                                </CInputGroup>
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <LoadingButton
                                className='fw-bold text-white m-2'
                                color='danger'
                                style={{ width: '125px' }}
                                isLoading={adjust.isLoading}
                                onClick={() => adjust.mutate()}>
                                회수하기
                            </LoadingButton>
                        </CModalFooter>
                    </CModal>
                </>
            )}
        </>
    )
}

export default PointUser
