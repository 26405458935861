import { CFormInput, CFormLabel } from '@coreui/react'
import React, { ReactNode } from 'react'

interface ILabelAboveInput {
    label: string
    readonly?: boolean
    col: number
    value: string | ReactNode
    onClick?: () => void
    type?: string
    cursor?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const LabelAboveInput = ({
    label,
    readonly = false,
    col,
    value,
    onClick,
    type = 'text',
    cursor,
    onChange,
}: ILabelAboveInput) => {
    const fontSize = label.length > 4 ? '0.9rem' : '1rem'
    return (
        <div className={`d-flex flex-column align-items-start col-${col} my-2`}>
            <CFormLabel className='w-100 px-2' style={{ fontSize: fontSize }}>
                {label}
                {typeof value === 'string' && (
                    <CFormInput
                        type={type}
                        readOnly={readonly}
                        onClick={onClick}
                        onChange={onChange}
                        style={{ cursor: cursor ?? 'default' }}
                        value={value ?? '-'}
                    />
                )}
                {typeof value !== 'string' && value}
            </CFormLabel>
        </div>
    )
}

export default LabelAboveInput
