import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { ITermResponse } from '../../interface/ITermResponse'
import axios from '../../util/api'
import apiUrl from '../../util/url'

const Terms = () => {
    const url = apiUrl()

    const [serviceSetting, setServiceSetting] = useState<any>(null)
    const [error, setError] = useState<any>(null)
    const [tosDisabled, setTosDisabled] = useState(true)
    const [privacyDisabled, setPrivacyDisabled] = useState(true)

    const [visible, setVisible] = useState(false)
    const [returnString, setReturnString] = useState<string>('')

    const [fetchingUrl, setFetchingUrl] = useState<string>('api/service-setting/terms')

    const { data } = useQuery(['getTerms'], () => {
        return axios.get(`${fetchingUrl}`)
    })

    const term: ITermResponse = data?.data.error ?? data?.data.body
    const [tosText, setTosText] = useState<string>(term.tosText)
    const [privacy, setPrivacy] = useState<string>(term.privacyPolicyText)

    const updateTosText = useMutation(
        'updateTosText',
        () => {
            const input = {
                content: tosText,
            }
            return axios.post('/api/service-setting/terms/updatetos', input)
        },
        {
            onSuccess: (data) => {
                const result = data as AxiosResponse
                if (result.data.success === true) {
                    alert('등록되었습니다.')
                    location.reload()
                } else {
                    alert('요청 등록에 실패했습니다. 다시 시도해주세요.')
                }
            },
            onError: (error) => {
                console.log(error)
                alert('요청 등록에 실패했습니다. 다시 시도해주세요.')
            },
        },
    )

    const updatePrivacy = useMutation(
        'updatePrivacy',
        () => {
            const input = {
                content: privacy,
            }
            return axios.post('/api/service-setting/terms/updateprivacy', input)
        },
        {
            onSuccess: (data) => {
                const result = data as AxiosResponse
                if (result.data.success === true) {
                    alert('등록되었습니다.')
                    location.reload()
                } else {
                    alert('요청 등록에 실패했습니다. 다시 시도해주세요.')
                }
            },
            onError: (error) => {
                console.log(error)
                alert('요청 등록에 실패했습니다. 다시 시도해주세요.')
            },
        },
    )

    useEffect(() => {
        // fetchDatas()
    }, []) //  일반적인 예는 데이터를 가져오는 것. 컴포넌트의 경우 데이터를 한 번만 가벼와서 상태에 넣은 다음 jsx에 표시할 수 있다.

    return (
        <>
            <CCard className='mb-4'>
                <CCardHeader>
                    <input
                        className='fs-3 fw-4 border-0'
                        value={'이용약관'}
                        readOnly={true}
                        disabled={true}
                    />
                </CCardHeader>
                <CCardBody>
                    <textarea
                        className='w-100 h-100 bg-white border-0'
                        style={{ resize: 'none' }}
                        rows={10}
                        disabled={tosDisabled}
                        readOnly={tosDisabled}
                        defaultValue={term.tosText}
                        onChange={(e) => setTosText(e.target.value)}
                    />
                </CCardBody>
            </CCard>
            <div className='mb-4 d-flex justify-content-end'>
                <CButton
                    className='w-25 fw-bold '
                    color='warning'
                    hidden={!tosDisabled}
                    onClick={() => {
                        setTosDisabled(false)
                        setPrivacyDisabled(true)
                    }}>
                    수정하기
                </CButton>
                <CButton
                    className='w-25 fw-bold text-white'
                    color='info'
                    hidden={tosDisabled}
                    onClick={() => {
                        if (confirm('변경 내용이 저장됩니다') == true) {
                            updateTosText.mutate()
                        }
                    }}>
                    변경하기
                </CButton>
            </div>

            <CCard className='mb-4'>
                <CCardHeader>
                    <input
                        className='fs-3 fw-4 border-0'
                        value={'개인정보처리방침'}
                        readOnly={true}
                        disabled={true}
                    />
                </CCardHeader>
                <CCardBody>
                    <textarea
                        className='w-100 h-100 bg-white border-0'
                        style={{ resize: 'none' }}
                        rows={10}
                        disabled={privacyDisabled}
                        readOnly={privacyDisabled}
                        defaultValue={term.privacyPolicyText}
                        onChange={(e) => setPrivacy(e.target.value)}
                    />
                </CCardBody>
            </CCard>
            <div className='mb-4 d-flex justify-content-end'>
                <CButton
                    className='w-25 fw-bold '
                    color='warning'
                    hidden={!privacyDisabled}
                    onClick={() => {
                        setPrivacyDisabled(false)
                        setTosDisabled(true)
                    }}>
                    수정하기
                </CButton>
                <CButton
                    className='w-25 fw-bold text-white'
                    color='info'
                    hidden={privacyDisabled}
                    onClick={() => {
                        if (confirm('변경 내용이 저장됩니다') == true) {
                            updatePrivacy.mutate()
                        }
                    }}>
                    변경하기
                </CButton>
            </div>

            <CModal visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader onClick={() => setVisible(false)}>
                    <CModalTitle>저장 결과</CModalTitle>
                </CModalHeader>
                <CModalBody>{returnString}</CModalBody>
                <CModalFooter>
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setVisible(false)
                            setReturnString('')
                        }}>
                        Close
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default Terms
