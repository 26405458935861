import {
    CButton,
    CCarousel,
    CCarouselItem,
    CFormLabel,
    CFormTextarea,
    CImage,
    CInputGroup,
    CModal,
} from '@coreui/react'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import LabelAboveInput from '../../components/LabelAboveInput'
import ImagePreviewList from '../../components/imgae/ImagePreviewList'
import ImageSelector, { ImageFile } from '../../components/imgae/ImageSelector'
import ImageZoom from '../../components/imgae/ImageZoom'
import { IVocForAdminResponse, IVocImageResponse } from '../../interface/IVocResponse'
import { IVocCommentInput, createVocCommentInputInit } from '../../interface/input/voc/IVocInput'
import axios from '../../util/api'
import { vocStateConverter } from '../../util/converter/VocStateConverter'
import { dayjs } from '../../util/dayjs'

const InquiryDetail = () => {
    const { inquiryId } = useParams()
    const navigate = useNavigate()

    const [showImg, setShowImg] = useState<boolean>(false)
    const [imgSrc, setImgSrc] = useState<string>('')

    const [input, setInput] = useState<IVocCommentInput>(createVocCommentInputInit)

    const { data } = useQuery(['InquiryDetail', inquiryId], () => {
        return axios.get(`/api/voc/inquiry/${inquiryId}`)
    })

    const inquiry: IVocForAdminResponse = !data?.data.error && data?.data.body.inquiry
    const vocQuestionImgs: IVocImageResponse[] = !data?.data.error && data?.data.body.questionImgList
    const vocAnswerImgs: IVocImageResponse[] = !data?.data.error && data?.data.body.answerImgList

    const addComment = useMutation(
        'addCommentInquiry',
        () => {
            const formData = new FormData()
            input.imageList.map((img) => {
                if (img.file instanceof File) {
                    formData.append('files', img.file)
                }
            })
            const body = {
                targetId: inquiryId,
                category: 'inquiry'.toUpperCase(),
                comment: input.comment,
            }
            formData.append(
                'data',
                new Blob([JSON.stringify(body)], {
                    type: 'application/json',
                }),
            )
            return axios.put('/api/voc/inquiry/comment', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        {
            onSuccess: (data) => {
                const result = data as AxiosResponse
                if (result.data.success === true) {
                    alert('등록되었습니다.')
                    location.reload()
                } else {
                    alert('요청 등록에 실패했습니다. 다시 시도해주세요.')
                }
            },
            onError: (error) => {
                console.log(error)
                alert('요청 등록에 실패했습니다. 다시 시도해주세요.')
            },
        },
    )

    useEffect(() => {}, [])

    return (
        <>
            <div className='container-fluid w-100'>
                <div className='d-flex flex-row w-100'>
                    {/*이미지 표시영역*/}
                    <div className='col-3 me-3 d-flex align-items-center'>
                        <CCarousel controls indicators>
                            {vocQuestionImgs.map((image: any, index: number) => (
                                <CCarouselItem key={index}>
                                    <CImage
                                        src={`${image.url}`}
                                        className='d-block w-100'
                                        alt={`image ${index + 1}`}
                                        onClick={() => {
                                            setImgSrc(`${image.url}`)
                                            setShowImg(true)
                                        }}
                                    />
                                </CCarouselItem>
                            ))}
                        </CCarousel>
                    </div>
                    {/*form 표시영역*/}
                    <div className='col-9'>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput label='문의 id' col={8} readonly={true} value={inquiry.voc.id} />
                            <LabelAboveInput
                                label='상태(state)'
                                col={3}
                                value={vocStateConverter(inquiry.voc.state)}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='생성일'
                                col={6}
                                value={dayjs(inquiry.voc.createdAt).format('YYYY년 MM월 DD일')}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='편집일'
                                col={6}
                                value={dayjs(inquiry.voc.updatedAt).format('YYYY년 MM월 DD일')}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex flex-wrap'>
                            <LabelAboveInput
                                label='작성자 id'
                                col={6}
                                value={inquiry.voc.authorId}
                                readonly={true}
                            />
                            <LabelAboveInput label={'작성자 email'} col={6} value={inquiry.authorEmail} />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='문의 제목'
                                col={12}
                                value={inquiry.voc.title}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <CFormLabel className='w-100 px-2' style={{ fontSize: '1rem' }}>
                                문의 내용
                                <CFormTextarea
                                    className='w-100 h-100 bg-white'
                                    style={{ resize: 'none' }}
                                    disabled={true}
                                    readOnly={true}
                                    defaultValue={inquiry.voc.content}
                                />
                            </CFormLabel>
                        </div>
                        {/* 답변 영역 */}
                        <div className='col-12 d-flex mt-4'>
                            <CFormLabel className='w-100 px-2' style={{ fontSize: '1rem' }}>
                                답변 내용
                                <CFormTextarea
                                    className='w-100 h-100 bg-white'
                                    style={{ resize: 'none' }}
                                    disabled={inquiry.voc.comment !== null && true}
                                    readOnly={inquiry.voc.comment !== null && true}
                                    defaultValue={inquiry.voc.comment}
                                    onChange={(e) => setInput({ ...input, comment: e.currentTarget.value })}
                                />
                            </CFormLabel>
                        </div>
                        {inquiry.voc.vocAnswerImgId === null &&
                        data?.data.body.answerImgList.length == 0 &&
                        inquiry.voc.comment == null ? (
                            <div className='col-12 d-flex mt-4'>
                                <ImageSelector
                                    key={'portfolio-image-selector'}
                                    className={'my-3'}
                                    imageList={input.imageList}
                                    setImageList={(img: ImageFile[]) =>
                                        setInput({ ...input, imageList: img })
                                    }
                                    title={'사진'}
                                    description={''}
                                />
                            </div>
                        ) : (
                            inquiry.voc.vocAnswerImgId !== null &&
                            data?.data.body.answerImgList.length > 0 &&
                            inquiry.voc.comment !== null && (
                                <div className='col-12 mt-4'>
                                    <CFormLabel
                                        className='my-3 fw-semibold'
                                        htmlFor='exampleFormControlTextarea1'>
                                        사진
                                    </CFormLabel>
                                    <ImagePreviewList
                                        images={vocAnswerImgs.map((item) => {
                                            return { id: item.id, url: item.url }
                                        })}
                                        slidesPerView={3.8}
                                        spaceBetween={15}
                                    />
                                </div>
                            )
                        )}
                    </div>
                </div>
                {/** 하단 토글, 버튼 영역 */}
                <div className=' my-4'>
                    <CInputGroup className='mb-3 mt-5 d-flex justify-content-end'>
                        {inquiry.voc.comment == null && (
                            <CButton
                                className='fw-bold text-white rounded-end'
                                color='info'
                                style={{ width: '125px' }}
                                onClick={() => {
                                    if (confirm('작성한 답변 내용이 고객의 문의글에 등록됩니다') == true) {
                                        addComment.mutate()
                                    }
                                }}>
                                답변 달기
                            </CButton>
                        )}
                        <CButton
                            className='fw-bold text-white rounded mx-2'
                            color='primary'
                            style={{ width: '125px' }}
                            onClick={() => {
                                navigate('/inquiry')
                            }}>
                            확인
                        </CButton>
                    </CInputGroup>
                </div>
            </div>
            {showImg && (
                <CModal
                    alignment={'center'}
                    visible={showImg}
                    size={'lg'}
                    onClose={() => {
                        setImgSrc('')
                        setShowImg(false)
                    }}>
                    <ImageZoom src={imgSrc} />
                </CModal>
            )}
        </>
    )
}

export default InquiryDetail
