export type vocState = 'RECEIVED' | 'IN_PROGRESS' | 'HOLDING' | 'DONE'

export const vocStateFilter = [
    { name: '접수완료', value: 'RECEIVED' },
    { name: '답변완료', value: 'DONE' },
]

export const vocStateConverter = (state: vocState) => {
    switch (state) {
        case 'RECEIVED':
            return '접수완료'
        case 'IN_PROGRESS':
            return '접수완료'
        case 'HOLDING':
            return '접수완료'
        case 'DONE':
            return '답변완료'
        default:
            return '알수없음'
    }
}
