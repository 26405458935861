import { CChart } from '@coreui/react-chartjs'
import { getStyle } from 'chart.js/helpers'
import { useQuery } from 'react-query'
import { RequestData } from '../../interface/IDashboardDataResponse'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'

const ClassificationSorted = () => {
    const root = document.getElementById('root') as HTMLElement

    const { data } = useQuery('requestDashboard', () => {
        return axios.get('/api/dashboard/request')
    })

    const requestData: RequestData = data?.data.body
    const classificationRequests =
        requestData.requestCountOfClassification &&
        requestData.requestCountOfClassification.classificationRequests

    const sumResult: { classification: string; sum: number }[] = classificationRequests
        .map((data) => {
            return {
                classification: data.classification,
                sum:
                    data.userRequestCount +
                    data.nonameRequestCount +
                    data.normalRequestCount +
                    data.simpleRequestCount +
                    data.matchedRequestCount,
            }
        })
        .sort((a, b) => {
            return b.sum - a.sum
        })

    return (
        <div className={'d-flex flex-column'}>
            {!classificationRequests ? (
                <h1>충분한 데이터가 수집되지 않았습니다.</h1>
            ) : (
                <>
                    <div>
                        <h1>공사별 요청서 통계</h1>
                    </div>
                    <div className={'mt-5'}>
                        <h3>
                            {dayjs(requestData.requestCountOfClassification.date).format('MM-DD')} 일 요청서
                            정보 (전일 기준 생성된 전체 요청서)
                        </h3>
                    </div>
                    <div className={'col-12 py-3 d-flex flex-column gap-3'}>
                        <div className={'col-12'}>
                            <CChart
                                type='bar'
                                data={{
                                    labels: sumResult
                                        .slice(0, Math.floor(sumResult.length / 2))
                                        .map((data) => data.classification),
                                    datasets: [
                                        {
                                            label: '전문분야별 분포',
                                            backgroundColor: '#79b0f8',
                                            data: sumResult
                                                .slice(0, Math.floor(sumResult.length / 2))
                                                .map((data) => data.sum),
                                        },
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                color: getStyle(root, '--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle(root, '--cui-body-color'),
                                                font: {
                                                    size: 18,
                                                },
                                            },
                                        },
                                        y: {
                                            grid: {
                                                color: getStyle(root, '--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle(root, '--cui-body-color'),
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>

                        <div className={'col-12'}>
                            <CChart
                                type='bar'
                                data={{
                                    labels: sumResult
                                        .slice(Math.floor(sumResult.length / 2))
                                        .map((data) => data.classification),
                                    datasets: [
                                        {
                                            label: '전문분야별 분포',
                                            backgroundColor: '#79b0f8',
                                            data: sumResult
                                                .slice(Math.floor(sumResult.length / 2))
                                                .map((data) => data.sum),
                                        },
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                color: getStyle(root, '--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle(root, '--cui-body-color'),
                                                font: {
                                                    size: 18,
                                                },
                                            },
                                        },
                                        y: {
                                            max:
                                                Math.floor(
                                                    sumResult.reduce((prev, cur) =>
                                                        prev.sum < cur.sum ? cur : prev,
                                                    ).sum / 10,
                                                ) *
                                                    10 +
                                                10,
                                            grid: {
                                                color: getStyle(root, '--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle(root, '--cui-body-color'),
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ClassificationSorted
