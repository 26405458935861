import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import React from 'react'
import { IRegionTypeResponse } from '../interface/IRequestResponse'
import { locationSiConverter } from '../util/converter/LocationSiConverter'

interface RegionFilterDropDownProps {
    data: IRegionTypeResponse[]
    color?: string
    onClick: (uri: string) => void
}

const RegionFilterDropDown = ({ data, color = 'secondary', onClick }: RegionFilterDropDownProps) => {
    const [selected, setSelected] = React.useState('지역')

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setSelected(e.currentTarget.name)
        onClick(`/api/request/filter/location?location=${e.currentTarget.name}&`)
    }

    return (
        <CDropdown>
            <CDropdownToggle color={`${color}`}>{selected}</CDropdownToggle>
            <CDropdownMenu>
                {data.map((item: IRegionTypeResponse, index: number) => {
                    return (
                        <CDropdownItem
                            key={index}
                            href='#'
                            name={locationSiConverter(item.name)}
                            onClick={onClickHandler}>
                            {locationSiConverter(item.name)}
                        </CDropdownItem>
                    )
                })}
            </CDropdownMenu>
        </CDropdown>
    )
}

export default React.memo(RegionFilterDropDown)
