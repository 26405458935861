import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import React, { useState } from 'react'

interface FilterDropDownProps {
    color?: string
    name: string
    items: {
        name: string
        value: string
    }[]
    requestUrl: string
    onClick: Function
}

const FilterDropDown = ({ color = 'secondary', name, items, onClick, requestUrl }: FilterDropDownProps) => {
    const [selected, setSelected] = useState<string>(name)

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setSelected(e.currentTarget.id)
        onClick(`${requestUrl}${e.currentTarget.name}&`)
    }

    return (
        <>
            <CDropdown>
                <CDropdownToggle color={`${color}`}>{selected}</CDropdownToggle>
                <CDropdownMenu>
                    {items.map((item, index) => {
                        return (
                            <CDropdownItem
                                key={index}
                                href='#'
                                id={item.name}
                                name={item.value}
                                onClick={onClickHandler}>
                                {item.name}
                            </CDropdownItem>
                        )
                    })}
                </CDropdownMenu>
            </CDropdown>
        </>
    )
}

export default React.memo(FilterDropDown)
