import { CChart } from '@coreui/react-chartjs'
import { getStyle } from 'chart.js/helpers'
import { useQuery } from 'react-query'
import { RequestData } from '../../interface/IDashboardDataResponse'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'

const RegionSorted = () => {
    const root = document.getElementById('root') as HTMLElement

    const { data } = useQuery('requestDashboard', () => {
        return axios.get('/api/dashboard/request')
    })

    const requestData: RequestData = data?.data.body
    const regionRequests =
        requestData.requestCountOfRegions && requestData.requestCountOfRegions.regionRequests

    const sumResult: { region: string; sum: number }[] = regionRequests
        .map((data) => {
            return {
                region: data.region,
                sum: data.userRequestCount + data.nonameRequestCount,
                // + data.normalRequestCount +
                // data.simpleRequestCount +
                // data.matchedRequestCount,
            }
        })
        .sort((a, b) => {
            return b.sum - a.sum
        })

    return (
        <div className={'container d-flex flex-column'}>
            <div>
                <h1>지역별 요청서 통계</h1>
            </div>
            <div className={'mt-5'}>
                <h3>
                    {dayjs(requestData.requestCountOfRegions.date).format('MM-DD')} 일 요청서 정보 (전일 기준
                    생성된 전체 요청서)
                </h3>
            </div>
            <div className={'col-12 py-3 d-flex flex-column gap-3'}>
                {/*전체요청서 수*/}
                <div className={'mt-5'}>
                    <h2>
                        전체 요청서&nbsp;&nbsp;:&nbsp;
                        {sumResult.reduce((acc, cur) => acc + cur.sum, 0)} 건
                    </h2>
                </div>
                <div className={'col-12'}>
                    <CChart
                        type='bar'
                        data={{
                            labels: sumResult.map((data) => {
                                if (data.region.includes('자치')) {
                                    return data.region.slice(0, 2)
                                }
                                return data.region
                            }),
                            datasets: [
                                {
                                    label: '지역별 분포',
                                    backgroundColor: '#79b0f8',
                                    data: sumResult.map((data) => data.sum),
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            datasets: {},
                            scales: {
                                x: {
                                    grid: {
                                        color: getStyle(root, '--cui-border-color-translucent'),
                                    },
                                    ticks: {
                                        color: getStyle(root, '--cui-body-color'),
                                        font: {
                                            size: 18,
                                        },
                                    },
                                },
                                y: {
                                    grid: {
                                        color: getStyle(root, '--cui-border-color-translucent'),
                                    },
                                    ticks: {
                                        color: getStyle(root, '--cui-body-color'),
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default RegionSorted
