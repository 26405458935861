import { CImage } from '@coreui/react'
import React, { ReactNode, SetStateAction } from 'react'
import { ICONS } from '../../styles/Icon'
import { ReactComponent as Remove } from '../../styles/icons/remove.svg'

export interface FileOnServer {
    id: string
}

export interface ImageFile {
    url: string
    file: File | FileOnServer
}

interface ImageSelectorProps {
    imageList: ImageFile[]
    setImageList: React.Dispatch<SetStateAction<ImageFile[]>> | ((imageList: ImageFile[]) => void)
    className?: string
    /**
     * 이미지가 하나도 등록되지 않았을 때 표시되는 경고문구
     */
    description?: string
    /**
     * 입력받을 수 있는 이미지 수 제한. 기본 10
     */
    maxLength?: number
    /**
     * 타이틀. 기본 '사진'
     */
    title?: string | ReactNode
    /**
     * 타이틀 굵게. 기본 true
     */
    boldTitle?: boolean
    /**
     * 경고아이콘 표시. 기본 true
     */
    warning?: boolean
}
const ImageSelector = ({
    imageList,
    setImageList,
    className,
    description = '이미지를 등록해 주세요.',
    maxLength = 10,
    title = '사진',
    boldTitle = true,
    warning = true,
}: ImageSelectorProps) => {
    const id = 'img-selector-' + Math.random().toString(36).substr(2, 11)
    const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = e.target.files as FileList

        if (selected.length + imageList.length > maxLength) {
            alert(`이미지는 최대 ${maxLength}장까지 등록 가능합니다.`)
            return
        }

        let selectedImageList: ImageFile[] = []
        for (let i = 0; i < selected.length; i++) {
            const url = URL.createObjectURL(selected[i])
            selectedImageList.push({ url: url, file: selected[i] })
        }
        setImageList([...imageList, ...selectedImageList])
        e.target.value = ''
    }

    const handleDeleteImage = (index: number) => {
        const target = imageList[index]
        if (target) {
            setImageList(imageList.filter((item) => item !== target))
        }
    }

    return (
        <div className={`col-11 ${className}`}>
            <div className={`d-flex justify-content-between col-12 ${boldTitle ? 'fw-bold' : ''} fs-17`}>
                <span>{title}</span>
                <span>
                    {imageList.length}/{maxLength}
                </span>
            </div>
            <label
                htmlFor={id}
                className={'d-flex col-12'}
                style={{
                    gap: '15px',
                    padding: '10px 0 15px',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    cursor: 'pointer',
                }}>
                <div
                    className={'d-flex justify-content-center align-items-center p-4 border rounded'}
                    style={{ width: '80px', height: '80px' }}>
                    {ICONS.PLUS}
                </div>

                {imageList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={'position-relative image-input-remove'}
                            style={{ width: '80px', height: '80px' }}>
                            <CImage
                                className={'rounded'}
                                src={item.url}
                                style={{ width: '80px', height: '80px', objectFit: 'contain' }}
                            />
                            <Remove
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleDeleteImage(index)
                                }}
                            />
                        </div>
                    )
                })}
            </label>
            <input
                type={'file'}
                id={id}
                className={'d-none'}
                accept={'image/jpeg, image/png, image/jpg'}
                multiple={true}
                onChange={handleAddImage}
            />
            {imageList.length === 0 && (
                <div style={{ lineHeight: '24px', verticalAlign: 'bottom' }}>
                    {warning && ICONS.CAUTION_ICON}&nbsp;
                    {description ? description : '이미지를 등록해 주세요.'}
                </div>
            )}
        </div>
    )
}

export default ImageSelector
