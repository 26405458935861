import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Mousewheel } from 'swiper'
import ImageZoom from './ImageZoom'

interface ImagePreViewListProps {
    images: {
        id: string
        url: string
    }[]
    spaceBetween?: string | number
    slidesPerView?: number | 'auto'
    height?: number
    onSlideChange?: () => void
    onSwiper?: () => void
    loop?: boolean
}

const ImagePreviewList = ({
    images,
    spaceBetween,
    slidesPerView,
    height = 0.1,
    onSlideChange = () => {},
    onSwiper = () => {},
    loop = false,
}: ImagePreViewListProps) => {
    return (
        <Swiper
            modules={[Mousewheel]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            height={height}
            mousewheel
            loop={loop}
            onSlideChange={onSlideChange}
            onSwiper={onSwiper}
            watchOverflow
            className={'flex-row w-100'}>
            {images.map((image) => {
                return (
                    <SwiperSlide key={image.id}>
                        <ImageZoom
                            className={'rounded rounded-2'}
                            src={image.url}
                            key={image.id}
                            height={height}
                        />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default ImagePreviewList
