const apiUrl = () => {
    if (process.env.REACT_APP_NODE_ENV === 'development') return 'http://localhost:8085'
    if (process.env.REACT_APP_NODE_ENV === 'test') return 'https://api-test-admin.tongba.co.kr'
    if (process.env.REACT_APP_NODE_ENV === 'production') return 'https://api-admin.tongba.co.kr'
    return
}

export const stompUrl = () => {
    if (process.env.REACT_APP_NODE_ENV === 'test') return 'wss://api-testv2.tongba.co.kr'
    else if (process.env.REACT_APP_NODE_ENV === 'production') return 'wss://api.tongba.co.kr'
    else return 'ws://localhost:8085'
}

export default apiUrl
