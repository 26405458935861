import React, { SetStateAction, useEffect } from 'react'
import { useMutation } from 'react-query'
import axios from '../../util/api'
import {
    CButton,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
} from '@coreui/react'
import LoadingButton from '../button/LoadingButton'

interface ISendSMSModalProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
    phoneNumber?: string
}

type mmsForm = {
    phoneNumber?: string
    title?: string
    message?: string
}

const SendSMSModal = ({ visible, setVisible, phoneNumber }: ISendSMSModalProps) => {
    const [mmsForm, setMmsForm] = React.useState<mmsForm>({})

    const sendSms = useMutation(
        'sendSms',
        () => {
            return axios.post(`/api/notification/send/mms`, mmsForm)
        },
        {
            onSuccess: (data) => {
                if (data.data.success) {
                    alert('발송하였습니다.')
                } else {
                    alert(data.data.error)
                }
            },
        },
    )

    useEffect(() => {
        if (visible && phoneNumber) {
            setMmsForm({
                ...mmsForm,
                phoneNumber: phoneNumber,
            })
        }
    }, [visible])

    return (
        <CModal
            visible={visible}
            onClose={() => {
                setMmsForm({})
                setVisible(false)
            }}>
            <CModalHeader className={'fw-bold'}>{phoneNumber}에 문자 발송</CModalHeader>
            <CModalBody>
                <div className={`d-flex flex-column align-items-start m-2`}>
                    <CFormLabel className='w-100 px-2 fw-bold' style={{ fontSize: '0.9rem' }}>
                        제목
                        <CFormInput
                            type='text'
                            readOnly={false}
                            style={{ cursor: 'default' }}
                            onChange={(e) => setMmsForm({ ...mmsForm, title: e.target.value.trim() })}
                        />
                    </CFormLabel>
                </div>
                <div className={`d-flex flex-column align-items-start m-2`}>
                    <CFormLabel className='w-100 px-2 fw-bold' style={{ fontSize: '0.9rem' }}>
                        내용
                        <CFormTextarea
                            readOnly={false}
                            rows={5}
                            style={{ cursor: 'default', resize: 'none' }}
                            onChange={(e) => setMmsForm({ ...mmsForm, message: e.target.value.trim() })}
                        />
                    </CFormLabel>
                </div>
            </CModalBody>
            <CModalFooter>
                <div className='d-flex justify-content-end'>
                    <LoadingButton
                        color='primary'
                        className={'me-2 fw-bold'}
                        isLoading={sendSms.isLoading}
                        onClick={() => {
                            sendSms.mutate()
                            setVisible(false)
                        }}>
                        확인
                    </LoadingButton>
                    <CButton
                        color='danger'
                        className={'text-white fw-bold'}
                        onClick={() => {
                            setMmsForm({})
                            setVisible(false)
                        }}>
                        취소
                    </CButton>
                </div>
            </CModalFooter>
        </CModal>
    )
}

export default SendSMSModal
