const classificationConverter = (classification: string[]): string => {
    const length = classification.length
    if (length === 0) return ''
    if (classification[length - 1] === '일반' || classification[length - 1] === '시공') {
        return classification.slice(0, length - 1).join('/')
    } else {
        return classification.join('/')
    }
}

export default classificationConverter
