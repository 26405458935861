import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import SimpleBar from 'simplebar-react'
import React, { useState } from 'react'
import { AppSidebarNav } from './AppSidebarNav'
import navigation from '../_nav'
import 'simplebar/dist/simplebar.min.css'
import { Link } from 'react-router-dom'

const AppSidebar = () => {
    return (
        <CSidebar position='fixed' unfoldable={false}>
            <CSidebarBrand className='d-flex'>
                <Link to={'/'}>
                    <span className={'fw-bold text-white'}>admin page</span>
                </Link>
            </CSidebarBrand>
            <CSidebarNav>
                <SimpleBar>
                    {/* @ts-ignore */}
                    <AppSidebarNav items={navigation} />
                </SimpleBar>
            </CSidebarNav>
        </CSidebar>
    )
}

export default React.memo(AppSidebar)
