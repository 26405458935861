import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { CFormCheck } from '@coreui/react'
import LabelAboveInput from '../../components/LabelAboveInput'
import { useMutation, useQuery } from 'react-query'
import axios from '../../util/api'
import { IUserDetailResponse } from '../../interface/IUserResponse'
import LoadingButton from '../../components/button/LoadingButton'
import UserTransaction from './UserTransaction'
import CardWithNav from '../../components/CardWithNav'
import UserReviews from './UserReviews'

const UserDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const stateRef = useRef<HTMLInputElement>(null)

    const { data } = useQuery(['user', id], () => {
        return axios.get(`/api/user/${id}`)
    })

    const userInfo: IUserDetailResponse = !data?.data.error && data?.data.body.userInfo

    const changeState = useMutation(
        'changeState',
        () => {
            return axios.post('/api/user/change-state', {
                userId: userInfo.id,
                state: userInfo.state === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
            })
        },
        {
            onSuccess: (data) => {
                if (data.data.success) {
                    alert('상태 변경이 완료되었습니다.')
                    navigate(-1)
                } else {
                    alert('상태 변경에 실패하였습니다.')
                }
            },
        },
    )

    useEffect(() => {
        if (data?.data.error) {
            alert(data.data.error)
            navigate(-1)
        }
    }, [data])

    return (
        <>
            {data && (
                <div className='container-fluid w-100'>
                    <div className='d-flex flex-row w-100'>
                        {/*form 표시영역*/}
                        <div className='col-9'>
                            <div className='col-6'>
                                <LabelAboveInput
                                    label='유저 id'
                                    value={userInfo.id}
                                    readonly={true}
                                    col={12}
                                />
                            </div>
                            <div className='col-12 d-flex'>
                                <LabelAboveInput
                                    label='고객명'
                                    col={4}
                                    readonly={true}
                                    value={userInfo.name}
                                />
                                <LabelAboveInput
                                    label='email'
                                    col={4}
                                    value={userInfo.email}
                                    readonly={true}
                                />
                                <LabelAboveInput
                                    label='연락처'
                                    col={4}
                                    value={userInfo.phoneNumber !== null ? userInfo.phoneNumber : '정보없음'}
                                    readonly={true}
                                />
                            </div>
                            <div className='col-12 d-flex'>
                                <LabelAboveInput
                                    label='알림동의 / 마케팅동의'
                                    col={4}
                                    readonly={true}
                                    value={`${userInfo.isNotified ? '동의' : '미동의'} / ${
                                        userInfo.receivingMarketing ? '동의' : '미동의'
                                    }`}
                                />
                                <LabelAboveInput
                                    label='상태'
                                    col={4}
                                    value={userInfo.state}
                                    readonly={true}
                                />
                                <LabelAboveInput
                                    label='가입경로'
                                    col={4}
                                    value={userInfo.serviceProvider}
                                    readonly={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mb-5'>
                        <CFormCheck
                            ref={stateRef}
                            type='checkbox'
                            id='changeState'
                            className='text-danger'
                            label={userInfo.state === 'ACTIVE' ? '고객 비활성화 처리' : '고객 활성화 처리'}
                        />
                        <LoadingButton
                            isLoading={changeState.isLoading}
                            color='primary'
                            size={'lg'}
                            className='m-3'
                            onClick={() => {
                                if (stateRef.current?.checked) {
                                    alert('고객 상태를 변경합니다.')
                                    changeState.mutate()
                                } else {
                                    navigate(-1)
                                }
                            }}>
                            확인
                        </LoadingButton>
                    </div>
                    <div className='mb-5'>
                        <CardWithNav
                            navItems={[
                                { name: '거래내역', component: <UserTransaction id={id} /> },
                                { name: '후기내역', component: <UserReviews id={id} /> },
                            ]}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default UserDetail
