import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import DefaultLayout from './layout/DefaultLayout'
import './scss/style.scss'
import { CSpinner } from '@coreui/react'
import { IServiceRegionResponse, IServiceSpecializationResponse } from './interface/IServiceSettingResponse'
import { RequestFilterContext } from './util/contexts'

const isAuthenticated = () => {
    return !!localStorage.getItem('token')
}
const Login = React.lazy(() => import('./pages/Login'))

function App() {
    const [requestFilterItem, setRequestFilterItem] = React.useState<{
        region: IServiceRegionResponse[]
        classification: IServiceSpecializationResponse[]
    }>({ region: [], classification: [] })

    const requestFilterContextValue = React.useMemo(
        () => ({
            requestFilterItem,
            setRequestFilterItem,
        }),
        [requestFilterItem, setRequestFilterItem],
    )

    return (
        <div className='container-fluid' style={{ maxWidth: '1920px' }}>
            <Suspense fallback={<CSpinner />}>
                <RequestFilterContext.Provider value={requestFilterContextValue}>
                    <Routes>
                        <Route
                            path='/'
                            element={<Navigate replace to={isAuthenticated() ? '/main' : '/login'} />}
                        />
                        <Route path='/login' element={<Login />} />
                        <Route path='*' element={<DefaultLayout />} />
                    </Routes>
                </RequestFilterContext.Provider>
            </Suspense>
        </div>
    )
}

export default App
