import { IRequestNonameResponse } from '../interface/IRequestNonameResponse'
import { IRequestResponse } from '../interface/IRequestResponse'

const requestTypeDecider = (request: IRequestResponse | IRequestNonameResponse): string => {
    if (request.classifications.at(-1) === '일반') {
        return 'detail'
    } else if (request.classifications.at(-1) === '시공') {
        return 'simple'
    } else if (request.buildingType === 'simpleRequest') {
        return 'simple'
    } else {
        return 'detail'
    }
}

export default requestTypeDecider
