import React, { SetStateAction, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import axios from '../../util/api'
import { CFormInput, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import LabelAboveInput from '../LabelAboveInput'
import LoadingButton from '../button/LoadingButton'

interface IPaymentRefundModalProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
    data: {
        paymentId: string
        pgCno: string
        cancelPgCno: string
        usedPoint: number
    }
}

const PaymentRefundModal = ({ visible, setVisible, data }: IPaymentRefundModalProps) => {
    const queryClient = useQueryClient()
    const refundDataInit = {
        paymentId: '',
        pgCno: '',
        cancelPgCno: '',
        usedPoint: 0,
    }
    const [refundData, setRefundData] = useState<{
        paymentId: string
        pgCno: string
        cancelPgCno: string
        usedPoint: number
    }>(refundDataInit)

    const forceRefund = useMutation(
        'forceRefund',
        () => {
            return axios.put('/api/payment/refund', refundData)
        },
        {
            onSuccess: (res) => {
                if (res.data.success) {
                    alert('환불데이터를 저장했습니다.')
                    setVisible(false)
                } else {
                    alert('애러가 발생하여 환불데이터를 저장하지 못했습니다.')
                    setVisible(false)
                    setRefundData(refundDataInit)
                }
            },
        },
    )

    useEffect(() => {
        if (visible) {
            setRefundData(data)
        }
    }, [visible])

    return (
        <CModal
            alignment='center'
            visible={visible}
            onClose={() => {
                setVisible(false)
                setRefundData(refundDataInit)
            }}>
            <CModalHeader>
                <CModalTitle>관리자권한 환불처리</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <LabelAboveInput label={'환불대상 거래번호'} col={12} value={refundData.pgCno} />
                <p>
                    이지페이 관리자페이지에서 위 거래번호로 환불을 진행하시고, 환불결과창에서 환불거래번호를
                    확인하신 후 아래에 입력 해 주세요
                </p>
                <CFormInput
                    type='text'
                    placeholder='환불거래번호'
                    value={refundData.cancelPgCno}
                    onChange={(e) => {
                        setRefundData({ ...refundData, cancelPgCno: e.target.value })
                    }}
                />
            </CModalBody>
            <CModalFooter>
                <LoadingButton
                    color='primary'
                    isLoading={forceRefund.isLoading}
                    onClick={() => {
                        if (refundData.cancelPgCno === undefined || refundData.cancelPgCno === '') {
                            alert('환불거래번호를 입력해주세요')
                            return
                        }
                        forceRefund.mutate()
                    }}>
                    확인
                </LoadingButton>
            </CModalFooter>
        </CModal>
    )
}

export default PaymentRefundModal
