import React from 'react'
import {
    IServiceRegionResponse,
    IServiceSpecializationResponse,
    RequestFilterValueType,
} from '../interface/IServiceSettingResponse'

export const RequestFilterContext = React.createContext({
    requestFilterItem: { region: [], classification: [] } as RequestFilterValueType,
    setRequestFilterItem: (filterValue: {
        region: IServiceRegionResponse[]
        classification: IServiceSpecializationResponse[]
    }): void => {},
})
