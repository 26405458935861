const constructionStateConverter = (
    state:
        | string
        | 'ACTIVE'
        | 'INACTIVE'
        | 'PENDING_COMPLETED'
        | 'COMPLETED'
        | 'PENDING_REFUND'
        | 'REFUND_REJECTED'
        | 'REFUNDED',
): string => {
    switch (state) {
        case 'ACTIVE':
            return '진행중'
        case 'INACTIVE':
            return '취소'
        case 'PENDING_COMPLETED':
            return '공사완료요청중'
        case 'COMPLETED':
            return '공사완료'
        case 'PENDING_REFUND':
            return '환불요청중'
        case 'REFUND_REJECTED':
            return '환불거절'
        case 'REFUNDED':
            return '환불완료'
        default:
            return '알수없음'
    }
}

export default constructionStateConverter
