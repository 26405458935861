import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import KindFilterDropDown from '../../components/KindFilterDropDown'
import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import RegionFilterDropDown from '../../components/RegionFilterDropDown'
import ToExcelButton from '../../components/ToExcelButton'
import DetailRequestBadge from '../../components/badge/DetailRequestBadge'
import SimpleRequestBadge from '../../components/badge/SimpleRequestBadge'
import { IRequestNonameResponse } from '../../interface/IRequestNonameResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import axios from '../../util/api'
import { RequestFilterContext } from '../../util/contexts'
import { locationSiConverter } from '../../util/converter/LocationSiConverter'
import classificationConverter from '../../util/converter/classificationConverter'
import { dayjs } from '../../util/dayjs'
import requestTypeDecider from '../../util/requestTypeDecider'

const RequestNonameList = () => {
    const today = dayjs()
    const queryClient = useQueryClient()
    const { requestFilterItem, setRequestFilterItem } = useContext(RequestFilterContext)

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [exelData, setExelData] = useState<string>('')
    const [fetchingUrl, setFetchingUrl] = useState<string>(`/api/noname/list?`)

    const [address, setAddress] = useState<string>('')
    const [userEmail, setUserEmail] = useState<string>('')

    const { data } = useQuery(['requestList', fetchingUrl, pagingParam.page, pagingParam.size], () => {
        return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const getFilterItem = useQuery(
        'getRequestFilterItem',
        () => {
            return axios.get('/api/service-setting/request-filter')
        },
        {
            enabled: requestFilterItem.region.length === 0,
            onSuccess: (data) => {
                setRequestFilterItem({
                    region: data.data.body.regions,
                    classification: data.data.body.specializations,
                })
            },
        },
    )

    const list: IRequestNonameResponse[] = data?.data.body
    const pagination: IPagination = data?.data.pagination ?? null
    const regionFilterItem = getFilterItem.isFetched
        ? getFilterItem.data?.data.body.regions
        : requestFilterItem.region
    const classificationFilterItem = getFilterItem.isFetched
        ? getFilterItem.data?.data.body.specializations
        : requestFilterItem.classification

    useEffect(() => {
        data?.data.pagination &&
            setPagingParam({ ...pagingParam, page: String(Number(data?.data.pagination.number - 1)) })

        !data?.data.error && setExelData(JSON.stringify(data?.data.body))
    }, [data])

    return (
        <div className={'container d-flex flex-column'}>
            <div className={'d-flex justify-content-between'}>
                <h1>비회원 요청서 관리</h1>
                <div>
                    <ToExcelButton csvData={exelData} fileName={'요청서_견적서내역'} />
                </div>
            </div>

            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row'>
                            <CCol xs='auto'>
                                <CFormInput
                                    type='text'
                                    value={address}
                                    placeholder='주소로 검색'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setAddress(e.target.value)
                                    }
                                />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(`/api/noname/search/location?address=${address}&`)
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput
                                    value={userEmail}
                                    type='text'
                                    placeholder='고객 email로 검색'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setUserEmail(e.target.value)
                                    }
                                />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(`/api/noname/search/email?email=${userEmail.trim()}&`)
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs={'auto'} className='px-1'>
                                <CButton
                                    color='secondary'
                                    onClick={() => {
                                        setFetchingUrl(`/api/request/list?`)
                                        queryClient.invalidateQueries([
                                            'requestList',
                                            fetchingUrl,
                                            pagingParam.page,
                                            pagingParam.size,
                                        ])
                                    }}>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>
                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>
            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-1'>
                                등록일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className={'col-1'}>
                                타입
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className={'col-2 text-truncate'}>
                                요청서id
                            </CTableHeaderCell>
                            <CTableHeaderCell scope={'col'} className='col-2'>
                                전화번호
                            </CTableHeaderCell>
                            <CTableHeaderCell scope={'col'} className='col-2'>
                                고객 email
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                <RegionFilterDropDown data={regionFilterItem} onClick={setFetchingUrl} />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                <KindFilterDropDown
                                    data={classificationFilterItem}
                                    onClick={setFetchingUrl}
                                />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope={'col'} className='col-1'>
                                만료일
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item, index) => (
                            <CTableRow key={index}>
                                <CTableDataCell>
                                    <div style={{ lineHeight: '20px', textAlign: 'center' }}>
                                        {dayjs(item.createdAt).format('YY.MM.DD')}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    {requestTypeDecider(item) === 'detail' ? (
                                        <DetailRequestBadge />
                                    ) : (
                                        <SimpleRequestBadge />
                                    )}
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '100px' }}>
                                        <Link to={`/noname/${item.id}`}>{item.id}</Link>
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>{item.phoneNumber ?? '-'}</CTableDataCell>
                                <CTableDataCell>{item.email}</CTableDataCell>
                                <CTableDataCell>
                                    <div style={{ lineHeight: '20px', textAlign: 'center' }}>
                                        {locationSiConverter(item.address.siDo)}
                                        <br />
                                        {item.address.siGunGu}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    {classificationConverter(item.classifications)}
                                </CTableDataCell>
                                <CTableHeaderCell scope={'col'} className='col-1'>
                                    {dayjs().diff(dayjs(item.createdAt), 'day') - 30} 일
                                </CTableHeaderCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>

            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
        </div>
    )
}

export default RequestNonameList
