export const reviewStateFilter = [
    { name: '정상', value: 'false' },
    { name: '삭제', value: 'true' },
]

export type reviewState = 'common' | 'delete'

export const reviewStateConverter = (state: boolean) => {
    switch (state) {
        case true:
            return '블라인드'
        case false:
            return '정상'
    }
}

export const reviewDeleteStateConverter = (deleteState: string) => {
    switch (deleteState) {
        case 'force':
            return 'force'
        default:
            return ''
    }
}
