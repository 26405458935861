import {
    cilFullscreenExit,
    cilMoodBad,
    cilNoteAdd,
    cilSpeak,
    cilStar,
    cilTask,
    cilTruck,
    cilUserPlus,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import IssueWidget from '../components/widget/IssueWidget'
import { IAdminAlertResponse } from '../interface/IAdminAlertResponse'
import axios from '../util/api'

const Main = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [startAt, setStartAt] = useState<string>('')
    const [startTime, setStartTime] = useState<string>('')
    const [finishAt, setFinishAt] = useState<string>('')
    const [finishTime, setFinishTime] = useState<string>('')

    const checkToken = useQuery(
        'checkToken',
        () => {
            return axios.get('/api/login/check/token')
        },
        {
            enabled: false,
            onSuccess: (res) => {
                if (res.status === 401 || res.status === 403) {
                    alert('로그인이 만료되었거나 관리자계정이 아닙니다.')
                    navigate('/login')
                }
            },
        },
    )
    const healthCheck = useQuery(
        'health-check',
        () => {
            return axios.get('/health-check')
        },
        {
            enabled: false,
        },
    )

    const { data } = useQuery(
        'getAdminAlert',
        () => {
            return axios.get('view/main/new-alert')
        },
        {
            useErrorBoundary: false,
            onError: (err) => {
                console.log('캐싱서버 애러로 신규 이슈알림을 확인할 수 없습니다.')
            },
        },
    )

    const cronJobResult = useQuery('cronJobResult', () => {
        return axios.get('/view/main/cron')
    })

    const alertData: IAdminAlertResponse = data?.data.body

    const serverState = healthCheck.data?.data.state

    const changeToActive = useMutation(
        'changeToActive',
        () => {
            return axios.get('/health-check/active')
        },
        {
            onSuccess: (res) => {
                healthCheck.refetch()
            },
        },
    )

    const alertClear = useMutation(
        'alertClear',
        (target: string) => {
            return axios.get(`/view/main/reset-alert?target=${target}`)
        },
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries('getAdminAlert')
            },
        },
    )

    const validator = () => {
        if (!startAt || !startTime || !finishAt || !finishTime) {
            alert('값이 입력되지 않았습니다.')
            return false
        }
        if (parseInt(startTime) > 23 || parseInt(finishTime) > 23) {
            alert('시간은 0 - 23시 사이어야 합니다')
            return false
        }
        if (startTime.length !== 2 || finishTime.length !== 2) {
            alert('시간은 00 형식이어야 합니다.')
            return false
        }
        return true
    }

    const changeToMaintenance = useMutation(
        'changeToMaintenance',
        () => {
            return axios.get(
                `/health-check/maintenance?startAt=${startAt} ${startTime}:00:00.000&finishAt=${finishAt} ${finishTime}:00:00.000`,
            )
        },
        {
            onSuccess: (res) => {
                healthCheck.refetch()
            },
        },
    )

    useEffect(() => {
        checkToken.refetch()
        healthCheck.refetch()
    }, [])

    return (
        <div className={'container w-100 d-flex flex-column justify-content-center align-items-start'}>
            <div className={'col-10 border rounded p-3'}>
                <div className={'d-flex mb-3'}>
                    <h3 className={'mb-0 me-5'} style={{ lineHeight: '48px', textAlign: 'center' }}>
                        서버 모드 v2
                    </h3>
                    <CButton
                        className={'text-white'}
                        size={'lg'}
                        color={serverState === 'ACTIVE' ? 'success' : 'danger'}
                        style={{ cursor: 'default' }}>
                        {serverState === 'ACTIVE' ? '정상 서비스' : '서버점검중'}
                    </CButton>
                    <div className={'d-flex align-items-end ms-5'}>
                        <CButton
                            className={'text-white me-3'}
                            size={'sm'}
                            color={'success'}
                            disabled={serverState === 'ACTIVE'}
                            onClick={() => changeToActive.mutate()}>
                            정상서비스로 변경
                        </CButton>
                        <CButton
                            className={'text-white me-3'}
                            size={'sm'}
                            color={'danger'}
                            disabled={serverState === 'MAINTENANCE'}
                            onClick={() => {
                                if (validator()) {
                                    changeToMaintenance.mutate()
                                }
                            }}>
                            점검중으로 변경
                        </CButton>
                    </div>
                </div>
                <div className='ms-5 d-flex flex-column'>
                    <div className='d-flex'>
                        <CInputGroup className='mb-3 me-3' style={{ width: '300px' }}>
                            <CInputGroupText id='basic-addon1' style={{ width: '100px' }}>
                                점검 시작일
                            </CInputGroupText>
                            <CFormInput
                                placeholder='yyyy-MM-dd'
                                aria-label='Username'
                                aria-describedby='basic-addon1'
                                onChange={(e) => setStartAt(e.target.value.trim())}
                            />
                        </CInputGroup>
                        <CInputGroup className='mb-3' style={{ width: '200px' }}>
                            <CInputGroupText id='basic-addon1' style={{ width: '100px' }}>
                                시작 시간
                            </CInputGroupText>
                            <CFormInput
                                type='number'
                                min={0}
                                max={23}
                                placeholder='00 - 23'
                                aria-label='Username'
                                aria-describedby='basic-addon1'
                                onChange={(e) => setStartTime(e.target.value.trim())}
                            />
                        </CInputGroup>
                    </div>
                    <div className='d-flex'>
                        <CInputGroup className='mb-3 me-3' style={{ width: '300px' }}>
                            <CInputGroupText id='basic-addon1' style={{ width: '100px' }}>
                                점검 종료일
                            </CInputGroupText>
                            <CFormInput
                                placeholder='yyyy-MM-dd'
                                aria-label='Username'
                                aria-describedby='basic-addon1'
                                onChange={(e) => setFinishAt(e.target.value.trim())}
                            />
                        </CInputGroup>
                        <CInputGroup className='mb-3' style={{ width: '200px' }}>
                            <CInputGroupText id='basic-addon1' style={{ width: '100px' }}>
                                종료 시간
                            </CInputGroupText>
                            <CFormInput
                                type='number'
                                min={0}
                                max={23}
                                placeholder='00 - 23'
                                aria-label='Username'
                                aria-describedby='basic-addon1'
                                onChange={(e) => setFinishTime(e.target.value.trim())}
                            />
                        </CInputGroup>
                    </div>
                </div>
            </div>
            {alertData && (
                <div className={'col-10 border rounded p-3 mt-4'}>
                    <CRow className={'my-1'}>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                icon={<CIcon icon={cilUserPlus} height={24} />}
                                title={'신규가입'}
                                value={alertData.newUser}
                                valueSuffix={' 명'}
                                onClick={() => alertClear.mutate('NEW_USER')}
                            />
                        </CCol>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                icon={<CIcon icon={cilTruck} height={24} />}
                                title={'신규 대장 프로필 등록'}
                                value={alertData.newProvider}
                                onClick={() => alertClear.mutate('NEW_PROVIDER')}
                            />
                        </CCol>
                    </CRow>
                    <CRow className={'my-1'}>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'success'}
                                icon={<CIcon icon={cilNoteAdd} height={24} />}
                                title={'새로 등록된 요청서'}
                                value={alertData.newRequest}
                                onClick={() => alertClear.mutate('NEW_REQUEST')}
                            />
                        </CCol>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'success'}
                                icon={<CIcon icon={cilFullscreenExit} height={24} />}
                                title={'새로 매칭된 거래'}
                                value={alertData.newConstruction}
                                onClick={() => alertClear.mutate('NEW_CONSTRUCTION')}
                            />
                        </CCol>
                    </CRow>
                    <CRow className={'my-1'}>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'info'}
                                icon={<CIcon icon={cilSpeak} height={24} />}
                                title={'신규 문의'}
                                value={alertData.newInquiry}
                                onClick={() => alertClear.mutate('NEW_INQUIRY')}
                            />
                        </CCol>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'info'}
                                icon={<CIcon icon={cilMoodBad} height={24} />}
                                title={'신규 신고'}
                                value={alertData.newReport}
                                onClick={() => alertClear.mutate('NEW_REPORT')}
                            />
                        </CCol>
                    </CRow>
                    <CRow className={'my-1'}>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'dark'}
                                icon={<CIcon icon={cilTask} height={24} />}
                                title={'신규 건설업인증 요청'}
                                value={alertData.newCertification}
                                onClick={() => alertClear.mutate('NEW_CERTIFICATION')}
                            />
                        </CCol>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'dark'}
                                icon={<CIcon icon={cilStar} height={24} />}
                                title={'새롭게 작성된 후기'}
                                value={alertData.newReview}
                                onClick={() => alertClear.mutate('NEW_REVIEW')}
                            />
                        </CCol>
                    </CRow>
                    <CRow className={'my-1'}>
                        <CCol className={'position-relative'} xs={6}>
                            <IssueWidget
                                color={'success'}
                                icon={<CIcon icon={cilNoteAdd} height={24} />}
                                title={'신규 비회원 요청서'}
                                value={alertData.newRequestNoname}
                                onClick={() => alertClear.mutate('NEW_REQUEST_NONAME')}
                            />
                        </CCol>
                        <CCol className={'position-relative'} xs={6}></CCol>
                    </CRow>
                </div>
            )}
            {cronJobResult.data && (
                <div className={'col-10 border rounded p-3 my-4'}>
                    <CRow className={'my-1'}>
                        <CCol className={'position-relative'} xs={6}>
                            {cronJobResult.data.data.expiredRequest !== ''
                                ? cronJobResult.data.data.expiredRequest
                                : '자동삭제 익명요청서 0건'}
                        </CCol>
                        <CCol className={'position-relative'} xs={6}>
                            {cronJobResult.data.data.cancelIncompleteSplitBill !== ''
                                ? cronJobResult.data.data.cancelIncompleteSplitBill
                                : '자동취소 미완료 분할결제 0건'}
                        </CCol>
                    </CRow>
                </div>
            )}
        </div>
    )
}

export default Main
