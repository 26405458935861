import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPartnerDto } from '../../interface/IPartnerResponse'
import {
    IServiceRegionResponse,
    IServiceSpecializationResponse,
} from '../../interface/IServiceSettingResponse'
import FilterDropDown from '../../components/FilterDropDown'

const Partner = () => {
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [fetchingUrl, setFetchingUrl] = useState<string>(`/api/partner/list?`)

    const nameRef = useRef<HTMLInputElement>(null)
    const phoneRef = useRef<HTMLInputElement>(null)

    const { data } = useQuery(
        ['partnerList', fetchingUrl, pagingParam.page, pagingParam.size],
        () => {
            return axios.get(fetchingUrl + `page=${pagingParam.page}&size=${pagingParam.size}`)
        },
        {
            keepPreviousData: true,
        },
    )

    const searchPartner = useMutation(
        'searchPartner',
        (paramType: string) => {
            let body: any = {}
            switch (paramType) {
                case 'corpName':
                    body = { corpName: nameRef.current?.value }
                    break
                case 'phoneNumber':
                    body = { phoneNumber: phoneRef.current?.value }
                    break
            }
            return axios.post('api/partner/search', body)
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    ['partnerList', fetchingUrl, pagingParam.page, pagingParam.size],
                    (old: any) => {
                        return {
                            ...old,
                            data: {
                                ...old.data,
                                pagination: null,
                                body: data.data.body,
                            },
                        }
                    },
                )
            },
        },
    )

    const pagination: IPagination = data?.data.pagination
    const list: IPartnerDto[] = data?.data.body.partners
    const serviceRegions: IServiceRegionResponse[] = data?.data.body.regions
    const serviceSpecializations: IServiceSpecializationResponse[] = data?.data.body.specializations

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>대장목록</h1>
            </div>

            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={nameRef} type='text' placeholder='업체명으로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        searchPartner.mutate('corpName')
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CFormInput ref={phoneRef} type='text' placeholder='전화번호로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        searchPartner.mutate('phoneNumber')
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CButton
                                    color='secondary'
                                    onClick={() => {
                                        setFetchingUrl(`/api/partner/list?`)
                                        queryClient.invalidateQueries([
                                            'partnerList',
                                            fetchingUrl,
                                            pagingParam.page,
                                            pagingParam.size,
                                        ])
                                    }}>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-2'>
                                등록일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                업체명
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                연락처
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                <FilterDropDown
                                    name='지역'
                                    items={serviceRegions.map((item) => {
                                        return { name: item.name, value: item.name }
                                    })}
                                    requestUrl='region='
                                    onClick={(param: string) => {
                                        if (fetchingUrl === '/api/partner/list?') {
                                            setFetchingUrl(`/api/partner/filter?${param}&`)
                                        } else {
                                            let indicator = -1
                                            fetchingUrl
                                                .split('?')[1]
                                                .split('&')
                                                .forEach((item, index) => {
                                                    if (item.includes('region')) {
                                                        indicator = index
                                                    }
                                                })
                                            if (indicator === -1) {
                                                setFetchingUrl(fetchingUrl + `${param}`)
                                            } else {
                                                setFetchingUrl(
                                                    fetchingUrl.replace(
                                                        fetchingUrl.split('?')[1].split('&')[indicator],
                                                        param,
                                                    ),
                                                )
                                            }
                                        }
                                    }}
                                />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                <FilterDropDown
                                    name='분야'
                                    items={serviceSpecializations.map((item) => {
                                        return { name: item.name, value: item.name }
                                    })}
                                    requestUrl='specialization='
                                    onClick={(param: string) => {
                                        if (fetchingUrl === '/api/partner/list?') {
                                            setFetchingUrl(`/api/partner/filter?${param}&`)
                                        } else {
                                            let indicator = -1
                                            fetchingUrl
                                                .split('?')[1]
                                                .split('&')
                                                .forEach((item, index) => {
                                                    if (item.includes('specialization')) {
                                                        indicator = index
                                                    }
                                                })
                                            if (indicator === -1) {
                                                setFetchingUrl(fetchingUrl + `${param}`)
                                            } else {
                                                setFetchingUrl(
                                                    fetchingUrl.replace(
                                                        fetchingUrl.split('?')[1].split('&')[indicator],
                                                        param,
                                                    ),
                                                )
                                            }
                                        }
                                    }}
                                />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                리뷰갯수
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                리뷰평점
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래횟수
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item) => (
                            <CTableRow key={item.id}>
                                <CTableDataCell>{dayjs(item.createdAt).format('YY.MM.DD')}</CTableDataCell>
                                <CTableDataCell>
                                    <Link to={`/partner/${item.id}`}>{item.corpName}</Link>
                                </CTableDataCell>
                                <CTableDataCell>{item.phoneNumber}</CTableDataCell>
                                <CTableDataCell>{item.region}</CTableDataCell>
                                <CTableDataCell style={{ lineHeight: '20px' }}>
                                    {item.specialization.join('/')}
                                </CTableDataCell>
                                <CTableDataCell>{item.countOfReviews}</CTableDataCell>
                                <CTableDataCell>{item.averageScoreOfReviews}</CTableDataCell>
                                <CTableDataCell>{item.countOfTransactions}</CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>

            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
        </div>
    )
}

export default Partner
