import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'

import {CBadge} from '@coreui/react'

interface AppSidebarNavProps {
  items: Items[]
}
interface Items {
  component: React.ForwardRefExoticComponent<any & React.RefAttributes<HTMLAnchorElement>>,
  name: string,
  to: string,
  icon?: React.ForwardRefExoticComponent<any & React.RefAttributes<HTMLAnchorElement>>,
  badge?: {
    color: string,
    text: string
  },
  items?: SubItems[]
}
interface SubItems {
    component: React.ForwardRefExoticComponent<any & React.RefAttributes<HTMLAnchorElement>>,
    name: string,
    to: string,
}

export const AppSidebarNav = ({ items }: AppSidebarNavProps) => {
  const location = useLocation()
  const navLink = (name: string, icon: React.ForwardRefExoticComponent<any & React.RefAttributes<HTMLAnchorElement>> | undefined , badge?: {color: string, text: string} | undefined) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item: Items, index: number) => {
    const { component, name, badge, icon, ...rest } = item
    const Component: React.ForwardRefExoticComponent<any & React.RefAttributes<HTMLAnchorElement>> = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item: Items, index: number) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
           navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

