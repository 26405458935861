import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import FilterDropDown from '../../components/FilterDropDown'
import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import { IVocForAdminResponse } from '../../interface/IVocResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import { vocStateConverter, vocStateFilter } from '../../util/converter/VocStateConverter'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'

const Report = () => {
    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)

    const authorIdRef = useRef<HTMLInputElement>(null)
    const reportedIdRef = useRef<HTMLInputElement>(null)
    const contentRef = useRef<HTMLInputElement>(null)

    const [fetchingUrl, setFetchingUrl] = useState<string>(`api/voc/report?`)

    const { data } = useQuery(['ReportList', fetchingUrl, pagingParam.page, pagingParam.size], () => {
        return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const list: IVocForAdminResponse[] = data?.data.body
    const pagination: IPagination = data?.data.pagination ?? null

    useEffect(() => {
        authorIdRef.current && (authorIdRef.current.value = '')
        reportedIdRef.current && (reportedIdRef.current.value = '')
        contentRef.current && (contentRef.current.value = '')
    }, [fetchingUrl, pagingParam])

    return (
        <>
            <div>
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={contentRef} type='text' placeholder='내용으로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(
                                            `/api/voc/search/content?category=report&content=${contentRef.current?.value.trim()}&`,
                                        )
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={authorIdRef} type='text' placeholder='신고자 email로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(
                                            `/api/voc/search/email?category=report&email=${authorIdRef.current?.value.trim()}&`,
                                        )
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput
                                    ref={reportedIdRef}
                                    type='text'
                                    placeholder='신고대상 email로 검색'
                                />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(
                                            `/api/voc/search/reported?email=${reportedIdRef.current?.value.trim()}&`,
                                        )
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>
            </div>

            <CCard className='mb-4'>
                <CCardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <input
                            className='fs-3 fw-4 border-0'
                            value={'신고관리'}
                            readOnly={true}
                            disabled={true}
                        />
                        <div className='col-2 d-flex flex-column justify-content-end my-3'>
                            <PaginationFilter
                                color='secondary'
                                selected={pagingParam.size}
                                page={pagingParam.page}
                                onClick={setPagingParam}
                            />
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CTable striped hover style={{ tableLayout: 'fixed' }}>
                        <CTableHead>
                            <CTableRow>
                                {/* <CTableHeaderCell scope='col' className='col-1'>#</CTableHeaderCell> */}
                                <CTableHeaderCell scope='col' className='col-1'>
                                    작성일자
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    신고자 id
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    신고자email
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    제목
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    <FilterDropDown
                                        name='상태'
                                        items={vocStateFilter}
                                        requestUrl={`api/voc/filter/state?category=report&state=`}
                                        onClick={setFetchingUrl}></FilterDropDown>
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    신고대상 id
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    신고대상email
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>

                        {list === null ? (
                            <CTableBody>
                                <CTableRow>
                                    <CTableHeaderCell scope='row'>1</CTableHeaderCell>
                                    <CTableDataCell>{` '에러 발생'`}</CTableDataCell>
                                    <CTableDataCell> </CTableDataCell>
                                    <CTableDataCell> </CTableDataCell>
                                    <CTableDataCell> </CTableDataCell>
                                    <CTableDataCell> </CTableDataCell>
                                    <CTableDataCell> </CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        ) : (
                            <CTableBody>
                                {list.map((item: IVocForAdminResponse, idx) => (
                                    <CTableRow style={{ lineHeight: '30px' }} key={idx}>
                                        <CTableDataCell>
                                            {dayjs(item.voc.createdAt).format('YY.MM.DD')}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div
                                                className='d-inline-block text-truncate'
                                                style={{ maxWidth: '100px' }}>
                                                <Link to={`/user/${item.voc.authorId}`}>
                                                    {' '}
                                                    {item.voc.authorId}
                                                </Link>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell className='text-truncate'>
                                            {item.authorEmail}
                                        </CTableDataCell>
                                        <CTableDataCell className='text-truncate'>
                                            <Link to={`/report/${item.voc.id}`}>{item.voc.title}</Link>
                                        </CTableDataCell>
                                        <CTableDataCell>{vocStateConverter(item.voc.state)}</CTableDataCell>
                                        <CTableDataCell>
                                            <div
                                                className='d-inline-block text-truncate'
                                                style={{ maxWidth: '100px' }}>
                                                <Link to={`/user/${item.voc.reportedUserId}`}>
                                                    {' '}
                                                    {item.voc.reportedUserId}
                                                </Link>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell className='text-truncate'>
                                            {item.reportedEmail}
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        )}
                    </CTable>
                    <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
                </CCardBody>
            </CCard>
        </>
    )
}

export default Report
