export interface IWithdrawResponse {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    rAccountNo: string
    rAmount: string
    rBankCd: string
    rBankNm: string
    rCno: string
    rMgrRemAmt: string
    rOrderNo: string
    rTranDate: string
    resCd: string
    resMsg: string
    constructionId: string
}

export interface IPaymentListResponse {
    id: string
    createdAt: string
    updatedAt: string
    customerId: string
    customerEmail: string
    partnerId: string
    providerName: string
    amount: string
    resMsg: string
    shopOrderNo: string
    statusMessage: string
    approvalDate: string
    paymentMethod: string
    payMethodTypeCode: string
    usedPoint: number
    pgCno: string
    requestId: string
    estimateId: string
    constructionId: string
    cardInfo: ICardInfoResponse
    virtualAccountInfo: IVirtualInfoResponse
    usedCouponAmount: number
    comment: string
}

export interface ICardInfoResponse {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt: string
    cardNo: string
    issuerCode: string
    issuerName: string
    installmentMonth: string
    freeInstallmentTypeCode: string
}

export const defaultCardInfo = {
    id: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    cardNo: '',
    issuerCode: '',
    issuerName: '',
    installmentMonth: '',
    freeInstallmentTypeCode: '',
}

export interface IVirtualInfoResponse {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt: string
    bankCode: string
    bankName: string
    accountNo: string
    depositName: string
    expireDate: string
}

export interface ISplitBillListResponse {
    paymentSplitInfoId: string
    createdAt: string
    customerEmail: string
    corpName: string
    totalAmount: string
    countOfSplit: number
    state: string
    pointUsed: number
    couponUsed: number
    amountToBePaid: string
    requestId: string
    userId: string
    partnerId: string
}

export interface ISplitBillDto {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt: string
    totalAmount: string
    splitInfo: {
        index: number
        method: string
        amount: string
        paymentId: string
    }[]
    constructionId: string
    userId: string
    isCompleted: boolean
    spare: {
        couponId: string
        couponAmount: string
        pointUsed: string
    }
}

export interface ISplitBillDetailResponse {
    userInfo: {
        id: string
        name: string
        email: string
        phoneNumber: string
    }
    constructionInfo: {
        id: string
        address: string
        partnerName: string
        partnerPhoneNumber: string
        partnerId: string
        totalAmount: string
        state: string
        matched: boolean
        splitInfoId: string
    }
    splitInfo: ISplitBillDto
    state: string
    paymentList: IPaymentListResponse[]
}
