import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React from 'react'

type props = {
    children: React.ReactNode
}

type states = {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<props, states> {
    constructor(props: props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ hasError: true })
    }

    goHome = () => {
        window.location.replace('/')
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='bg-light min-vh-100 d-flex flex-row align-items-center'>
                    <CContainer>
                        <CRow className='justify-content-center'>
                            <CCol md={6}>
                                <span className='clearfix'>
                                    <h1 className='float-start display-3 me-4'>500</h1>
                                    <h4 className='pt-3'>문제가 발생했습니다.</h4>
                                    <p className='text-medium-emphasis float-start'>
                                        잠시 후 다시 시도해 주세요.
                                        <br /> 문제가 지속되면 개발팀으로 연락주세요.
                                    </p>
                                </span>
                                <CButton
                                    className={'text-white fw-bold my-3'}
                                    color={'primary'}
                                    onClick={this.goHome}>
                                    홈으로
                                </CButton>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            )
        }
        return this.props.children
    }
}
