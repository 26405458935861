type YellowDotProps = {
    top: string
    right: string
}

// 기본 position absolute 이므로 반드시 top, right 를 지정해줘야함. px, % 등 단위는 상관없음
const YellowDot = ({ top, right }: YellowDotProps) => {
    const dotStyle = {
        top: top,
        right: right,
    }

    return (
        <div
            className={'position-absolute rounded-circle'}
            style={{ width: '10px', height: '10px', backgroundColor: 'var(--cui-yellow)', ...dotStyle }}
        />
    )
}

export default YellowDot
