import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { dayjs } from '../../util/dayjs'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import axios from '../../util/api'
import { IConstructionResponse } from '../../interface/IConstructionResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { currencyFormatter } from '../../util/formatter'

const Construction = () => {
    const navigate = useNavigate()

    const [fetchingUrl, setFetchingUrl] = useState<string>('/api/construction/list?')
    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)

    const { data } = useQuery(
        ['constructionList', fetchingUrl, pagingParam.page, pagingParam.size],
        () => {
            return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
        },
        {
            keepPreviousData: true,
        },
    )

    const list: IConstructionResponse[] = data?.data.body
    const pagination: IPagination = data?.data.pagination ?? null

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>매칭된 거래 목록</h1>
            </div>

            <div className='my-5 row'>
                {/*검색필터 */}
                {/*<div className='col-10'>*/}
                {/*    <CForm className='d-flex flex-row w-100'>*/}
                {/*        <div className='d-flex flex-row me-2'>*/}
                {/*            <CCol xs='auto'>*/}
                {/*                <CFormInput ref={emailRef} type='text' placeholder='email로 검색' />*/}
                {/*            </CCol>*/}
                {/*            <CCol xs='auto' className='px-1'>*/}
                {/*                <LoadingButton*/}
                {/*                    type='button'*/}
                {/*                    className='mb-3'*/}
                {/*                    isLoading={searchUser.isLoading}*/}
                {/*                    onClick={() => searchUser.mutate('email')}>*/}
                {/*                    검색*/}
                {/*                </LoadingButton>*/}
                {/*            </CCol>*/}
                {/*            <CCol xs='auto'>*/}
                {/*                <CFormInput ref={phoneRef} type='text' placeholder='전화번호로 검색' />*/}
                {/*            </CCol>*/}
                {/*            <CCol xs='auto' className='px-1'>*/}
                {/*                <LoadingButton*/}
                {/*                    type='button'*/}
                {/*                    className='mb-3'*/}
                {/*                    isLoading={searchUser.isLoading}*/}
                {/*                    onClick={() => searchUser.mutate('phoneNumber')}>*/}
                {/*                    검색*/}
                {/*                </LoadingButton>*/}
                {/*            </CCol>*/}
                {/*            <CCol xs='auto'>*/}
                {/*                <CButton*/}
                {/*                    color='secondary'*/}
                {/*                    onClick={() =>*/}
                {/*                        queryClient.invalidateQueries([*/}
                {/*                            'userList',*/}
                {/*                            fetchingUrl,*/}
                {/*                            pagingParam.page,*/}
                {/*                            pagingParam.size,*/}
                {/*                        ])*/}
                {/*                    }>*/}
                {/*                    리스트 초기화*/}
                {/*                </CButton>*/}
                {/*            </CCol>*/}
                {/*        </div>*/}
                {/*    </CForm>*/}
                {/*</div>*/}

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-2'>
                                거래확정일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래상태
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                공사시작일/완료일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                요청서 id
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                최종견적
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                고객email
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래업체
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                결제상태
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list ? (
                            list.map((item) => (
                                <CTableRow key={item.id}>
                                    <CTableDataCell>
                                        {dayjs(item.createdAt).format('YYYY-MM-DD')}
                                    </CTableDataCell>
                                    <CTableDataCell>{item.state}</CTableDataCell>
                                    <CTableDataCell>
                                        <div>
                                            {dayjs(item.startedAt).format('YYYY-MM-DD') === 'Invalid Date'
                                                ? '공사전'
                                                : dayjs(item.startedAt).format('YYYY-MM-DD')}
                                            <br />
                                            {dayjs(item.endAt).format('YYYY-MM-DD') === 'Invalid Date'
                                                ? '미완료'
                                                : dayjs(item.endAt).format('YYYY-MM-DD')}
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div
                                            className='d-inline-block text-truncate'
                                            style={{ maxWidth: '100px' }}>
                                            <Link to={`/request/${item.requestId}`}>{item.requestId}</Link>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>{currencyFormatter(item.totalAmount)}</CTableDataCell>
                                    <CTableDataCell onClick={() => navigate(`/user/${item.userId}`)}>
                                        {item.userEmail}
                                    </CTableDataCell>
                                    <CTableDataCell onClick={() => navigate(`/partner/${item.partnerId}`)}>
                                        {item.partnerCorpName}
                                    </CTableDataCell>
                                    <CTableDataCell>{item.paymentState}</CTableDataCell>
                                </CTableRow>
                            ))
                        ) : (
                            <CTableRow>
                                <CTableDataCell colSpan={9}>목록이 없습니다.</CTableDataCell>
                            </CTableRow>
                        )}
                    </CTableBody>
                </CTable>
            </div>

            {/*페이징 indicator*/}
            {pagination && <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />}
        </div>
    )
}

export default Construction
