import { CCarousel, CCarouselItem } from '@coreui/react'
import ImageZoom from './ImageZoom'
import React from 'react'
import { Link } from 'react-router-dom'

interface ImageSlideProps {
    /**
     * 뒤로가기 앞으로가기 버튼 표시
     */
    controls?: boolean
    /**
     * 하단 페이지 인디케이터 표시
     */
    indicators?: boolean
    /**
     * 페이드아웃으로 이미지 체인지
     */
    crossfade?: boolean
    /**
     * 가장먼저 보여줄 이미지
     */
    activeIndex?: number
    /**
     * 이미지에 적용되는 클래스
     */
    className?: string
    /**
     * 자동 스크롤 시간 ms 단위
     */
    interval?: number | boolean
    /**
     * 마우스 오버시 자동 스크롤 멈춤
     */
    pause?: boolean | 'hover'
    /**
     * 이미지 배열
     *
     * 링크와 연결시킬 이미지라면 link 값을 넣어줘야함
     */
    images: slideImageType[]
    /**
     * 이미지 클릭시 링크 여부
     */
    linked?: boolean
    /**
     * 이미지 leftItem, centerItem, rightItem, bgColor, position높이 비율 (0-1)
     */
    height: number
    /**
     * 이미지가 1장일 때 auto play 여부
     */
    wrap?: boolean
    /**
     * 이미지 둥글게
     */
    rounded?: boolean
    /**
     * 비활성상태인 컨텐츠인 경우
     */
    disabled?: {
        disable?: boolean
        message?: string
    }
}

export type slideImageType = { id: string; image: string; link?: string }

/**
 * 이미지 슬라이드 컴포넌트.
 *
 * 링크가 걸린 이미지는 링크로 이동.
 *
 * 그 외에는 클릭하면 확대모달 띄움
 */
const ImageSlide = ({
    controls,
    indicators,
    crossfade,
    activeIndex,
    className,
    interval,
    pause,
    images,
    linked,
    height,
    wrap = true,
    rounded = true,
    disabled = { disable: false, message: '' },
}: ImageSlideProps) => {
    const imageHeight = `calc(var(--doc-height) * ${height})`
    const roundedClass = rounded ? 'rounded rounded-3' : ''

    return (
        <div className={'position-relative w-100 d-flex justify-content-center'}>
            {images.length === 0 ? (
                <div className={'w-100'} style={{ height: imageHeight }}>
                    <span>등록된 이미지가 없습니다.</span>
                </div>
            ) : images.length === 1 ? (
                <ImageZoom
                    className={`d-block w-100 ${roundedClass}`}
                    src={images[0].image}
                    height={height}
                />
            ) : (
                <CCarousel
                    controls={controls ?? false}
                    indicators={indicators ?? false}
                    transition={crossfade ? 'crossfade' : 'slide'}
                    activeIndex={activeIndex ?? 0}
                    className={className ?? ''}
                    interval={interval ?? 5000}
                    touch={true}
                    wrap={wrap}
                    pause={pause ?? false}>
                    {images.map((image, index) => (
                        <CCarouselItem key={image.id + index} style={{ cursor: 'pointer' }}>
                            {linked ? (
                                <Link to={'/my-info/notice/banner/' + image.id}>
                                    <div
                                        className={`d-flex justify-content-center w-100 ${roundedClass}`}
                                        style={{ height: imageHeight }}>
                                        <img
                                            className={`${roundedClass}`}
                                            src={image.image}
                                            alt={''}
                                            style={{ objectFit: 'fill', width: '100%', height: '100%' }}
                                        />
                                    </div>
                                </Link>
                            ) : (
                                <ImageZoom
                                    className={`d-block w-100 ${roundedClass}`}
                                    src={image.image}
                                    height={height}
                                />
                            )}
                        </CCarouselItem>
                    ))}
                </CCarousel>
            )}

            {disabled?.disable && (
                <div
                    className={
                        'position-absolute d-flex justify-content-center align-items-center w-100 top-0'
                    }
                    style={{
                        background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                        fontSize: '21px',
                        fontWeight: '400',
                        color: 'white',
                        height: imageHeight,
                    }}>
                    {disabled.message}
                </div>
            )}
        </div>
    )
}

export default ImageSlide
