import { useParams } from 'react-router'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCardText,
    CFormLabel,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
} from '@coreui/react'
import { useQuery } from 'react-query'
import axios from '../../util/api'
import { ISplitBillDetailResponse } from '../../interface/IPaymentResponse'
import { dayjs } from '../../util/dayjs'
import { useNavigate } from 'react-router-dom'
import SendSMSModal from '../../components/modal/SendSMSModal'
import React, { useState } from 'react'
import SendPushMessageModal from '../../components/modal/SendPushMessageModal'
import constructionStateConverter from '../../util/converter/constructionStateConverter'
import { currencyFormatter } from '../../util/formatter'
import PaymentRefundModal from '../../components/modal/PaymentRefundModal'

const SplitBillDetail = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const { data, error } = useQuery(['getSplitBillDetail', id], () => {
        return axios.get(`/api/payment/split/${id}`)
    })

    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false)
    const [pushModalVisible, setPushModalVisible] = useState<boolean>(false)
    const [refundModalVisible, setRefundModalVisible] = useState<boolean>(false)
    const [smsTarget, setSmsTarget] = useState<string>('')
    const [pushTarget, setPushTarget] = useState<{ name: string; id: string }>({
        name: '',
        id: '',
    })
    const refundDataInit = {
        paymentId: '',
        pgCno: '',
        cancelPgCno: '',
        usedPoint: 0,
    }
    const [refundData, setRefundData] = useState<{
        paymentId: string
        pgCno: string
        cancelPgCno: string
        usedPoint: number
    }>(refundDataInit)

    const detail: ISplitBillDetailResponse = data?.data.body

    return (
        <>
            <div className='container d-flex flex-column w-100'>
                <div>
                    <h1>분할결제 상세내역</h1>
                </div>
                <div className={'d-flex flex-wrap col-12'}>
                    <div className={'col-4 d-flex flex-column p-3'}>
                        <CCard>
                            <CCardHeader className={'fw-bold fs-4'}>고객정보</CCardHeader>
                            <CCardBody>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>이 름</span>
                                    {detail.userInfo.name}
                                </CCardText>
                                <CCardText className={'d-flex justify-content-between my-2'}>
                                    <div>
                                        <span className={'fw-bold me-2'}>email</span>
                                        {detail.userInfo.email}
                                    </div>
                                    <CButton
                                        size={'sm'}
                                        onClick={() => navigate(`/user/${detail.userInfo.id}`)}>
                                        정보보기
                                    </CButton>
                                </CCardText>
                                <CCardText className={'d-flex justify-content-between'}>
                                    <div>
                                        <span className={'fw-bold me-2'}>연락처</span>
                                        {detail.userInfo.phoneNumber}
                                    </div>
                                    <div>
                                        <CButton
                                            className={'me-2'}
                                            size={'sm'}
                                            onClick={() => {
                                                setSmsTarget(detail.userInfo.phoneNumber)
                                                setSmsModalVisible(true)
                                            }}>
                                            문자발송
                                        </CButton>
                                        <CButton
                                            size={'sm'}
                                            onClick={() => {
                                                setPushTarget({
                                                    name: detail.userInfo.name,
                                                    id: detail.userInfo.id,
                                                })
                                                setPushModalVisible(true)
                                            }}>
                                            push발송
                                        </CButton>
                                    </div>
                                </CCardText>
                            </CCardBody>
                        </CCard>
                    </div>
                    <div className={'d-flex flex-column col-8 p-3'}>
                        <CCard>
                            <CCardHeader className={'fw-bold fs-4'}>공사정보</CCardHeader>
                            <CCardBody>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>주 소</span>
                                    {detail.constructionInfo.address}
                                </CCardText>
                                <CCardText className={'d-flex justify-content-between'}>
                                    <div>
                                        <span className={'fw-bold me-2'}>대 장</span>{' '}
                                        {detail.constructionInfo.partnerName} -{' '}
                                        {detail.constructionInfo.partnerPhoneNumber}
                                    </div>
                                    <div>
                                        <CButton
                                            size={'sm'}
                                            onClick={() =>
                                                navigate(`/partner/${detail.constructionInfo.partnerId}`)
                                            }>
                                            정보보기
                                        </CButton>
                                        <CButton
                                            className={'mx-2'}
                                            size={'sm'}
                                            onClick={() => {
                                                setSmsTarget(detail.constructionInfo.partnerPhoneNumber)
                                                setSmsModalVisible(true)
                                            }}>
                                            문자발송
                                        </CButton>
                                        <CButton
                                            size={'sm'}
                                            onClick={() => {
                                                setPushTarget({
                                                    name: detail.constructionInfo.partnerName,
                                                    id: detail.constructionInfo.partnerId,
                                                })
                                                setPushModalVisible(true)
                                            }}>
                                            push발송
                                        </CButton>
                                    </div>
                                </CCardText>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>견 적</span>
                                    {currencyFormatter(detail.constructionInfo.totalAmount)}
                                </CCardText>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>상 태</span>{' '}
                                    {constructionStateConverter(detail.constructionInfo.state)}
                                </CCardText>
                            </CCardBody>
                        </CCard>
                    </div>
                    <div className={'col-12 p-3'}>
                        <CCard>
                            <CCardHeader className={'fw-bold fs-4'}>
                                분할결제정보{' '}
                                <span className={'fw-normal fs-6'}>
                                    {detail.constructionInfo.splitInfoId === detail.splitInfo.id ? (
                                        ''
                                    ) : (
                                        <>
                                            <span className={'fw-bold text-danger'}>
                                                이 공사는 아래 결제를 취소하고 다른 결제방식으로 진행/ 완료
                                                또는 거래취소 되었습니다.
                                            </span>
                                            <span className={'text-decoration-underline'}>
                                                {' '}
                                                진행한 모든 결제건이 <strong>[환불완료]</strong>상태여야
                                                합니다.
                                            </span>
                                        </>
                                    )}
                                </span>
                            </CCardHeader>
                            <CCardBody>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>업 데 이 트</span>{' '}
                                    {dayjs(detail.splitInfo.updatedAt).format('YY.MM.DD')}
                                </CCardText>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>포인트사용</span>{' '}
                                    {currencyFormatter(detail.splitInfo.spare?.pointUsed ?? '0')}
                                    <br /> <span className={'fw-bold me-2'}>쿠 폰 사 용</span>{' '}
                                    {currencyFormatter(detail.splitInfo.spare?.couponAmount ?? '0')}
                                </CCardText>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>결 제 총 액</span>{' '}
                                    {currencyFormatter(
                                        String(
                                            Number(detail.constructionInfo.totalAmount) -
                                                Number(detail.splitInfo.spare?.couponAmount ?? '0') -
                                                Number(detail.splitInfo.spare?.pointUsed ?? '0'),
                                        ),
                                    )}
                                </CCardText>
                                <CCardText>
                                    <span className={'fw-bold me-2'}>결 제 상 태</span> {detail.state}
                                    {detail.state === '조치필요' && (
                                        <>
                                            <br />
                                            <br />
                                            <span>
                                                환불처리에서{' '}
                                                <strong className={'text-decoration-underline'}>
                                                    애러가 발생한 결제내역입니다.
                                                </strong>{' '}
                                                환불되지 않은 결제건을 강제환불해주세요.
                                            </span>
                                        </>
                                    )}
                                </CCardText>
                            </CCardBody>
                        </CCard>
                    </div>
                </div>
                <div className={'d-flex flex-column w-100 my-3'}>
                    <div className={'d-flex flex-column col-12 p-3'}>
                        <div className={'d-flex justify-content-between'}>
                            <span className={'fw-bold fs-4'}>건별 결제내역</span>
                            <div>
                                <CFormLabel>
                                    <div
                                        className={'btn btn-sm btn-success text-success mx-2 cursor-default'}>
                                        {'    '}
                                    </div>
                                    정상결제
                                </CFormLabel>
                                <CFormLabel>
                                    <div
                                        className={'btn btn-sm btn-warning text-warning mx-2 cursor-default'}>
                                        {'    '}
                                    </div>
                                    환불완료
                                </CFormLabel>
                                <CFormLabel>
                                    <div className={'btn btn-sm btn-danger text-danger mx-2 cursor-default'}>
                                        {'    '}
                                    </div>
                                    환불애러
                                </CFormLabel>
                                <CFormLabel>
                                    <div
                                        className={
                                            'btn btn-sm btn-secondary text-secondary mx-2 cursor-default'
                                        }>
                                        {'    '}
                                    </div>
                                    미결제
                                </CFormLabel>
                            </div>
                        </div>
                        {detail.splitInfo.splitInfo.map((item, index) => (
                            <CCard className={'my-2'}>
                                <CCardHeader
                                    className={
                                        `${
                                            detail.state === '조치필요' && item.paymentId !== null
                                                ? 'bg-danger'
                                                : item.paymentId !== null
                                                ? detail.paymentList[index].comment.startsWith('c')
                                                    ? 'bg-warning'
                                                    : 'bg-success'
                                                : 'bg-secondary'
                                        }` + ' fw-bold text-white'
                                    }>
                                    결제 {index + 1} - {currencyFormatter(item.amount)}{' '}
                                    {item.method === 'CARD' ? '카드' : '애스크로'}
                                </CCardHeader>
                                <CCardBody>
                                    <CCardText className={'fw-bold'}>
                                        {item.paymentId === null
                                            ? '미결제'
                                            : detail.paymentList[index].comment.startsWith('c')
                                            ? '환불완료'
                                            : '결제완료'}
                                        {detail.state === '조치필요' && item.paymentId !== null && (
                                            <CButton
                                                color={'info'}
                                                size={'sm'}
                                                className={'ms-3 fw-bold text-white'}
                                                onClick={() => {
                                                    setRefundData({
                                                        ...refundData,
                                                        paymentId: detail.paymentList[index].id,
                                                        pgCno: detail.paymentList[index].pgCno,
                                                    })
                                                    setRefundModalVisible(true)
                                                }}>
                                                강제환불 진행하기
                                            </CButton>
                                        )}
                                    </CCardText>
                                    {item.paymentId !== null && (
                                        <CTable className={'mt-2'}>
                                            <CTableHead>
                                                <CTableHeaderCell scope='col'>결제일</CTableHeaderCell>
                                                {detail.paymentList[index].comment.startsWith('c') && (
                                                    <CTableHeaderCell scope='col'>환불일</CTableHeaderCell>
                                                )}
                                                {detail.paymentList[index].payMethodTypeCode === '11' && (
                                                    <CTableHeaderCell scope='col'>카드사</CTableHeaderCell>
                                                )}
                                                {detail.paymentList[index].payMethodTypeCode === '22' && (
                                                    <CTableHeaderCell scope='col'>
                                                        은행/입금계좌
                                                    </CTableHeaderCell>
                                                )}
                                                <CTableHeaderCell scope='col'>결과메세지</CTableHeaderCell>
                                                <CTableHeaderCell scope='col'>결제id(pgCno)</CTableHeaderCell>
                                                {detail.paymentList[index].comment.startsWith('c') && (
                                                    <CTableHeaderCell scope='col'>
                                                        환불id(cancelPgCno)
                                                    </CTableHeaderCell>
                                                )}
                                            </CTableHead>
                                            <CTableBody>
                                                <CTableDataCell>
                                                    {dayjs(detail.paymentList[index].createdAt).format(
                                                        'YY.MM.DD',
                                                    )}
                                                </CTableDataCell>
                                                {detail.paymentList[index].comment.startsWith('c') && (
                                                    <CTableDataCell>
                                                        {dayjs(detail.paymentList[index].approvalDate).format(
                                                            'YY.MM.DD',
                                                        )}
                                                    </CTableDataCell>
                                                )}
                                                {detail.paymentList[index].payMethodTypeCode === '11' && (
                                                    <CTableDataCell>
                                                        {detail.paymentList[index].cardInfo.issuerName}
                                                    </CTableDataCell>
                                                )}
                                                {detail.paymentList[index].payMethodTypeCode === '22' && (
                                                    <CTableDataCell>
                                                        {
                                                            detail.paymentList[index].virtualAccountInfo
                                                                .bankName
                                                        }{' '}
                                                        {
                                                            detail.paymentList[index].virtualAccountInfo
                                                                .accountNo
                                                        }
                                                    </CTableDataCell>
                                                )}
                                                <CTableDataCell>
                                                    {detail.paymentList[index].resMsg}
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    {detail.paymentList[index].pgCno}
                                                </CTableDataCell>
                                                {detail.paymentList[index].comment.startsWith('c') && (
                                                    <CTableDataCell>
                                                        {detail.paymentList[index].comment.substring(1)}
                                                    </CTableDataCell>
                                                )}
                                            </CTableBody>
                                        </CTable>
                                    )}
                                </CCardBody>
                            </CCard>
                        ))}
                    </div>
                </div>
            </div>
            <SendSMSModal visible={smsModalVisible} setVisible={setSmsModalVisible} phoneNumber={smsTarget} />
            <SendPushMessageModal
                visible={pushModalVisible}
                setVisible={setPushModalVisible}
                userId={pushTarget.id}
                receiverName={pushTarget.name}
            />
            <PaymentRefundModal
                visible={refundModalVisible}
                setVisible={setRefundModalVisible}
                data={refundData}
            />
        </>
    )
}

export default SplitBillDetail
