import { ImageFile } from '../../../components/imgae/ImageSelector'

export interface IVocCommentInput {
    imageList: ImageFile[]
    targetId?: string
    category: string
    comment: string
}

export const createVocCommentInputInit = {
    imageList: [],
    targetId: '',
    category: '',
    title: '',
    comment: '',
}
