import React, { useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CFormInput,
    CInputGroup,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import PaginationBar from '../../components/PaginationBar'
import { dayjs } from '../../util/dayjs'
import ToExcelButton from '../../components/ToExcelButton'
import { useQuery } from 'react-query'
import axios from '../../util/api'
import { IPointListOfDateResponse } from '../../interface/IPointResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { Link } from 'react-router-dom'
import { pointTypeConverter } from '../../util/converter/pointTypeConverter'

const PointDate = () => {
    const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'))

    const [pagingParam, setPagingParam] = useState({
        page: '0',
        size: '10',
    })

    const { data } = useQuery(
        ['pointListByDate', date, pagingParam],
        () => {
            return axios.get(
                `/api/point/list/date?date=${date}&page=${pagingParam.page}&size=${pagingParam.size}`,
            )
        },
        {
            keepPreviousData: true,
        },
    )

    const list: IPointListOfDateResponse[] = data?.data.body.list
    const totalGain: number = data?.data.body.totalGain ?? 0
    const totalUse: number = data?.data.body.totalUse ?? 0
    const paginationDto: IPagination = data?.data.body.pagination

    return (
        <>
            <div className='d-flex justify-content-between'>
                <div>
                    <ToExcelButton csvData={JSON.stringify(list)} fileName={'일자별 포인트내역'} />
                </div>
                <div>
                    <CInputGroup className='mb-3 ' style={{ width: '250px' }}>
                        <CButton className='fw-bold text-white' color='info' style={{ width: '125px' }}>
                            포인트 총 획득
                        </CButton>
                        <CFormInput readOnly={true} value={totalGain} />
                    </CInputGroup>
                    <CInputGroup className='mb-3' style={{ width: '250px' }}>
                        <CButton className='fw-bold text-white' color='danger' style={{ width: '125px' }}>
                            포인트 총 소진
                        </CButton>
                        <CFormInput readOnly={true} value={totalUse} />
                    </CInputGroup>
                </div>
            </div>

            <CCard className='mb-4'>
                <CCardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='fs-3 fw-4 border-0'>
                            <span className={'me-4'}>포인트관리 - 날짜별 관리</span>
                            <input type={'date'} value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>

                        <div className='col-2 d-flex flex-column justify-content-end my-3'>
                            <PaginationFilter
                                color='secondary'
                                selected={pagingParam.size}
                                page={pagingParam.page}
                                onClick={setPagingParam}
                            />
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CTable striped hover>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col'>획득일</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>유저</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>획득</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>사용</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>만료</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>타입</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>비고</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>

                        <CTableBody>
                            {list.map((point) => (
                                <CTableRow key={point.id}>
                                    <CTableDataCell className='text-truncate'>
                                        {dayjs(point.createdAt).format('YY.MM.DD')}
                                    </CTableDataCell>
                                    <CTableDataCell className='text-truncate'>
                                        <Link to={`/point/user/${point.userEmail}`}>{point.userEmail}</Link>
                                    </CTableDataCell>
                                    <CTableDataCell className='text-truncate'>{point.gain}</CTableDataCell>
                                    <CTableDataCell className='text-truncate'>{point.use}</CTableDataCell>
                                    <CTableDataCell className='text-truncate'>
                                        {!point.expired ? '비만료' : '만료'}
                                    </CTableDataCell>
                                    <CTableDataCell className='text-truncate'>
                                        {pointTypeConverter(point.type)}
                                    </CTableDataCell>
                                    <CTableDataCell className='text-truncate'>
                                        {point.description}
                                    </CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                    <PaginationBar {...paginationDto} size={pagingParam.size} onClick={setPagingParam} />
                </CCardBody>
            </CCard>
        </>
    )
}

export default PointDate
