import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import AppContents from "./AppContents";

const DefaultLayout = () => {
    return (
        <div>
            <AppSidebar />
            <div className={'wrapper d-flex flex-column min-vh-100 gb-light'}>
                <AppHeader />
                <div className={'body flex-grow-1 px-3'}>
                    <AppContents />
                </div>
            </div>
        </div>
    )
}

export default DefaultLayout