import React, { useRef, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import PaginationBar from '../../components/PaginationBar'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import { dayjs } from '../../util/dayjs'
import FilterDropDown from '../../components/FilterDropDown'
import { reviewStateConverter, reviewStateFilter } from '../../util/converter/reviewStateConverter'
import { Link } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from '../../util/api'
import { IReviewListResponse } from '../../interface/IReviewResponse'
import { IPagination } from '../../interface/pagination/IPagination'

const Review = () => {
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [fetchingUrl, setFetchingUrl] = useState<string>('api/review/list?')

    const emailRef = useRef<HTMLInputElement>(null)
    const textRef = useRef<HTMLInputElement>(null)

    const { data } = useQuery(['reviewList', fetchingUrl, pagingParam.page, pagingParam.size], () => {
        return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const paginationDto: IPagination = data?.data.pagination
    const list: IReviewListResponse[] = data?.data.body

    const search = useMutation(
        'searchReview',
        (type: string) => {
            let body = {}
            if (type === 'email') body = { email: emailRef.current?.value }
            if (type === 'text') body = { content: textRef.current?.value }
            return axios.post('api/review/search', body)
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    ['reviewList', fetchingUrl, pagingParam.page, pagingParam.size],
                    (old: any) => {
                        return {
                            ...old,
                            data: {
                                ...old.data,
                                pagination: null,
                                body: data.data.body,
                            },
                        }
                    },
                )
            },
        },
    )

    return (
        <>
            <div>
                <div className='d-flex justify-content-between'>
                    <CInputGroup className='mb-3 ' style={{ width: '500px' }}>
                        <CInputGroupText id='basic-addon1' style={{ width: '100px' }}>
                            유저 email
                        </CInputGroupText>
                        <CFormInput
                            placeholder='유저 email로 검색'
                            aria-label='Username'
                            aria-describedby='basic-addon1'
                            ref={emailRef}
                        />
                        <CButton
                            className='fw-bold '
                            color='primary'
                            style={{ width: '55px' }}
                            onClick={() => search.mutate('email')}>
                            검색
                        </CButton>
                    </CInputGroup>
                    <CInputGroup className='mb-3' style={{ width: '500px' }}>
                        <CInputGroupText id='basic-addon1' style={{ width: '100px' }}>
                            후기 내용
                        </CInputGroupText>
                        <CFormInput
                            placeholder='후기 내용으로 검색'
                            aria-label='Username'
                            aria-describedby='basic-addon1'
                            ref={textRef}
                        />
                        <CButton
                            className='fw-bold'
                            color='primary'
                            style={{ width: '55px' }}
                            onClick={() => search.mutate('text')}>
                            검색
                        </CButton>
                    </CInputGroup>
                </div>
            </div>

            <CCard className='mb-4'>
                <CCardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <input
                            className='fs-3 fw-4 border-0'
                            value={'후기관리'}
                            readOnly={true}
                            disabled={true}
                        />
                        <div className='col-2 d-flex flex-column justify-content-end my-3'>
                            <PaginationFilter
                                color='secondary'
                                selected={pagingParam.size}
                                page={pagingParam.page}
                                onClick={setPagingParam}
                            />
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CTable striped hover style={{ tableLayout: 'fixed' }}>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    작성일자
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-3'>
                                    작성자
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-5'>
                                    본문
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    점수
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    <FilterDropDown
                                        name='상태'
                                        items={reviewStateFilter}
                                        requestUrl='api/review/list?isBlocked='
                                        onClick={setFetchingUrl}></FilterDropDown>
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>

                        {list.length === 0 ? (
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell colSpan={5}>등록된 리뷰가 없습니다.</CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        ) : (
                            <CTableBody>
                                {list.map((dto) => (
                                    <CTableRow key={dto.id} style={{ lineHeight: '30px' }}>
                                        <CTableDataCell>
                                            {dayjs(dto.createdAt).format('YY.MM.DD')}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div
                                                className='d-inline-block text-truncate'
                                                style={{ maxWidth: '100px' }}>
                                                <Link to={`/user/${dto.userId}`}>{dto.userEmail}</Link>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell className='text-truncate'>
                                            <Link to={`/review/${dto.id}`}>{dto.content}</Link>
                                        </CTableDataCell>
                                        <CTableDataCell>{dto.score}</CTableDataCell>
                                        <CTableDataCell>{reviewStateConverter(dto.isBlocked)}</CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        )}
                    </CTable>
                    <PaginationBar {...paginationDto} size={pagingParam.size} onClick={setPagingParam} />
                </CCardBody>
            </CCard>
        </>
    )
}

export default Review
