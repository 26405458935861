export const numberValidator = (value: string): boolean => {
    const reg = /^[0-9]*$/
    return reg.test(value)
}

export const emailValidator = (value: string): boolean => {
    const reg = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/
    return reg.test(value)
}

export const phoneNumberValidator = (value: string): boolean => {
    const reg = /^\d{3}-\d{3,4}-\d{4}$/
    return reg.test(value)
}
