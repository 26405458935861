import { useCallback, useRef, useState } from 'react'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'
import { CModal, CModalContent } from '@coreui/react'

interface ImageZoomProps {
    src: string
    className?: string
    // 이미지 높이 비율 (0-1)
    height?: number
}

const ImageZoom = ({ src, className, height }: ImageZoomProps) => {
    const imgRef = useRef(null)
    const [visible, setVisible] = useState(false)
    const imageHeight = `calc(var(--doc-height) * ${height})`

    // @ts-ignore
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef
        if (img) {
            const value = make3dTransformValue({ x, y, scale })
            // @ts-ignore
            img.style.setProperty('transform', value)
        }
    }, [])

    return (
        <>
            <div
                className={'d-flex justify-content-center w-100'}
                onClick={() => setVisible(true)}
                style={{ height: imageHeight }}>
                <img
                    className={className}
                    src={src}
                    alt={''}
                    style={{ objectFit: 'fill', width: '100%', height: imageHeight, cursor: 'pointer' }}
                />
            </div>
            <CModal alignment={'center'} visible={visible} onClose={() => setVisible(false)}>
                <CModalContent
                    className={'d-flex justify-content-center w-100'}
                    style={{ height: 'calc(var(--doc-height) * 0.7 )', overflow: 'auto' }}>
                    <QuickPinchZoom onUpdate={onUpdate}>
                        <img
                            className={'col-12 h-100'}
                            ref={imgRef}
                            src={src}
                            alt={''}
                            style={{ objectFit: 'scale-down', height: '100%' }}
                        />
                    </QuickPinchZoom>
                </CModalContent>
            </CModal>
        </>
    )
}

export default ImageZoom
