import {
    CButton,
    CCard,
    CCardBody,
    CCardImage,
    CCol,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { useState } from 'react'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import LabelAboveInput from '../../components/LabelAboveInput'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPartnerDto } from '../../interface/IPartnerResponse'
import { IFilesResponse } from '../../interface/IFilesResponse'
import { IVerificationDto } from '../../interface/IVerficationResponse'
import { IPartnersConstructionList } from '../../interface/IConstructionResponse'
import ImageZoom from '../../components/imgae/ImageZoom'
import CIcon from '@coreui/icons-react'
import { currencyFormatter } from '../../util/formatter'
import { cilUserX } from '@coreui/icons'
import LoadingButton from '../../components/button/LoadingButton'
import SendSMSModal from '../../components/modal/SendSMSModal'
import SendPushMessageModal from '../../components/modal/SendPushMessageModal'

const PartnerDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [portfolioVisible, setPortfolioVisible] = useState<boolean>(false)
    const [imageDeleteVisible, setImageDeleteVisible] = useState<boolean>(false)
    const [targetImageId, setTargetImageId] = useState<string>('')

    const [smsVisible, setSmsVisible] = useState<boolean>(false)
    const [fcmVisible, setFcmVisible] = useState<boolean>(false)

    const [withdrawVisible, setWithdrawVisible] = useState<boolean>(false)
    const [withdrawModalForm, setWithdrawModalForm] = useState<any>({})

    const { data } = useQuery(['partnerDetail', id], () => axios.get(`/api/partner/detail/${id}`))

    const deletePortfolio = useMutation(
        'deleteImage',
        (fileId: string) => {
            return axios.delete(`/api/partner/delete/${fileId}`)
        },
        {
            onSuccess: (data) => {
                if (data.data.success) {
                    alert('삭제하였습니다.')
                    queryClient.invalidateQueries(['partnerDetail', id])
                } else {
                    alert(data.data.error)
                }
            },
        },
    )

    const partner: IPartnerDto = data?.data.body.partnerInfo
    const profileUrl: string = data?.data.body.profileUrl
    const portfolioList: IFilesResponse[] = data?.data.body.portfolio
    const partnerUserId: string = data?.data.body.userId
    const partnerEmail: string = data?.data.body.email
    const verification: IVerificationDto = data?.data.body.verification
    const constructionList: IPartnersConstructionList[] = data?.data.body.constructionList
    const totalWithdrawAmount: string = data?.data.body.totalWithdrawAmount
    const reportCount: number = data?.data.body.reportCount

    return (
        <>
            <div className='container-fluid w-100'>
                <div className='d-flex flex-row w-100'>
                    {/*이미지 표시영역*/}
                    <div className='col-3 me-3 d-flex flex-column justify-content-center align-items-center'>
                        {profileUrl ? <ImageZoom src={profileUrl} /> : <CIcon icon={cilUserX} size='5xl' />}

                        <CButton
                            color='primary'
                            size={'sm'}
                            className='m-3'
                            onClick={() => {
                                setPortfolioVisible(true)
                            }}>
                            포트폴리오 보기
                        </CButton>
                    </div>
                    {/*포트폴리오 모달*/}
                    <CModal size='xl' alignment='center' visible={portfolioVisible}>
                        <div className='w-100 p-0 m-0'>
                            <CRow xl={{ cols: 2 }}>
                                {portfolioList.map((image) => (
                                    <CCol key={image.id} xl={{ order: 2 }}>
                                        <CCard className='m-2'>
                                            <CCardImage orientation='top' src={image.url} />
                                            <CCardBody className='d-flex flex-row-reverse'>
                                                <CButton
                                                    className=''
                                                    color='danger'
                                                    style={{ color: 'white' }}
                                                    onClick={() => {
                                                        setTargetImageId(image.id)
                                                        setImageDeleteVisible(true)
                                                    }}>
                                                    강제삭제
                                                </CButton>
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                ))}
                            </CRow>
                            <div className='d-flex flex-row-reverse'>
                                <CButton
                                    color='primary'
                                    size='lg'
                                    className='m-3'
                                    onClick={() => {
                                        setPortfolioVisible(false)
                                    }}>
                                    확인
                                </CButton>
                            </div>
                        </div>
                    </CModal>
                    {/*포트폴리오 삭제 확인 모달*/}
                    <CModal
                        size='lg'
                        alignment='center'
                        fullscreen='xxl'
                        backdrop='static'
                        visible={imageDeleteVisible}>
                        <CModalHeader closeButton={false}>
                            <CModalTitle className='fs-3'>포트폴리오 파일 삭제</CModalTitle>
                        </CModalHeader>
                        <CModalBody className='fs-4 d-flex justify-content-center'>
                            사용자 동의 없이 포트폴리오 목록에서 해당 파일을 삭제합니다.
                        </CModalBody>
                        <CModalFooter>
                            <CButton
                                color='dark'
                                size='lg'
                                className='m-3'
                                onClick={() => {
                                    setImageDeleteVisible(false)
                                }}>
                                취소
                            </CButton>
                            <LoadingButton
                                color='danger'
                                size='lg'
                                className='m-3 w-25'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    setImageDeleteVisible(false)
                                    deletePortfolio.mutate(targetImageId)
                                }}>
                                삭제
                            </LoadingButton>
                        </CModalFooter>
                    </CModal>

                    {/*form 표시영역*/}
                    <div className='col-9'>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='업체명'
                                col={4}
                                readonly={true}
                                value={partner.corpName}
                            />
                            <LabelAboveInput label='email' col={4} value={partnerEmail} readonly={true} />
                            <LabelAboveInput
                                label='연락처'
                                col={4}
                                value={partner.phoneNumber}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput label={'지역'} col={3} value={partner.region} />
                            <LabelAboveInput
                                label='계좌번호'
                                col={3}
                                readonly={true}
                                value={verification.accountNo}
                            />
                            <LabelAboveInput
                                label='사업자번호'
                                col={3}
                                value={verification.corpNo ?? `사업자번호가 없습니다`}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='면허번호'
                                col={3}
                                value={
                                    verification.hasCertification
                                        ? verification.certificationNo === 'requested'
                                            ? '인증요청중'
                                            : verification.certificationNo
                                        : '없음'
                                }
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label={'전문분야'}
                                col={3}
                                value={partner.specialization.join('/')}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='거래횟수'
                                col={3}
                                value={String(partner.countOfTransactions)}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='지급정산 누계액'
                                col={3}
                                value={currencyFormatter(totalWithdrawAmount)}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='리뷰평점'
                                col={3}
                                value={String(partner.averageScoreOfReviews)}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='누적신고횟수'
                                col={2}
                                value={String(reportCount)}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label={'세금계산서발행'}
                                col={2}
                                value={partner.taxbillAvailable ? '가능' : '불가'}
                                readonly={true}
                            />
                            <CButton
                                className='align-items-center col-2 my-2 mx-2'
                                variant='outline'
                                onClick={() => {
                                    setSmsVisible(true)
                                }}>
                                문자알림 보내기
                            </CButton>
                            <CButton
                                className='align-items-center col-2 my-2 mx-2'
                                variant='outline'
                                onClick={() => {
                                    setFcmVisible(true)
                                }}>
                                PUSH알림 보내기
                            </CButton>
                            <CButton
                                className='align-items-center col-3 my-2 mx-2'
                                variant='outline'
                                onClick={() => navigate(`/user/${partnerUserId}`)}>
                                유저정보 보기
                            </CButton>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <CButton
                        color='primary'
                        size={'lg'}
                        className='m-3'
                        onClick={() => {
                            navigate('/partner')
                        }}>
                        확인
                    </CButton>
                </div>

                <div className='text-center'>
                    <CTable striped>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    마지막활동
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    고객 email
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    공사 예정일
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    금액
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    지급정산액
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    정산가능일
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    상태
                                </CTableHeaderCell>
                                <CTableDataCell scope='col' className='col-1'>
                                    요청서
                                </CTableDataCell>
                            </CTableRow>
                        </CTableHead>

                        <CTableBody
                            style={{
                                lineHeight: '40px',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                            }}>
                            {constructionList.map((item) => (
                                <CTableRow key={item.id}>
                                    <CTableDataCell>
                                        {dayjs(item.updatedAt).format('YY.MM.DD')}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/user/${item.customerUserId}`}>{item.customerEmail}</Link>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {dayjs(item.constructionDate).format('YY.MM.DD')}
                                    </CTableDataCell>
                                    <CTableDataCell>{currencyFormatter(item.amount)}</CTableDataCell>
                                    <CTableDataCell
                                        style={{ textDecoration: 'underline', color: 'blue' }}
                                        onClick={() => {
                                            if (item.withdraw) {
                                                setWithdrawModalForm({
                                                    //은행, 계좌번호, 정산금액, 정산일, 결과
                                                    bankName: item.withdraw.rBankNm,
                                                    bankOwnerName: verification.accountOwner,
                                                    bankAccountNo: item.withdraw.rAccountNo,
                                                    amount: currencyFormatter(item.withdraw.rAmount),
                                                    withDay: dayjs(item.withdraw.rTranDate).format(
                                                        'YY.MM.DD',
                                                    ),
                                                    result: item.withdraw.resMsg,
                                                })
                                                setWithdrawVisible(true)
                                            } else {
                                                alert('아직 정산신청을 하지 않았습니다.')
                                            }
                                        }}>
                                        {currencyFormatter(
                                            item.withdraw
                                                ? item.withdraw.rAmount
                                                : String(Math.floor((Number(item.amount) * 95) / 100)),
                                        )}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {dayjs(item.canWithdrawAt).format('YY.MM.DD') !== 'Invalid Date'
                                            ? dayjs(item.canWithdrawAt).format('YY.MM.DD')
                                            : '결제 전'}
                                    </CTableDataCell>
                                    <CTableDataCell>{item.progress}</CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/request/${item.requestId}`}>보기</Link>
                                    </CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </div>
            </div>

            {/* SMS 발송 모달 */}
            <SendSMSModal visible={smsVisible} setVisible={setSmsVisible} phoneNumber={partner.phoneNumber} />
            {/* fcm PUSH 모달 */}
            <SendPushMessageModal visible={fcmVisible} setVisible={setFcmVisible} userId={partnerUserId} />
            {/* 정산 내역 상세 모달 */}
            <CModal visible={withdrawVisible}>
                <div className={'m-4'}>
                    <div className={`d-flex flex-row align-items-center m-3`}>
                        <CFormLabel className='w-25 px-2' style={{ fontSize: '0.9rem' }}>
                            은행
                        </CFormLabel>
                        <CFormInput
                            type='text'
                            readOnly={true}
                            value={withdrawModalForm.bankName}
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div className={`d-flex flex-row align-items-center m-3`}>
                        <CFormLabel className='w-25 px-2' style={{ fontSize: '0.9rem' }}>
                            계좌주
                        </CFormLabel>
                        <CFormInput
                            type='text'
                            readOnly={true}
                            value={withdrawModalForm.bankOwnerName}
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div className={`d-flex flex-row align-items-center m-3`}>
                        <CFormLabel className='w-25 px-2' style={{ fontSize: '0.9rem' }}>
                            계좌번호
                        </CFormLabel>
                        <CFormInput
                            type='text'
                            readOnly={true}
                            value={withdrawModalForm.bankAccountNo}
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div className={`d-flex flex-row align-items-center m-3`}>
                        <CFormLabel className='w-25 px-2' style={{ fontSize: '0.9rem' }}>
                            정산금액
                        </CFormLabel>
                        <CFormInput
                            type='text'
                            readOnly={true}
                            value={withdrawModalForm.amount}
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div className={`d-flex flex-row align-items-center m-3`}>
                        <CFormLabel className='w-25 px-2' style={{ fontSize: '0.9rem' }}>
                            정산일
                        </CFormLabel>
                        <CFormInput
                            type='text'
                            readOnly={true}
                            value={withdrawModalForm.withDay}
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div className={`d-flex flex-row align-items-center m-3`}>
                        <CFormLabel className='w-25 px-2' style={{ fontSize: '0.9rem' }}>
                            결과
                        </CFormLabel>
                        <CFormInput
                            type='text'
                            readOnly={true}
                            value={withdrawModalForm.result}
                            style={{ cursor: 'default' }}
                        />
                    </div>
                    <div className='d-flex justify-content-end'>
                        <CButton
                            color='primary'
                            className='m-3'
                            onClick={() => {
                                setWithdrawModalForm({})
                                setWithdrawVisible(false)
                            }}>
                            확인
                        </CButton>
                    </div>
                </div>
            </CModal>
        </>
    )
}

export default PartnerDetail
