import React, { SetStateAction, useEffect } from 'react'
import { useMutation } from 'react-query'
import axios from '../../util/api'
import {
    CButton,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
} from '@coreui/react'
import LoadingButton from '../button/LoadingButton'

interface ISendPushMessageModalProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
    target?: string
    userId: string
    receiverName?: string
}

type fcmForm = {
    title?: string
    content?: string
    target: string
    userId: string
}

const SendPushMessageModal = ({
    visible,
    setVisible,
    target = 'BOTH',
    userId,
    receiverName,
}: ISendPushMessageModalProps) => {
    const [fcmForm, setFcmForm] = React.useState<fcmForm>({ target: target, userId: '' })

    const sendPush = useMutation(
        'sendPush',
        () => {
            return axios.post(`/api/notification/send/push`, fcmForm)
        },
        {
            onSuccess: (data) => {
                if (data.data.success) {
                    alert('발송하였습니다.')
                } else {
                    alert(data.data.error)
                }
            },
        },
    )

    useEffect(() => {
        if (visible) {
            setFcmForm({
                ...fcmForm,
                userId: userId,
            })
        }
    }, [visible])

    return (
        <CModal
            visible={visible}
            onClose={() => {
                setVisible(false)
                setFcmForm({ target: 'BOTH', userId: '' })
            }}>
            <CModalHeader className={'fw-bold'}>
                {receiverName && receiverName}님에게 PUSH 알림 전송
            </CModalHeader>
            <CModalBody>
                <div className={`d-flex flex-column align-items-start m-2`}>
                    <CFormLabel className='w-100 px-2 fw-bold' style={{ fontSize: '0.9rem' }}>
                        제목
                        <CFormInput
                            type='text'
                            readOnly={false}
                            style={{ cursor: 'default' }}
                            onChange={(e) => setFcmForm({ ...fcmForm, title: e.target.value.trim() })}
                        />
                    </CFormLabel>
                </div>
                <div className={`d-flex flex-column align-items-start m-2`}>
                    <CFormLabel className='w-100 px-2 fw-bold' style={{ fontSize: '0.9rem' }}>
                        내용
                        <CFormTextarea
                            readOnly={false}
                            rows={5}
                            style={{ cursor: 'default', resize: 'none' }}
                            onChange={(e) => setFcmForm({ ...fcmForm, content: e.target.value.trim() })}
                        />
                    </CFormLabel>
                </div>
            </CModalBody>
            <CModalFooter>
                <div className='d-flex justify-content-end'>
                    <LoadingButton
                        color='primary'
                        className='me-2 fw-bold'
                        isLoading={sendPush.isLoading}
                        onClick={() => {
                            sendPush.mutate()
                            setVisible(false)
                        }}>
                        확인
                    </LoadingButton>
                    <CButton
                        color='danger'
                        className='fw-bold text-white'
                        onClick={() => {
                            setVisible(false)
                        }}>
                        취소
                    </CButton>
                </div>
            </CModalFooter>
        </CModal>
    )
}

export default SendPushMessageModal
