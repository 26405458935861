import React from 'react'
import { ImageFile } from '../interface/IFilesResponse'
import ImageSelector from '../components/imgae/ImageSelector'
import { CButton, CFormInput, CToast, CToastBody, CToastClose } from '@coreui/react'
import { useMutation } from 'react-query'
import axios from '../util/api'
import LoadingButton from '../components/button/LoadingButton'
import LabelAboveInput from '../components/LabelAboveInput'

const ImageUpdate = () => {
    const [image, setImage] = React.useState<ImageFile[]>([])
    const [urlList, setUrlList] = React.useState<string[]>([])
    const [toastVisible, setToastVisible] = React.useState<boolean>(false)
    const [errorToastVisible, setErrorToastVisible] = React.useState<boolean>(false)

    const upload = useMutation(
        'uploadImages',
        () => {
            const formData = new FormData()
            image.forEach((img) => {
                if (img.file instanceof File) formData.append('files', img.file)
            })
            return axios.post('/view/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        {
            onSuccess: (res) => {
                if (res.data.error) {
                    return alert('이미지 업로드에 실패했습니다.' + res.data.error)
                }
                setUrlList(res.data.data)
            },
            onError: (err) => {
                alert('이미지 업로드에 실패했습니다.' + err)
            },
        },
    )

    return (
        <div className={'container d-flex flex-column'}>
            <div>
                <h1>이미지 등록</h1>
                <br />
                <span>개발에 필요한 이미지를 등록하는 페이지입니다.</span>
            </div>
            <div className={'col-6 mt-5 border rounded p-3'}>
                <ImageSelector
                    warning={false}
                    maxLength={5}
                    description={'등록할 이미지를 선택해주세요.'}
                    imageList={image}
                    setImageList={setImage}
                />
            </div>
            <div className={'col-6 d-flex justify-content-between'}>
                <CButton
                    color={'secondary text-white'}
                    onClick={() => {
                        setImage([])
                        setUrlList([])
                    }}>
                    초기화
                </CButton>
                <div>
                    <LoadingButton
                        color={'primary me-2'}
                        isLoading={upload.isLoading}
                        onClick={() => upload.mutate()}>
                        등록
                    </LoadingButton>
                    <CButton color={'danger text-white'}>취소</CButton>
                </div>
            </div>
            <div className={`col-12 mt-5 ${urlList.length === 0 ? 'd-none' : 'd-block'}`}>
                <span className={'fw-bold'}>등록된 URL</span>
                <div className={'col-12 border rounded'}>
                    {urlList.map((url, index) => (
                        <LabelAboveInput
                            key={index}
                            label={String(index)}
                            col={12}
                            value={
                                <div className={'d-flex'}>
                                    <CFormInput type={'text'} value={url} />
                                    <CButton
                                        color={'primary'}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.preventDefault()
                                            const targetUrl = urlList[index]
                                            navigator.clipboard
                                                .writeText(targetUrl)
                                                .then(() => setToastVisible(true))
                                                .catch((err) => setErrorToastVisible(true))
                                        }}>
                                        복사
                                    </CButton>
                                </div>
                            }
                        />
                    ))}
                </div>
            </div>
            <CToast autohide={true} visible={toastVisible} delay={1000}>
                <div className={'d-flex'}>
                    <CToastBody>클립보드에 복사되었습니다.</CToastBody>
                    <CToastClose className={'me-2 m-auto'}></CToastClose>
                </div>
            </CToast>
            <CToast autohide={true} visible={errorToastVisible} delay={1000}>
                <div className={'d-flex'}>
                    <CToastBody>복사하지 못했습니다.</CToastBody>
                    <CToastClose className={'me-2 m-auto'}></CToastClose>
                </div>
            </CToast>
        </div>
    )
}

export default ImageUpdate
