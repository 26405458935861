import {
    CButton,
    CButtonGroup,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import FilterDropDown from '../components/FilterDropDown'
import Loading from '../components/Loading'
import PaginationBar from '../components/PaginationBar'
import PaginationFilter from '../components/PaginationFilter'
import { IPagination, paginationInitValue } from '../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../interface/pagination/IPagingParam'
import axios from '../util/api'
import { dayjs } from '../util/dayjs'

const Ask = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [pagination, setPagination] = useState<IPagination>(paginationInitValue)
    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [data, setData] = useState<any[]>([])
    const [fetchingUrl, setFetchingUrl] = useState<string>(`/api/ask/all?`)
    const [visible, setVisible] = useState<boolean>(false)
    const [question, setQuestion] = useState<any>({})

    const contentRef = useRef<HTMLInputElement>(null)
    const idRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)

    const fetch = async () => {
        const url = `${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`

        return await axios.get(url).then((res) => {
            if (res.data.error) alert(res.data.error)
            setData(res.data.inquiriesDtoList)
            setPagination(res.data.pagenation)
            setLoading(false)
        })
    }

    const handleSendButton = async () => {
        const form: HTMLFormElement = document.forms[1]

        const data = {
            //@ts-ignore
            id: form.id.value,
            answer: form.answer.value,
            //@ts-ignore
            state: form.state.value,
        }
        await axios.put('/api/ask/answer', data).then((res) => {
            alert('전송되었습니다.')
            setQuestion({})
            location.reload()
        })
    }

    useEffect(() => {
        fetch()
    }, [fetchingUrl, pagingParam])

    return loading ? (
        <Loading />
    ) : (
        <div className='container d-flex flex-column'>
            <div>
                <h1>1:1문의 관리</h1>
            </div>

            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={contentRef} type='text' placeholder='내용으로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(
                                            `/api/ask/question?question=${contentRef.current?.value}&`,
                                        )
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={idRef} type='text' placeholder='작성자id로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(
                                            `/api/ask/author?authorId=${idRef.current?.value.trim()}&`,
                                        )
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-1'></CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                작성일자
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                작성자 id
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                email
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-3'>
                                문의 내용
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                <FilterDropDown
                                    name='상태'
                                    items={[
                                        { name: '접수대기', value: 'PENDING' },
                                        { name: '처리중', value: 'IN_PROGRESS' },
                                        { name: '처리대기', value: 'HOLD' },
                                        { name: '처리완료', value: 'DONE' },
                                    ]}
                                    requestUrl='/api/ask/state?inquiriesState='
                                    onClick={setFetchingUrl}
                                />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                처리일자
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {data.map((item, index) => (
                            <CTableRow key={index}>
                                <CTableDataCell>
                                    <CFormCheck id={`checkbox${index}`} value={item.id} />
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div className='d-inline-block text-truncate'>
                                        {dayjs(item.createdAt).format('YY.MM.DD')}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '100px' }}>
                                        <Link to={`/user/${item.authorId.id}`}>{item.authorId.id}</Link>
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div className='d-inline-block text-truncate'>{item.authorId.email}</div>
                                </CTableDataCell>
                                <CTableDataCell
                                    onClick={() => {
                                        setQuestion(item)
                                        setVisible(true)
                                    }}>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '300px' }}>
                                        {item.question}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '300px' }}>
                                        {item.state}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '300px' }}>
                                        {item.answer === null
                                            ? '-'
                                            : dayjs(item.updatedAt).format('YY.MM.DD')}
                                    </div>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>

            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
            {/*작성 모달*/}
            {visible && (
                <CModal
                    alignment='center'
                    visible={visible}
                    onClose={() => {
                        setVisible(false)
                        setQuestion({})
                    }}>
                    <CModalHeader>
                        <CModalTitle>1:1 문의</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <div className='d-flex flex-column'>
                            <CForm>
                                <div>
                                    <CFormLabel htmlFor='author'>작성자 id</CFormLabel>
                                    <Link to={`/user/${question.authorId.id}`}>
                                        <CFormInput
                                            type='text'
                                            id='author'
                                            name='authorId'
                                            value={question.authorId.id}
                                        />
                                    </Link>
                                    <CFormInput type='hidden' name='id' value={question.id} />
                                </div>
                                <div>
                                    <CFormLabel htmlFor='question'>질문내용</CFormLabel>
                                    <CFormTextarea
                                        id='question'
                                        name='question'
                                        rows={5}
                                        style={{ resize: 'none' }}
                                        value={question.question}
                                    />
                                </div>
                                <div>
                                    <CFormLabel htmlFor='answer'>답변작성</CFormLabel>
                                    <CFormTextarea
                                        id='answer'
                                        name='answer'
                                        rows={5}
                                        style={{ resize: 'none' }}
                                    />
                                </div>
                                <div className='d-flex flex-column align-items-start mt-2'>
                                    상태
                                    <CButtonGroup
                                        role='group'
                                        aria-label='Basic target radio toggle button group'>
                                        <CFormCheck
                                            type='radio'
                                            button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                            name='state'
                                            id='PENDING'
                                            value='PENDING'
                                            defaultChecked={question.state === 'PENDING'}
                                            autoComplete='off'
                                            label='접수대기'
                                        />
                                        <CFormCheck
                                            type='radio'
                                            button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                            name='state'
                                            id='IN_PROGRESS'
                                            value='IN_PROGRESS'
                                            defaultChecked={question.state === 'IN_PROGRESS'}
                                            autoComplete='off'
                                            label='처리중'
                                        />
                                        <CFormCheck
                                            type='radio'
                                            button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                            name='state'
                                            id='HOLD'
                                            value='HOLD'
                                            defaultChecked={question.state === 'HOLD'}
                                            autoComplete='off'
                                            label='처리대기'
                                        />
                                        <CFormCheck
                                            type='radio'
                                            button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                            name='state'
                                            id='DONE'
                                            value='DONE'
                                            defaultChecked={question.state === 'DONE'}
                                            autoComplete='off'
                                            label='처리완료'
                                        />
                                    </CButtonGroup>
                                </div>
                            </CForm>
                        </div>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color='secondary'
                            onClick={() => {
                                setVisible(false)
                                setQuestion({})
                            }}>
                            취소
                        </CButton>
                        <CButton
                            color='primary'
                            onClick={() => {
                                handleSendButton()
                            }}>
                            답변하기
                        </CButton>
                    </CModalFooter>
                </CModal>
            )}
        </div>
    )
}

export default Ask
