import {
    cilBarChart,
    cilBell,
    cilCash,
    cilCreditCard,
    cilDescription,
    cilDollar,
    cilFullscreenExit,
    cilHouse,
    cilImagePlus,
    cilInfo,
    cilListRich,
    cilMoodBad,
    cilNotes,
    cilSpeak,
    cilStar,
    cilTask,
    cilTruck,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons/js/free/cil-user'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
    {
        component: CNavItem,
        name: 'Main',
        to: '/main',
        icon: <CIcon icon={cilHouse} customClassName='nav-icon' />,
    },
    // {
    //   component: CNavTitle,
    //   name: '',
    // },
    {
        component: CNavGroup,
        name: '통계목록',
        icon: <CIcon icon={cilBarChart} customClassName='nav-icon' />,
        items: [
            {
                component: CNavItem,
                name: '전체통계',
                to: '/dashboard/total',
            },
            {
                component: CNavItem,
                name: '유저통계',
                to: '/dashboard/user',
            },
            {
                component: CNavItem,
                name: '요청서통계',
                to: '/dashboard/request',
            },
            {
                component: CNavItem,
                name: '지역별 통계',
                to: '/dashboard/requestregion',
            },
            {
                component: CNavItem,
                name: '공사별 통계',
                to: '/dashboard/requestclassification',
            },
        ],
    },
    {
        component: CNavItem,
        name: '고객목록',
        to: '/user',
        icon: <CIcon icon={cilUser} customClassName='nav-icon' />,
        // items: [
        //     {
        //         component: CNavItem,
        //         name: 'Accordion',
        //         to: '/base/accordion',
        //     },
        //     {
        //         component: CNavItem,
        //         name: 'Breadcrumb',
        //         to: '/base/breadcrumbs',
        //     },
        // ],
    },
    {
        component: CNavItem,
        name: '대장목록',
        to: '/partner',
        icon: <CIcon icon={cilTruck} customClassName='nav-icon' />,
    },
    {
        component: CNavItem,
        name: '요청서',
        icon: <CIcon icon={cilNotes} customClassName='nav-icon' />,
        to: '/request',
    },
    {
        component: CNavItem,
        name: '비회원요청서',
        icon: <CIcon icon={cilNotes} customClassName='nav-icon' />,
        to: '/noname',
    },
    {
        component: CNavItem,
        name: '매칭된 거래 관리',
        to: '/construction',
        icon: <CIcon icon={cilFullscreenExit} customClassName='nav-icon' />,
    },
    {
        component: CNavGroup,
        name: '결제관리',
        icon: <CIcon icon={cilCreditCard} customClassName='nav-icon' />,
        items: [
            {
                component: CNavItem,
                name: '결제내역',
                to: '/payment',
            },
            {
                component: CNavItem,
                name: '분할결제관리',
                to: '/payment/split',
            },
        ],
    },
    {
        component: CNavGroup,
        name: '공지사항',
        to: '/notice',
        icon: <CIcon icon={cilInfo} customClassName='nav-icon' />,
        items: [
            {
                component: CNavItem,
                name: '공지관리',
                to: '/notice',
            },
            {
                component: CNavItem,
                name: '배너관리',
                to: '/notice/banner',
            },
            {
                component: CNavItem,
                name: '팝업관리',
                to: '/notice/popup',
            },
        ],
    },
    {
        component: CNavItem,
        name: '약관/개인정보',
        to: '/service/terms',
        icon: <CIcon icon={cilListRich} customClassName='nav-icon' />,
    },
    {
        component: CNavItem,
        name: '알림관리',
        to: '/alarm',
        icon: <CIcon icon={cilBell} customClassName='nav-icon' />,
    },
    {
        component: CNavItem,
        name: '신고관리',
        to: '/report',
        icon: <CIcon icon={cilMoodBad} customClassName='nav-icon' />,
    },
    {
        component: CNavItem,
        name: '1:1문의관리',
        to: '/inquiry',
        icon: <CIcon icon={cilSpeak} customClassName='nav-icon' />,
    },
    {
        component: CNavItem,
        name: '후기관리',
        to: '/review',
        icon: <CIcon icon={cilStar} customClassName='nav-icon' />,
    },
    {
        component: CNavItem,
        name: '건설업인증',
        to: '/verification',
        icon: <CIcon icon={cilTask} customClassName='nav-icon' />,
    },
    {
        component: CNavGroup,
        name: '포인트관리',
        icon: <CIcon icon={cilDollar} customClassName='nav-icon' />,
        items: [
            {
                component: CNavItem,
                name: '날짜별 관리',
                to: '/point/date',
            },
            {
                component: CNavItem,
                name: '회원별 관리',
                to: '/point/user/default',
            },
        ],
    },
    {
        component: CNavItem,
        name: '쿠폰관리',
        icon: <CIcon icon={cilCash} customClassName='nav-icon' />,
        to: '/coupon',
    },
    {
        component: CNavItem,
        name: '이미지등록',
        icon: <CIcon icon={cilImagePlus} customClassName='nav-icon' />,
        to: '/image',
    },
    {
        component: CNavTitle,
        name: 'Extras',
    },
    {
        component: CNavGroup,
        name: 'Pages',
        icon: <CIcon icon={cilStar} customClassName='nav-icon' />,
        items: [
            {
                component: CNavItem,
                name: 'Login',
                to: '/login',
            },
            {
                component: CNavItem,
                name: 'Register',
                to: '/register',
            },
            {
                component: CNavItem,
                name: 'Error 404',
                to: '/404',
            },
            {
                component: CNavItem,
                name: 'Error 500',
                to: '/500',
            },
        ],
    },
    {
        component: CNavItem,
        name: 'Docs',
        href: 'https://coreui.io/react/docs/templates/installation/',
        icon: <CIcon icon={cilDescription} customClassName='nav-icon' />,
    },
]

export default _nav
