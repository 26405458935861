import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import React, { useState } from 'react'

interface paginationFilterProps {
    color?: string
    selected: string
    page: string
    onClick: Function
}

const paginationFilter = ({ color = 'primary', selected, page, onClick }: paginationFilterProps) => {
    const [size, setSize] = useState<string>(selected)

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setSize(e.currentTarget.name)
        onClick({ page: page, size: e.currentTarget.name })
    }

    return (
        <>
            <CDropdown variant='btn-group' className='w-100'>
                <CDropdownToggle color={color} id='size' value={size}>
                    {size}개씩 보기
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem href='#' name='10' style={{ width: '136px' }} onClick={onClickHandler}>
                        10개씩 보기
                    </CDropdownItem>
                    <CDropdownItem href='#' name='30' style={{ width: '136px' }} onClick={onClickHandler}>
                        30개씩 보기
                    </CDropdownItem>
                    <CDropdownItem href='#' name='50' style={{ width: '136px' }} onClick={onClickHandler}>
                        50개씩 보기
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </>
    )
}

export default React.memo(paginationFilter)
