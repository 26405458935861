import { useQuery } from 'react-query'
import axios from '../../util/api'
import { MonthlyTotalUser, TotalData } from '../../interface/IDashboardDataResponse'
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilChartPie, cilMinus } from '@coreui/icons'
import { CChart, CChartLine } from '@coreui/react-chartjs'
import { CWidgetStatsA, CWidgetStatsE, CWidgetStatsF } from '@coreui/react'
import { getStyle } from 'chart.js/helpers'

const TotalDataDashboard = () => {
    const root = document.getElementById('root') as HTMLElement

    const { data } = useQuery('totalData', () => {
        return axios.get('/api/dashboard/total')
    })

    const totalData: TotalData = data?.data.body

    const monthlyUserData: MonthlyTotalUser[] = totalData?.totalUser.monthlyTotalUser.sort((a, b) => {
        if (a === undefined || b === undefined) return 0
        let aMonth = a.month
        let bMonth = b.month
        if (String(a.month).length < 6) {
            aMonth = Number(String(a.month).substring(0, 4) + '0' + String(a.month).substring(4))
        }
        if (String(b.month).length < 6) {
            bMonth = Number(String(b.month).substring(0, 4) + '0' + String(b.month).substring(4))
        }

        return (aMonth % 10000) - (bMonth % 10000)
    })

    const sumTotal: number[] = []
    const sumCustomer: number[] = []
    const sumProvider: number[] = []

    for (let i = 0; i < monthlyUserData.length; i++) {
        if (i === 0) {
            sumTotal.push(monthlyUserData[i].totalUserCount)
            sumCustomer.push(monthlyUserData[i].totalUserCount - monthlyUserData[i].totalProviderCount)
            sumProvider.push(monthlyUserData[i].totalProviderCount)
        } else {
            const prevTotal = sumTotal[i - 1]
            const prevCustomer = sumCustomer[i - 1]
            const prevProvider = sumProvider[i - 1]
            sumTotal.push(
                prevTotal + monthlyUserData[i].totalUserCount + monthlyUserData[i].totalProviderCount,
            )
            sumCustomer.push(
                prevCustomer + monthlyUserData[i].totalUserCount - monthlyUserData[i].totalProviderCount,
            )
            sumProvider.push(prevProvider + monthlyUserData[i].totalProviderCount)
        }
    }

    return (
        <div className={'container d-flex flex-column'}>
            <div className={'mt-5'}>
                <h3>누적 회원수 {Intl.NumberFormat().format(totalData.totalUser.totalUserCount)} 명</h3>
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-center gap-3 py-3'}>
                <CWidgetStatsA
                    className='col-5'
                    color='info'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>고객 회원</div>
                            <div className={'ms-3'}>
                                {Intl.NumberFormat().format(
                                    totalData.totalUser.totalUserCount -
                                        totalData.totalUser.totalProviderCount,
                                )}{' '}
                                명
                            </div>
                        </div>
                    }
                    title={
                        <span className='fs-20 fw-600 ms-2'>
                            월간 증감률 (
                            {Math.abs(
                                100 -
                                    (isNaN(
                                        (monthlyUserData[monthlyUserData.length - 1].totalUserCount -
                                            monthlyUserData[monthlyUserData.length - 1].totalProviderCount) /
                                            (monthlyUserData[monthlyUserData.length - 2].totalUserCount -
                                                monthlyUserData[monthlyUserData.length - 2]
                                                    .totalProviderCount),
                                    )
                                        ? 1
                                        : (monthlyUserData[monthlyUserData.length - 1].totalUserCount -
                                              monthlyUserData[monthlyUserData.length - 1]
                                                  .totalProviderCount) /
                                          (monthlyUserData[monthlyUserData.length - 2].totalUserCount -
                                              monthlyUserData[monthlyUserData.length - 2]
                                                  .totalProviderCount)) *
                                        100,
                            ).toFixed(1)}
                            %{' '}
                            <CIcon
                                icon={
                                    monthlyUserData[monthlyUserData.length - 2].totalUserCount -
                                        monthlyUserData[monthlyUserData.length - 2].totalProviderCount -
                                        (monthlyUserData[monthlyUserData.length - 1].totalUserCount -
                                            monthlyUserData[monthlyUserData.length - 1]
                                                .totalProviderCount) ===
                                    0
                                        ? cilMinus
                                        : monthlyUserData[monthlyUserData.length - 2].totalUserCount -
                                              monthlyUserData[monthlyUserData.length - 2].totalProviderCount -
                                              (monthlyUserData[monthlyUserData.length - 1].totalUserCount -
                                                  monthlyUserData[monthlyUserData.length - 1]
                                                      .totalProviderCount) <
                                          0
                                        ? cilArrowTop
                                        : cilArrowBottom
                                }
                            />
                            )
                        </span>
                    }
                    chart={
                        <CChartLine
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: monthlyUserData.map(
                                    (data) =>
                                        String(
                                            data.month > 100000 ? data.month % 10000 : data.month % 1000,
                                        ).substring(0, 2) +
                                        '년 ' +
                                        String(
                                            data.month > 100000 ? data.month % 10000 : data.month % 1000,
                                        ).substring(2) +
                                        '월',
                                ),
                                datasets: [
                                    {
                                        label: '월간 신규 고객 회원',
                                        backgroundColor: 'transparent',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        pointBackgroundColor: '#39f',
                                        data: monthlyUserData.map(
                                            (data) => data.totalUserCount - data.totalProviderCount,
                                        ),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            monthlyUserData.reduce((prev, data) =>
                                                prev.totalUserCount > data.totalUserCount ? prev : data,
                                            ).totalUserCount + 10,
                                        max:
                                            monthlyUserData.reduce((prev, data) =>
                                                prev.totalUserCount < data.totalUserCount ? prev : data,
                                            ).totalUserCount - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsA
                    className='col-5'
                    color='success'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>대장 회원</div>
                            <div className={'ms-3'}>
                                {Intl.NumberFormat().format(totalData.totalUser.totalProviderCount)} 명
                            </div>
                        </div>
                    }
                    title={
                        <span className='fs-20 fw-600 ms-2'>
                            (
                            {Math.abs(
                                100 -
                                    (isNaN(
                                        monthlyUserData[monthlyUserData.length - 1].totalProviderCount /
                                            monthlyUserData[monthlyUserData.length - 2].totalProviderCount,
                                    )
                                        ? 1
                                        : monthlyUserData[monthlyUserData.length - 1].totalProviderCount /
                                          monthlyUserData[monthlyUserData.length - 2].totalProviderCount) *
                                        100,
                            ).toFixed(1)}
                            %{' '}
                            <CIcon
                                icon={
                                    monthlyUserData[monthlyUserData.length - 2].totalProviderCount -
                                        monthlyUserData[monthlyUserData.length - 1].totalProviderCount ===
                                    0
                                        ? cilMinus
                                        : monthlyUserData[monthlyUserData.length - 2].totalProviderCount -
                                              monthlyUserData[monthlyUserData.length - 1].totalProviderCount <
                                          0
                                        ? cilArrowTop
                                        : cilArrowBottom
                                }
                            />
                            )
                        </span>
                    }
                    chart={
                        <CChartLine
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: monthlyUserData.map(
                                    (data) =>
                                        String(
                                            data.month > 100000 ? data.month % 10000 : data.month % 1000,
                                        ).substring(0, 2) +
                                        '년 ' +
                                        String(
                                            data.month > 100000 ? data.month % 10000 : data.month % 1000,
                                        ).substring(2) +
                                        '월',
                                ),
                                datasets: [
                                    {
                                        label: '월간 신규 대장 회원',
                                        backgroundColor: 'transparent',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        pointBackgroundColor: '#2eb85c',
                                        data: monthlyUserData.map((data) => data.totalProviderCount),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            monthlyUserData.reduce((prev, data) =>
                                                prev.totalProviderCount > data.totalProviderCount
                                                    ? prev
                                                    : data,
                                            ).totalProviderCount + 10,
                                        max:
                                            monthlyUserData.reduce((prev, data) =>
                                                prev.totalProviderCount < data.totalProviderCount
                                                    ? prev
                                                    : data,
                                            ).totalProviderCount - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CChart
                    className={'col-11 mt-4'}
                    type='line'
                    data={{
                        labels: monthlyUserData.map(
                            (data) =>
                                String(
                                    data.month > 100000 ? data.month % 10000 : data.month % 1000,
                                ).substring(0, 2) +
                                '년 ' +
                                String(
                                    data.month > 100000 ? data.month % 10000 : data.month % 1000,
                                ).substring(2) +
                                '월',
                        ),
                        datasets: [
                            {
                                label: '누적 고객 회원 수',
                                backgroundColor: '#0d6efd',
                                borderColor: '#0d6efd',
                                pointBackgroundColor: '#0d6efd',
                                pointBorderColor: '#fff',
                                data: sumCustomer,
                            },
                            {
                                label: '누적 대장 회원 수',
                                backgroundColor: '#198754',
                                borderColor: '#198754',
                                pointBackgroundColor: '#198754',
                                pointBorderColor: '#fff',
                                data: sumProvider,
                            },
                            {
                                label: '총 누적 회원 수',
                                backgroundColor: '#4f5d73',
                                borderColor: '#4f5d73',
                                pointBackgroundColor: '#4f5d73',
                                pointBorderColor: '#fff',
                                data: sumTotal,
                            },
                        ],
                    }}
                    options={{
                        plugins: {
                            legend: {
                                labels: {
                                    color: getStyle(root, '--cui-body-color'),
                                },
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: getStyle(root, '--cui-border-color-translucent'),
                                },
                                ticks: {
                                    color: getStyle(root, '--cui-body-color'),
                                },
                            },
                            y: {
                                grid: {
                                    color: getStyle(root, '--cui-border-color-translucent'),
                                },
                                ticks: {
                                    color: getStyle(root, '--cui-body-color'),
                                },
                            },
                        },
                    }}
                />
            </div>
            <div className={'mt-5'}>
                <h3>요청서 통계 (전체 기간)</h3>
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-center gap-3 py-3'}>
                {/*전체요청서 수*/}
                <CWidgetStatsE
                    className='mb-3 col-4'
                    title={<span className={'fw-500 fs-24'}>회원 요청서</span>}
                    value={<span className={'fs-20'}>{totalData.totalRequest.totalRequest + ' 건'}</span>}
                />
                <CWidgetStatsE
                    className='mb-3 col-4'
                    title={<span className={'fw-500 fs-24'}>비회원 요청서</span>}
                    value={
                        <span className={'fs-20'}>{totalData.totalRequest.totalNonameRequest + ' 건'}</span>
                    }
                />
                <CWidgetStatsE
                    className='mb-3 col-4'
                    title={<span className={'fw-500 fs-24'}>일반 요청서</span>}
                    value={
                        <span className={'fs-20'}>{totalData.totalRequest.totalNormalRequest + ' 건'}</span>
                    }
                />
                <CWidgetStatsE
                    className='mb-3 col-4'
                    title={<span className={'fw-500 fs-24'}>시공 요청서</span>}
                    value={
                        <span className={'fs-20'}>{totalData.totalRequest.totalSimpleRequest + ' 건'}</span>
                    }
                />
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-center gap-3 py-3'}>
                {/*<CWidgetStatsB*/}
                {/*    className='mb-3 col-5 fs-20'*/}
                {/*    progress={{*/}
                {/*        color: 'success',*/}
                {/*        value: totalData.totalRequest.ratioOfNonameToRequest * 100,*/}
                {/*    }}*/}
                {/*    text={`비회원요청서 정식요청서 전환 비율 ${(*/}
                {/*        totalData.totalRequest.ratioOfNonameToRequest * 100*/}
                {/*    ).toFixed(1)}%`}*/}
                {/*    title='비회원요청서 정식요청서 변환'*/}
                {/*    value={`${totalData.totalRequest.totalCountOfChanged}*/}
                {/*    건`}*/}
                {/*/>*/}
                {/*<CWidgetStatsB*/}
                {/*    className='mb-3 col-5 fs-20'*/}
                {/*    progress={{*/}
                {/*        color: 'success',*/}
                {/*        value: totalData.totalRequest.ratioOfNonameWithEstimateToRequest * 100,*/}
                {/*    }}*/}
                {/*    text={`견적을 받은 비회원요청서 전환 비율 ${(*/}
                {/*        totalData.totalRequest.ratioOfNonameWithEstimateToRequest * 100*/}
                {/*    ).toFixed(1)} %`}*/}
                {/*    title='견적을 받은 비회원요청서 변환'*/}
                {/*    value={`${totalData.totalRequest.totalCountOfNonameWithEstimatedChanged}*/}
                {/*     건`}*/}
                {/*/>*/}
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받은 요청서의 수'
                    value={`${totalData.totalRequest.totalCountOfEstimated} 건`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받는 비율'
                    value={`${(
                        (totalData.totalRequest.totalCountOfEstimated / totalData.totalRequest.totalRequest) *
                        100
                    ).toFixed(1)} %`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받은 요청서당 평균 수신 견적 수'
                    value={`${totalData.totalRequest.averageCountOfEstimated.toFixed(1)} 건`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받은 요청서가 거래확정 되는 비율'
                    value={`${(totalData.totalRequest.ratioOfChangeToConstruction * 100).toFixed(1)} %`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='가견적 이후 재견적을 받는 비율'
                    value={`${(totalData.totalRequest.ratioOfReEstimated * 100).toFixed(1)} %`}
                />
                <div className={'col-5'}></div>
            </div>
        </div>
    )
}

export default TotalDataDashboard
