import { CTooltip } from '@coreui/react'

const DetailRequestBadge = () => {
    return (
        <CTooltip content={'인테리어/리모델링 요청입니다.'}>
            <div
                className={'btn btn-sm btn-primary text-white fw-bold mx-1 py-0'}
                role={'textbox'}
                aria-label={'인테리어/리모델링 요청입니다.'}
                style={{ cursor: 'default' }}>
                일반
            </div>
        </CTooltip>
    )
}

export default DetailRequestBadge
