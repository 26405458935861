import {
    CButton,
    CFormTextarea,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import LabelAboveInput from '../../components/LabelAboveInput'
import ImageSlide from '../../components/imgae/ImageSlide'
import { IFilesResponse } from '../../interface/IFilesResponse'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'
import { IUserListResponse } from '../../interface/IUserResponse'
import { IRequestResponse } from '../../interface/IRequestResponse'
import { IEstimatesWithPartnerResponse } from '../../interface/IEstimateResponse'
import { currencyFormatter } from '../../util/formatter'
import React from 'react'
import requestTypeDecider from '../../util/requestTypeDecider'
import DetailRequestBadge from '../../components/badge/DetailRequestBadge'
import SimpleRequestBadge from '../../components/badge/SimpleRequestBadge'

const RequestDetail = () => {
    const { requestId } = useParams()
    const navigate = useNavigate()

    const { data } = useQuery(['getRequestDetail', requestId], () => {
        return axios.get(`/api/request/detail/${requestId}`)
    })

    const detail: {
        author: IUserListResponse
        request: IRequestResponse
        images: IFilesResponse[]
        estimates: IEstimatesWithPartnerResponse[]
    } = data?.data.body
    const images: IFilesResponse[] = detail.images
    const estimates = detail.estimates

    return (
        <>
            <div className='container-fluid w-100'>
                <div className={'d-flex flex-row'}>
                    <h1 className={'me-3'}>요청 상세정보</h1>
                    {requestTypeDecider(detail.request) === 'detail' ? (
                        <div className={'d-flex flex-column justify-content-end pb-3'}>
                            <DetailRequestBadge />
                        </div>
                    ) : (
                        <div className={'d-flex flex-column justify-content-end pb-3'}>
                            <SimpleRequestBadge />
                        </div>
                    )}
                </div>
                <div className='d-flex flex-row w-100'>
                    {/*이미지 표시영역*/}
                    <div className='col-3 me-3 d-flex align-items-center'>
                        <ImageSlide
                            images={images.map((img) => ({ id: img.id, image: img.url }))}
                            height={0.2}
                        />
                    </div>

                    {/*form 표시영역*/}
                    <div className='col-9'>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='고객명'
                                col={4}
                                value={detail.author.name}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='email'
                                col={4}
                                readonly={true}
                                value={detail.author.email}
                            />
                            <LabelAboveInput
                                label='연락처'
                                col={4}
                                value={detail.author.phoneNumber}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='요청서 id'
                                col={4}
                                readonly={true}
                                value={detail.request.id}
                            />
                            <LabelAboveInput
                                label='생성일'
                                col={4}
                                value={dayjs(detail.request.createdAt).format('YYYY년 MM월 DD일')}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='공사요청일'
                                col={4}
                                value={
                                    detail.request.requestDate === null
                                        ? '미지정'
                                        : dayjs(detail.request.requestDate).format('YYYY년 MM월 DD일')
                                }
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='주소'
                                col={5}
                                value={detail.request.address.fullAddress}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='도로명주소'
                                col={5}
                                value={detail.request.address.roadAddress}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='상태'
                                col={2}
                                value={detail.request.isOpened ? '요청공개' : '거래진행중'}
                                readonly={true}
                            />
                        </div>
                        {detail.request.buildingType === 'simpleRequest' ? (
                            <div className='col-12 d-flex'>
                                <LabelAboveInput
                                    label='공사 종류'
                                    col={4}
                                    value={detail.request.classifications.join('/')}
                                    readonly={true}
                                />
                            </div>
                        ) : (
                            <>
                                <div className='col-12 d-flex'>
                                    <LabelAboveInput
                                        label='건물 종류'
                                        col={3}
                                        value={detail.request.buildingType}
                                        readonly={true}
                                    />
                                    <LabelAboveInput
                                        label='공사 종류'
                                        col={4}
                                        value={detail.request.classifications.join('/')}
                                        readonly={true}
                                    />
                                    <LabelAboveInput
                                        label='면적'
                                        col={2}
                                        value={`${detail.request.acreage}평`}
                                        readonly={true}
                                    />
                                    <LabelAboveInput
                                        label='층'
                                        col={2}
                                        value={`${detail.request.address.floor}층`}
                                        readonly={true}
                                    />
                                </div>
                                <div className='col-12 d-flex'>
                                    <LabelAboveInput
                                        label='거주여부'
                                        col={3}
                                        value={
                                            detail.request.isLivingNow !== null
                                                ? detail.request.isLivingNow
                                                    ? '거주'
                                                    : '비거주'
                                                : '선택안함'
                                        }
                                        readonly={true}
                                    />
                                    <LabelAboveInput
                                        label='철거여부'
                                        col={3}
                                        value={
                                            detail.request.hasDeconstructed !== null
                                                ? detail.request.hasDeconstructed
                                                    ? '철거'
                                                    : '미철거'
                                                : '선택안함'
                                        }
                                        readonly={true}
                                    />
                                    <LabelAboveInput
                                        label='엘리베이터'
                                        col={3}
                                        value={
                                            detail.request.hasElevator !== null
                                                ? detail.request.hasElevator
                                                    ? '있음'
                                                    : '없음'
                                                : '선택안함'
                                        }
                                        readonly={true}
                                    />
                                    <LabelAboveInput
                                        label='차량진입'
                                        col={3}
                                        value={
                                            detail.request.hasCarEntrance !== null
                                                ? detail.request.hasCarEntrance
                                                    ? '가능'
                                                    : '불가능'
                                                : '선택안함'
                                        }
                                        readonly={true}
                                    />
                                </div>
                                <div className={'col-12'}>
                                    <LabelAboveInput
                                        label={'요청내용'}
                                        col={12}
                                        value={
                                            <CFormTextarea
                                                value={detail.request.content}
                                                rows={5}
                                                readOnly={true}
                                                style={{ resize: 'none' }}
                                            />
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <CButton
                        color='primary'
                        size={'lg'}
                        className='m-3'
                        onClick={() => {
                            navigate(-1)
                        }}>
                        확인
                    </CButton>
                </div>
            </div>
            <span>
                <h5>받은 견적</h5>
            </span>
            <div className='w-100 text-center mb-5 p-3 rounded border'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-2'>
                                일자
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                대장명
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                연락처
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                견적액
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                코멘트
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래확정
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {estimates.length > 0 ? (
                            estimates.map((item) => (
                                <CTableRow key={item.estimate.id}>
                                    <CTableDataCell>
                                        {dayjs(item.estimate.createdAt).format('YYYY-MM-DD')}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/partner/${item.partner.id}`}>
                                            {item.partner.corpName === 'default'
                                                ? '본인인증미완료'
                                                : item.partner.corpName}
                                        </Link>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {item.partner.phoneNumber
                                            ? item.partner.phoneNumber
                                            : '본인인증미완료'}
                                    </CTableDataCell>
                                    <CTableDataCell>{currencyFormatter(item.estimate.amount)}</CTableDataCell>
                                    <CTableDataCell className={'text-truncate'}>
                                        {item.estimate.comment}
                                    </CTableDataCell>
                                    <CTableDataCell>{item.isMatched ? '확정' : '-'}</CTableDataCell>
                                </CTableRow>
                            ))
                        ) : (
                            <CTableRow>
                                <CTableDataCell colSpan={9}>목록이 없습니다.</CTableDataCell>
                            </CTableRow>
                        )}
                    </CTableBody>
                </CTable>
            </div>
        </>
    )
}

export default RequestDetail
