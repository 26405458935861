import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import FilterDropDown from '../components/FilterDropDown'
import { dayjs } from '../util/dayjs'
import React from 'react'
import { currencyFormatter } from '../util/formatter'
import LabelAboveInput from '../components/LabelAboveInput'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from '../util/api'
import { couponInfoConverter, couponInfoInit, ICouponListResponse } from '../interface/ICouponResponse'
import LoadingButton from '../components/button/LoadingButton'
import { numberValidator } from '../util/validator'

const Coupon = () => {
    const queryClient = useQueryClient()

    const codeRef = React.useRef<HTMLInputElement>(null)

    const [detailModalVisible, setDetailModalVisible] = React.useState(false)
    const [createModalVisible, setCreateModalVisible] = React.useState(false)
    const [fetchingUrl, setFetchingUrl] = React.useState<string>('/api/coupon/list')
    const [couponInfo, setCouponInfo] = React.useState<ICouponListResponse>(couponInfoInit)

    const { data } = useQuery(['couponList', fetchingUrl], () => {
        return axios.get(`${fetchingUrl}`)
    })

    const setInactive = useMutation(
        'setCouponInactive',
        () => {
            return axios.put('api/coupon/inactive', { id: couponInfo.coupon.id })
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('쿠폰이 비활성화 되었습니다.')
                    setDetailModalVisible(false)
                }
            },
            onError: (error) => {
                alert('적용하지 못했습니다. ' + error)
            },
        },
    )

    const createCoupon = useMutation(
        'createCoupon',
        () => {
            return axios.post('api/coupon/create', {
                code: couponInfo.coupon.code,
                amount: couponInfo.coupon.specification.amount,
                discount: couponInfo.coupon.specification.discount,
                targetRole: couponInfo.coupon.specification.targetRole,
                expiredAt:
                    couponInfo.coupon.specification.constraint.expiredAt !== ''
                        ? couponInfo.coupon.specification.constraint.expiredAt
                        : undefined,
                priceGreaterThan: couponInfo.coupon.specification.constraint.priceGreaterThan,
                region: couponInfo.coupon.specification.constraint.region,
                classification: couponInfo.coupon.specification.constraint.classification,
                reusable: couponInfo.coupon.specification.constraint.reusable,
                totalStock: couponInfo.coupon.specification.constraint.totalStock,
            })
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('쿠폰이 생성되었습니다.')
                    setCreateModalVisible(false)
                }
            },
            onError: (error) => {
                alert('생성하지 못했습니다. ' + error)
            },
        },
    )

    const list: ICouponListResponse[] = data?.data.body

    return (
        <>
            <div className='container d-flex flex-column'>
                <div>
                    <h1>쿠폰관리</h1>
                </div>

                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={codeRef} type='text' placeholder='code로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    color={'primary'}
                                    onClick={() =>
                                        setFetchingUrl(`/api/coupon/search/${codeRef.current?.value}`)
                                    }>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CButton
                                    color='secondary'
                                    onClick={() => {
                                        codeRef.current!.value = ''
                                        setFetchingUrl('/api/coupon/list')
                                        queryClient.invalidateQueries(['couponList', fetchingUrl])
                                    }}>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*테이블 */}
                <div className='text-center'>
                    <CTable striped>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    코드
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    발행일
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    만료일
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    할인액
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    최소결제금액
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    <FilterDropDown
                                        name='대상'
                                        items={[
                                            { name: '고객', value: 'CUSTOMER' },
                                            { name: '대장', value: 'PROVIDER' },
                                        ]}
                                        requestUrl='/api/coupon/filter?targetRole='
                                        onClick={(param: string) => {
                                            if (fetchingUrl.includes('targetRole')) {
                                                setFetchingUrl(
                                                    fetchingUrl.replace(
                                                        /targetRole=[A-Z]+/,
                                                        `targetRole=${param
                                                            .replace('/api/coupon/filter?targetRole=', '')
                                                            .replace('&', '')}`,
                                                    ),
                                                )
                                            } else if (fetchingUrl.includes('state')) {
                                                setFetchingUrl(
                                                    fetchingUrl + param.replace('/api/coupon/filter?', ''),
                                                )
                                            } else {
                                                setFetchingUrl(`${param}`)
                                            }
                                        }}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-1'>
                                    <FilterDropDown
                                        name='상태'
                                        items={[
                                            { name: '사용가능', value: 'ACTIVE' },
                                            { name: '사용불가', value: 'INACTIVE' },
                                        ]}
                                        requestUrl='/api/coupon/filter?state='
                                        onClick={(param: string) => {
                                            if (fetchingUrl.includes('state')) {
                                                setFetchingUrl(
                                                    fetchingUrl.replace(
                                                        /state=[A-Z]+/,
                                                        `state=${param
                                                            .replace('/api/coupon/filter?state=', '')
                                                            .replace('&', '')}`,
                                                    ),
                                                )
                                            } else if (fetchingUrl.includes('targetRole')) {
                                                setFetchingUrl(
                                                    fetchingUrl + param.replace('/api/coupon/filter?', ''),
                                                )
                                            } else {
                                                setFetchingUrl(`${param.replace(fetchingUrl, '')}`)
                                            }
                                        }}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col' className='col-2'>
                                    사용횟수
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody
                            style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                            {list
                                .sort((a, b) => a.coupon.state.charCodeAt(0) - b.coupon.state.charCodeAt(0))
                                .map((item) => (
                                    <CTableRow key={item.coupon.id}>
                                        <CTableDataCell
                                            className={'text-decoration-underline'}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setCouponInfo(couponInfoConverter(item))
                                                setDetailModalVisible(true)
                                            }}>
                                            {item.coupon.code}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {dayjs(item.coupon.createdAt).format('YY.MM.DD')}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {!item.coupon.specification.constraint.expiredAt
                                                ? '무제한'
                                                : dayjs(
                                                      item.coupon.specification.constraint.expiredAt,
                                                  ).format('YY.MM.DD')}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {currencyFormatter(item.coupon.specification.amount)}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {currencyFormatter(
                                                item.coupon.specification.constraint.priceGreaterThan,
                                            )}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {item.coupon.specification.targetRole === 'CUSTOMER'
                                                ? '고객'
                                                : '대장'}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {item.coupon.state === 'ACTIVE' ? (
                                                <span className={'fw-bold text-success'}>사용가능</span>
                                            ) : (
                                                <span className={'fw-bold text-danger'}>사용불가</span>
                                            )}
                                        </CTableDataCell>
                                        <CTableDataCell>{item.usedCount}회</CTableDataCell>
                                    </CTableRow>
                                ))}
                        </CTableBody>
                    </CTable>
                </div>
                <div className={'w-100 d-flex justify-content-between align-items-center'}>
                    <CButton color='primary' onClick={() => setCreateModalVisible(true)}>
                        신규쿠폰 등록
                    </CButton>
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setFetchingUrl('/api/coupon/list')
                            queryClient.invalidateQueries(['couponList', fetchingUrl])
                        }}>
                        목록 초기화
                    </CButton>
                </div>
            </div>
            <CModal
                size={'lg'}
                visible={detailModalVisible}
                onClose={() => {
                    setCouponInfo(couponInfoInit)
                    setDetailModalVisible(false)
                }}>
                <CModalHeader> 쿠폰 상세내역</CModalHeader>
                <CModalBody className={'d-flex justify-content-center flex-wrap'}>
                    <LabelAboveInput readonly label={'쿠폰코드'} col={6} value={couponInfo.coupon.code} />
                    <LabelAboveInput
                        label={'발행일'}
                        readonly
                        col={6}
                        value={dayjs(couponInfo.coupon.createdAt).format('YY.MM.DD')}
                    />
                    <LabelAboveInput
                        label={'할인액'}
                        readonly
                        col={6}
                        value={currencyFormatter(couponInfo.coupon.specification.amount)}
                    />
                    <LabelAboveInput
                        label={'대상'}
                        readonly
                        col={6}
                        value={couponInfo.coupon.specification.targetRole}
                    />
                    <LabelAboveInput label={'상태'} readonly col={12} value={couponInfo.coupon.state} />
                    <LabelAboveInput label={'사용횟수'} readonly col={6} value={`${couponInfo.usedCount}`} />
                    <LabelAboveInput
                        label={'누적 사용액'}
                        readonly
                        col={6}
                        value={currencyFormatter(couponInfo.totalUsedAmount)}
                    />

                    <div className={'d-flex flex-wrap col-12 border rounded'}>
                        <LabelAboveInput
                            label={'만료일'}
                            readonly
                            col={12}
                            value={couponInfo.coupon.specification.constraint.expiredAt}
                        />
                        <LabelAboveInput
                            label={'최소결제금액'}
                            readonly
                            col={12}
                            value={currencyFormatter(
                                couponInfo.coupon.specification.constraint.priceGreaterThan,
                            )}
                        />
                        <LabelAboveInput
                            label={'재사용 가능 여부'}
                            readonly
                            col={12}
                            value={couponInfo.coupon.specification.constraint.reusable as string}
                        />
                        <LabelAboveInput
                            label={'지정된 총 수량'}
                            readonly
                            col={6}
                            value={String(
                                couponInfo.coupon.specification.constraint.totalStock === -1
                                    ? '무제한'
                                    : couponInfo.coupon.specification.constraint.totalStock,
                            )}
                        />
                        <LabelAboveInput
                            label={'남은 수량'}
                            readonly
                            col={6}
                            value={
                                couponInfo.coupon.specification.constraint.totalStock === '무제한'
                                    ? '무제한'
                                    : String(
                                          couponInfo.coupon.specification.constraint.totalStock -
                                              couponInfo.usedCount,
                                      )
                            }
                        />
                    </div>
                </CModalBody>
                <CModalFooter className={'d-flex justify-content-between'}>
                    <LoadingButton
                        className={'text-white'}
                        color='danger'
                        disabled={
                            couponInfo.coupon.state === 'INACTIVE' || couponInfo.coupon.state === '사용불가'
                        }
                        isLoading={setInactive.isLoading}
                        onClick={() => setInactive.mutate()}>
                        이 쿠폰 비활성화
                    </LoadingButton>
                    <CButton color='secondary' onClick={() => setDetailModalVisible(false)}>
                        확인
                    </CButton>
                </CModalFooter>
            </CModal>
            {/*신규쿠폰 생성*/}
            <CModal
                alignment={'center'}
                size={'lg'}
                visible={createModalVisible}
                onClose={() => {
                    setCouponInfo(couponInfoInit)
                    setCreateModalVisible(false)
                }}>
                <CModalHeader>신규 쿠폰 생성</CModalHeader>
                <CModalBody className={'d-flex justify-content-center flex-wrap'}>
                    <LabelAboveInput
                        label={'쿠폰코드'}
                        col={6}
                        value={couponInfo.coupon.code}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            // 공백, 특수문자 validation. 한글 입력 가능
                            if (e.target.value.match(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]/g)) {
                                alert('쿠폰코드는 영문, 숫자, 한글만 입력 가능합니다.')
                                return
                            }
                            setCouponInfo({
                                ...couponInfo,
                                coupon: {
                                    ...couponInfo.coupon,
                                    code: e.target.value,
                                },
                            })
                        }}
                    />
                    <LabelAboveInput
                        label={'할인액'}
                        col={6}
                        value={couponInfo.coupon.specification.amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (!numberValidator(e.target.value)) {
                                alert('숫자만 입력 가능합니다.')
                                return
                            }
                            setCouponInfo({
                                ...couponInfo,
                                coupon: {
                                    ...couponInfo.coupon,
                                    specification: {
                                        ...couponInfo.coupon.specification,
                                        amount: e.target.value,
                                    },
                                },
                            })
                        }}
                    />
                    <LabelAboveInput
                        label={'대상'}
                        col={6}
                        value={
                            <div>
                                <CButton active className={'me-3'} variant={'outline'}>
                                    고객
                                </CButton>
                                <CButton disabled variant={'outline'}>
                                    대장
                                </CButton>
                            </div>
                        }
                    />
                    <LabelAboveInput
                        label={'만료일'}
                        col={6}
                        type={'date'}
                        value={
                            <div>
                                <input
                                    className={'col-8 me-3'}
                                    type='date'
                                    value={couponInfo.coupon.specification.constraint.expiredAt}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setCouponInfo({
                                            ...couponInfo,
                                            coupon: {
                                                ...couponInfo.coupon,
                                                specification: {
                                                    ...couponInfo.coupon.specification,
                                                    constraint: {
                                                        ...couponInfo.coupon.specification.constraint,
                                                        expiredAt: e.target.value,
                                                    },
                                                },
                                            },
                                        })
                                    }}
                                />
                                <CButton
                                    variant={'outline'}
                                    active={couponInfo.coupon.specification.constraint.expiredAt === ''}
                                    onClick={() =>
                                        setCouponInfo({
                                            ...couponInfo,
                                            coupon: {
                                                ...couponInfo.coupon,
                                                specification: {
                                                    ...couponInfo.coupon.specification,
                                                    constraint: {
                                                        ...couponInfo.coupon.specification.constraint,
                                                        expiredAt: '',
                                                    },
                                                },
                                            },
                                        })
                                    }>
                                    무제한
                                </CButton>
                            </div>
                        }
                    />
                    <LabelAboveInput
                        label={'최소결제금액'}
                        col={6}
                        value={couponInfo.coupon.specification.constraint.priceGreaterThan}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (!numberValidator(e.target.value)) {
                                alert('숫자만 입력 가능합니다.')
                                return
                            }
                            setCouponInfo({
                                ...couponInfo,
                                coupon: {
                                    ...couponInfo.coupon,
                                    specification: {
                                        ...couponInfo.coupon.specification,
                                        constraint: {
                                            ...couponInfo.coupon.specification.constraint,
                                            priceGreaterThan: e.target.value,
                                        },
                                    },
                                },
                            })
                        }}
                    />
                    <LabelAboveInput
                        label={'재사용 가능 여부'}
                        col={6}
                        value={
                            <div>
                                <CButton
                                    className={'me-3'}
                                    variant={'outline'}
                                    active={couponInfo.coupon.specification.constraint.reusable === true}
                                    onClick={() =>
                                        setCouponInfo({
                                            ...couponInfo,
                                            coupon: {
                                                ...couponInfo.coupon,
                                                specification: {
                                                    ...couponInfo.coupon.specification,
                                                    constraint: {
                                                        ...couponInfo.coupon.specification.constraint,
                                                        reusable: true,
                                                    },
                                                },
                                            },
                                        })
                                    }>
                                    가능
                                </CButton>
                                <CButton
                                    variant={'outline'}
                                    active={couponInfo.coupon.specification.constraint.reusable === false}
                                    onClick={() =>
                                        setCouponInfo({
                                            ...couponInfo,
                                            coupon: {
                                                ...couponInfo.coupon,
                                                specification: {
                                                    ...couponInfo.coupon.specification,
                                                    constraint: {
                                                        ...couponInfo.coupon.specification.constraint,
                                                        reusable: false,
                                                    },
                                                },
                                            },
                                        })
                                    }>
                                    불가능
                                </CButton>
                            </div>
                        }
                    />
                    <LabelAboveInput
                        label={'총 발급 수량'}
                        col={6}
                        value={String(
                            couponInfo.coupon.specification.constraint.totalStock !== -1
                                ? couponInfo.coupon.specification.constraint.totalStock
                                : '무제한',
                        )}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (!numberValidator(e.target.value)) {
                                alert('숫자만 입력 가능합니다.')
                                return
                            }
                            if (Number(e.target.value) < 0) {
                                alert('0이상 입력 가능합니다.')
                                return
                            }
                            setCouponInfo({
                                ...couponInfo,
                                coupon: {
                                    ...couponInfo.coupon,
                                    specification: {
                                        ...couponInfo.coupon.specification,
                                        constraint: {
                                            ...couponInfo.coupon.specification.constraint,
                                            totalStock: Number(e.target.value),
                                        },
                                    },
                                },
                            })
                        }}
                    />
                    <div className={'col-6 d-flex flex-column justify-content-center'}>
                        <div className={'col-6 mt-3'}>
                            <CButton
                                variant={'outline'}
                                active={couponInfo.coupon.specification.constraint.totalStock === -1}
                                onClick={() => {
                                    if (couponInfo.coupon.specification.constraint.totalStock === -1) {
                                        setCouponInfo({
                                            ...couponInfo,
                                            coupon: {
                                                ...couponInfo.coupon,
                                                specification: {
                                                    ...couponInfo.coupon.specification,
                                                    constraint: {
                                                        ...couponInfo.coupon.specification.constraint,
                                                        totalStock: 0,
                                                    },
                                                },
                                            },
                                        })
                                    } else {
                                        setCouponInfo({
                                            ...couponInfo,
                                            coupon: {
                                                ...couponInfo.coupon,
                                                specification: {
                                                    ...couponInfo.coupon.specification,
                                                    constraint: {
                                                        ...couponInfo.coupon.specification.constraint,
                                                        totalStock: -1,
                                                    },
                                                },
                                            },
                                        })
                                    }
                                }}>
                                발급수량 무제한
                            </CButton>
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter className={'d-flex justify-content-between'}>
                    <CButton color='secondary' onClick={() => setCreateModalVisible(false)}>
                        취소
                    </CButton>
                    <LoadingButton
                        color='primary'
                        isLoading={createCoupon.isLoading}
                        onClick={() => createCoupon.mutate()}>
                        생성
                    </LoadingButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default Coupon
