import React from 'react'
import Alarm from './pages/Alarm'
import Ask from './pages/Ask'
import Coupon from './pages/Coupon'
import ImageUpdate from './pages/ImageUpdate'
import Main from './pages/Main'
import Verification from './pages/Verification'
import Construction from './pages/construction/Construction'
import ClassificationSorted from './pages/dashboard/ClassificationSorted'
import RegionSorted from './pages/dashboard/RegionSorted'
import RequestDashboard from './pages/dashboard/RequestDashboard'
import UserDashboard from './pages/dashboard/UserDashboard'
import Banner from './pages/notice/Banner'
import Notice from './pages/notice/Notice'
import Popup from './pages/notice/Popup'
import Partner from './pages/partner/Partner'
import PartnerDetail from './pages/partner/PartnerDetail'
import Payment from './pages/payment/Payment'
import SplitBillDetail from './pages/payment/SplitBillDetail'
import SplitPayment from './pages/payment/SplitPayment'
import PointDate from './pages/point/PointDate'
import PointUser from './pages/point/PointUser'
import RequestDetail from './pages/request/RequestDetail'
import RequestList from './pages/request/RequestList'
import RequestNonameDetail from './pages/requestNoname/RequestNonameDetail'
import RequestNonameList from './pages/requestNoname/RequestNonameList'
import Review from './pages/review/Review'
import ReviewDetail from './pages/review/ReviewDetail'
import Terms from './pages/service/Terms'
import User from './pages/user/User'
import UserDetail from './pages/user/UserDetail'
import Inquiry from './pages/voc/Inquiry'
import InquiryDetail from './pages/voc/InquiryDetail'
import Report from './pages/voc/Report'
import ReportDetail from './pages/voc/ReportDetail'
import TotalDataDashboard from './pages/dashboard/TotalDataDashboard'

const LoginPage = React.lazy(() => import('./pages/Login'))

const routes = [
    { path: '/main', element: Main },
    { path: '/login', element: LoginPage },
    { path: '/dashboard/user', element: UserDashboard },
    { path: '/dashboard/request', element: RequestDashboard },
    { path: '/dashboard/requestregion', element: RegionSorted },
    { path: '/dashboard/requestclassification', element: ClassificationSorted },
    { path: '/dashboard/total', element: TotalDataDashboard },
    { path: '/user', element: User },
    { path: '/user/:id', element: UserDetail },
    { path: '/partner', element: Partner },
    { path: '/partner/:id', element: PartnerDetail },
    { path: '/request', element: RequestList },
    { path: '/request/:requestId', element: RequestDetail },
    { path: '/noname', element: RequestNonameList },
    { path: '/noname/:requestId', element: RequestNonameDetail },
    { path: '/service/terms', element: Terms },
    { path: '/inquiry', element: Inquiry },
    { path: '/inquiry/:inquiryId', element: InquiryDetail },
    { path: '/report', element: Report },
    { path: '/report/:reportId', element: ReportDetail },
    { path: '/review', element: Review },
    { path: '/review/:reviewId', element: ReviewDetail },
    { path: '/point/date', element: PointDate },
    { path: '/point/user/:userEmail', element: PointUser },
    { path: '/alarm', element: Alarm },
    { path: '/notice', element: Notice },
    { path: '/notice/banner', element: Banner },
    { path: '/notice/popup', element: Popup },
    { path: '/ask', element: Ask },
    { path: '/Verification', element: Verification },
    { path: '/payment', element: Payment },
    { path: '/payment/split', element: SplitPayment },
    { path: '/payment/split/:id', element: SplitBillDetail },
    { path: '/coupon', element: Coupon },
    { path: '/image', element: ImageUpdate },
    { path: '/construction', element: Construction },
]

export default routes
