import { ReactComponent as BlackBack } from './icons/back-black.svg'
import { ReactComponent as WhiteBack } from './icons/back-white.svg'
import { ReactComponent as BlackMore } from './icons/more-black.svg'
import { ReactComponent as WhiteMore } from './icons/more-white.svg'
import { ReactComponent as TongbaIcon } from './icons/nav-tongba-icon.svg'
import { ReactComponent as BaseNotification } from './icons/notification.svg'

import { ReactComponent as Chatting } from './icons/chatting.svg'
import { ReactComponent as Home } from './icons/home.svg'
import { ReactComponent as MyInfo } from './icons/my-info.svg'
import { ReactComponent as ReceiveEstimateFill } from './icons/receive-estimate-fill.svg'
import { ReactComponent as ReceiveEstimate } from './icons/receive-estimate.svg'
import { ReactComponent as SendEstimateFill } from './icons/send-estimate-fill.svg'
import { ReactComponent as SendEstimate } from './icons/send-estimate.svg'

import { ReactComponent as CornerDownRight } from './icons/corner-down-right.svg'
import { ReactComponent as DropDown } from './icons/drop-down.svg'
import { ReactComponent as DropUp } from './icons/drop-up.svg'
import { ReactComponent as Right } from './icons/right-arrow.svg'

import { useNavigate } from 'react-router-dom'
import { ReactComponent as Apple } from './icons/apple.svg'
import { ReactComponent as Camera } from './icons/camera.svg'
import { ReactComponent as Caution } from './icons/caution.svg'
import { ReactComponent as CheckOrange } from './icons/check-orange.svg'
import { ReactComponent as CheckWhite } from './icons/check-white.svg'
import { ReactComponent as DefaultProfile45 } from './icons/default-profile45.svg'
import { ReactComponent as DefaultProfile60 } from './icons/default-profile60.svg'
import { ReactComponent as Delete } from './icons/delete.svg'
import { ReactComponent as File } from './icons/file.svg'
import { ReactComponent as Kakao } from './icons/kakao.svg'
import { ReactComponent as Naver } from './icons/naver.svg'
import { ReactComponent as Phone } from './icons/phone.svg'
import { ReactComponent as Plus } from './icons/plus-gray.svg'
import { ReactComponent as Star } from './icons/review-star-fill.svg'
import { ReactComponent as EmptyStar } from './icons/review-star.svg'
import { ReactComponent as TongbaLogo } from './icons/splash-tongba.svg'

const DeleteIcon = () => {
    return (
        <div
            className={'d-flex justify-content-center align-items-center bg-red'}
            style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                boxShadow: '4px 4px 20px rgba(56, 56, 56, 0.4)',
            }}>
            <Delete />
        </div>
    )
}

const CameraIcon = () => {
    return (
        <div style={{ padding: '6px', marginTop: '2px' }}>
            <Camera />
        </div>
    )
}

const FileIcon = () => {
    return (
        <div style={{ padding: '6px', marginTop: '3.5px' }}>
            <File />
        </div>
    )
}

const PhoneIcon = () => {
    return (
        <div style={{ padding: '6px', marginTop: '3.5px' }}>
            <Phone />
        </div>
    )
}

const KakaoLogo = () => {
    return (
        <div>
            <Kakao />
        </div>
    )
}

const NaverLogo = () => {
    return (
        <div>
            <Naver />
        </div>
    )
}

const AppleLogo = () => {
    return (
        <div>
            <Apple />
        </div>
    )
}

const SplashLogo = () => {
    return (
        <div style={{ marginBottom: '5px' }}>
            <TongbaLogo />
        </div>
    )
}

const RightArrow = () => {
    return (
        <div
            style={{
                width: '20px',
                height: '20px',
                textAlign: 'center',
                verticalAlign: 'middle',
                boxSizing: 'border-box',
            }}>
            <Right />
        </div>
    )
}

const CornerDownRightArrow = () => {
    return (
        <div
            style={{
                width: '20px',
                height: '20px',
                textAlign: 'center',
                verticalAlign: 'middle',
                boxSizing: 'border-box',
            }}>
            <CornerDownRight />
        </div>
    )
}

const UpArrow = () => {
    return (
        <div
            style={{
                width: '20px',
                height: '20px',
                textAlign: 'center',
                verticalAlign: 'middle',
                boxSizing: 'border-box',
            }}>
            <DropUp />
        </div>
    )
}

const DownArrow = () => {
    return (
        <div
            style={{
                width: '20px',
                height: '20px',
                textAlign: 'center',
                verticalAlign: 'middle',
                boxSizing: 'border-box',
            }}>
            <DropDown />
        </div>
    )
}

const NotificationFill = () => {
    const navigate = useNavigate()
    return (
        <div
            className={'bg-white'}
            onClick={() => navigate('/notification')}
            style={{
                position: 'relative',
                boxSizing: 'border-box',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                boxShadow: '4px 4px 20px rgba(10, 10, 10, 0.2)',
                flexShrink: 0,
                cursor: 'pointer',
            }}>
            <BaseNotification style={{ position: 'absolute', top: '10px', right: '9px' }} />
            <div
                className={'bg-orange'}
                style={{
                    position: 'absolute',
                    top: '8px',
                    right: '6px',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                }}></div>
        </div>
    )
}

const Notification = () => {
    const navigate = useNavigate()
    return (
        <div
            className={'bg-white'}
            onClick={() => navigate('/notification')}
            style={{
                position: 'relative',
                boxSizing: 'border-box',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                boxShadow: '4px 4px 20px rgba(10, 10, 10, 0.2)',
                flexShrink: 0,
                cursor: 'pointer',
            }}>
            <BaseNotification style={{ position: 'absolute', top: '10px', right: '9px' }} />
        </div>
    )
}

export const ICONS = {
    NAV: {
        /** TOP NAV */
        TONGBAICON: <TongbaIcon />,
        NOTIFICATION: <Notification />,
        NOTIFICATION_FILL: <NotificationFill />,
        BLACKBACK: <BlackBack />,
        WHITEBACK: <WhiteBack />,
        BLACKMORE: <BlackMore />,
        WHITEMORE: <WhiteMore />,
        /** FOOTER NAV */
        HOME: <Home />,
        HOME_FILL: <Home className={'path-fill'} />,
        RECEIVE_ESTIMATE: <ReceiveEstimate />,
        RECEIVE_ESTIMATE_FILL: <ReceiveEstimateFill />,
        SEND_EXTIMATE: <SendEstimate />,
        SEND_EXTIMATE_FILL: <SendEstimateFill />,
        CHATTING: <Chatting />,
        CHATTING_FILL: <Chatting className={'rect-fill path-fill'} />,
        MY_INFO: <MyInfo />,
        MY_INFO_FILL: <MyInfo className={'path-fill'} />,
    },
    UP_ARROW: <UpArrow />,
    DOWN_ARROW: <DownArrow />,
    CORNER_DOWN_RIGHT: <CornerDownRightArrow />,
    RIGHT_ARROW: <RightArrow />,
    TONGBA_LOGO: <SplashLogo />,
    KAKAO_LOGO: <KakaoLogo />,
    NAVER_LOGO: <NaverLogo />,
    APPLE_LOGO: <AppleLogo />,
    CAUTION_ICON: <Caution />,
    CAMERA: <CameraIcon />,
    FILE: <FileIcon />,
    PHONE: <PhoneIcon />,
    CHECK_ORANGE: <CheckOrange />,
    CHECK_WHITE: <CheckWhite />,
    PLUS: <Plus />,
    STAR: <Star />,
    EMPTY_STAR: <EmptyStar />,
    DELETE: <DeleteIcon />,
    DEFAULT_PROFILE_45: <DefaultProfile45 />,
    DEFAULT_PROFILE_60: <DefaultProfile60 />,
}
