import { useQuery } from 'react-query'
import axios from '../../util/api'
import { RequestData } from '../../interface/IDashboardDataResponse'
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilChartPie, cilMinus } from '@coreui/icons'
import { CChartBar } from '@coreui/react-chartjs'
import { dayjs } from '../../util/dayjs'
import { CWidgetStatsA, CWidgetStatsB, CWidgetStatsF } from '@coreui/react'

const RequestDashboard = () => {
    const root = document.getElementById('root') as HTMLElement

    const { data } = useQuery('requestDashboard', () => {
        return axios.get('/api/dashboard/request')
    })

    const requestData: RequestData = data?.data.body

    const requestInfo = {
        yesterday: {
            totalRequest: requestData?.requestInfo.at(-1)?.totalRequest ?? 0,
            totalNonameRequest: requestData?.requestInfo.at(-1)?.totalNonameRequest ?? 0,
            totalMatchedRequest: requestData?.requestInfo.at(-1)?.totalMatchedRequest ?? 0,
            totalNormalRequest: requestData?.requestInfo.at(-1)?.totalNormalRequest ?? 0,
            totalSimpleRequest: requestData?.requestInfo.at(-1)?.totalSimpleRequest ?? 0,
        },
        towDaysAgo: {
            totalRequest: requestData?.requestInfo.at(-2)?.totalRequest ?? 0,
            totalNonameRequest: requestData?.requestInfo.at(-2)?.totalNonameRequest ?? 0,
            totalMatchedRequest: requestData?.requestInfo.at(-2)?.totalMatchedRequest ?? 0,
            totalNormalRequest: requestData?.requestInfo.at(-2)?.totalNormalRequest ?? 0,
            totalSimpleRequest: requestData?.requestInfo.at(-2)?.totalSimpleRequest ?? 0,
        },
    }

    return (
        <div className={'container d-flex flex-column'}>
            <div>
                <h1>요청서/견적서 정보</h1>
            </div>
            <div className={'mt-5'}>
                <h3>요청서 (전일 기준 전체 유효 요청서)</h3>
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-start gap-3 py-3'}>
                {/*전체요청서 수*/}
                <CWidgetStatsA
                    className='col-5'
                    color='info'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>회원요청서</div>
                            <div className={'ms-3'}>{requestInfo.yesterday.totalRequest.toString()} 건</div>
                        </div>
                    }
                    title={
                        requestInfo.towDaysAgo.totalRequest != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(
                                    100 -
                                        (requestInfo.yesterday.totalRequest /
                                            requestInfo.towDaysAgo.totalRequest) *
                                            100,
                                ).toFixed(1)}
                                %{' '}
                                <CIcon
                                    icon={
                                        requestInfo.yesterday.totalRequest -
                                            requestInfo.towDaysAgo.totalRequest ===
                                        0
                                            ? cilMinus
                                            : requestInfo.yesterday.totalRequest -
                                                  requestInfo.towDaysAgo.totalRequest >
                                              0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartBar
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: requestData.requestInfo.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '회원 요청서',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        barPercentage: 0.6,
                                        pointBackgroundColor: '#39f',
                                        data: requestData.requestInfo.map((data) => data.totalRequest),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalRequest > data.totalRequest ? prev : data,
                                            ).totalRequest + 10,
                                        max:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalRequest < data.totalRequest ? prev : data,
                                            ).totalRequest - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsA
                    className='col-5'
                    color='warning'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>비회원요청서</div>
                            <div className={'ms-3'}>
                                {requestInfo.yesterday.totalNonameRequest.toString()} 건
                            </div>
                        </div>
                    }
                    title={
                        requestInfo.towDaysAgo.totalNonameRequest != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(
                                    100 -
                                        (requestInfo.yesterday.totalNonameRequest /
                                            requestInfo.towDaysAgo.totalNonameRequest) *
                                            100,
                                ).toFixed(1)}
                                %{' '}
                                <CIcon
                                    icon={
                                        requestInfo.yesterday.totalNonameRequest -
                                            requestInfo.towDaysAgo.totalNonameRequest ===
                                        0
                                            ? cilMinus
                                            : requestInfo.yesterday.totalNonameRequest -
                                                  requestInfo.towDaysAgo.totalNonameRequest >
                                              0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartBar
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: requestData.requestInfo.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '비회원 요청서',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        barPercentage: 0.6,
                                        pointBackgroundColor: '#f9b115',
                                        data: requestData.requestInfo.map((data) => data.totalNonameRequest),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalNonameRequest > data.totalNonameRequest
                                                    ? prev
                                                    : data,
                                            ).totalRequest + 10,
                                        max:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalNonameRequest < data.totalNonameRequest
                                                    ? prev
                                                    : data,
                                            ).totalRequest - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsA
                    className='col-5'
                    color='danger'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>거래확정</div>
                            <div className={'ms-3'}>
                                {requestInfo.yesterday.totalMatchedRequest.toString()} 건
                            </div>
                        </div>
                    }
                    title={
                        requestInfo.towDaysAgo.totalMatchedRequest != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(
                                    100 -
                                        (requestInfo.yesterday.totalMatchedRequest /
                                            requestInfo.towDaysAgo.totalMatchedRequest) *
                                            100,
                                ).toFixed(1)}
                                %{' '}
                                <CIcon
                                    icon={
                                        requestInfo.yesterday.totalMatchedRequest -
                                            requestInfo.towDaysAgo.totalMatchedRequest ===
                                        0
                                            ? cilMinus
                                            : requestInfo.yesterday.totalMatchedRequest -
                                                  requestInfo.towDaysAgo.totalMatchedRequest >
                                              0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartBar
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: requestData.requestInfo.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '거래확정 요청서',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        barPercentage: 0.6,
                                        data: requestData.requestInfo.map((data) => data.totalMatchedRequest),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalMatchedRequest > data.totalMatchedRequest
                                                    ? prev
                                                    : data,
                                            ).totalMatchedRequest + 10,
                                        max:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalMatchedRequest < data.totalMatchedRequest
                                                    ? prev
                                                    : data,
                                            ).totalMatchedRequest - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsA
                    className='col-5'
                    color='primary'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>일반요청서</div>
                            <div className={'ms-3'}>
                                {requestInfo.yesterday.totalNormalRequest.toString()} 건
                            </div>
                        </div>
                    }
                    title={
                        requestInfo.towDaysAgo.totalNormalRequest != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(
                                    100 -
                                        (requestInfo.yesterday.totalNormalRequest /
                                            requestInfo.towDaysAgo.totalNormalRequest) *
                                            100,
                                ).toFixed(1)}
                                %{' '}
                                <CIcon
                                    icon={
                                        requestInfo.yesterday.totalNormalRequest -
                                            requestInfo.towDaysAgo.totalNormalRequest ===
                                        0
                                            ? cilMinus
                                            : requestInfo.yesterday.totalNormalRequest -
                                                  requestInfo.towDaysAgo.totalNormalRequest >
                                              0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartBar
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: requestData.requestInfo.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '일반요청서',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        data: requestData.requestInfo.map((data) => data.totalNormalRequest),
                                        barPercentage: 0.6,
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalNormalRequest > data.totalNormalRequest
                                                    ? prev
                                                    : data,
                                            ).totalNormalRequest + 10,
                                        max:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalNormalRequest < data.totalNormalRequest
                                                    ? prev
                                                    : data,
                                            ).totalNormalRequest - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsA
                    className='col-5'
                    color='success'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>시공요청서</div>
                            <div className={'ms-3'}>
                                {requestInfo.yesterday.totalSimpleRequest.toString()} 건
                            </div>
                        </div>
                    }
                    title={
                        requestInfo.towDaysAgo.totalSimpleRequest != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(
                                    100 -
                                        (requestInfo.yesterday.totalSimpleRequest /
                                            requestInfo.towDaysAgo.totalSimpleRequest) *
                                            100,
                                ).toFixed(1)}
                                %{' '}
                                <CIcon
                                    icon={
                                        requestInfo.yesterday.totalSimpleRequest -
                                            requestInfo.towDaysAgo.totalSimpleRequest ===
                                        0
                                            ? cilMinus
                                            : requestInfo.yesterday.totalSimpleRequest -
                                                  requestInfo.towDaysAgo.totalSimpleRequest >
                                              0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartBar
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: requestData.requestInfo.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '시공요청서',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        barPercentage: 0.6,
                                        pointBackgroundColor: '#2eb85c',
                                        data: requestData.requestInfo.map((data) => data.totalSimpleRequest),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalSimpleRequest > data.totalSimpleRequest
                                                    ? prev
                                                    : data,
                                            ).totalSimpleRequest + 10,
                                        max:
                                            requestData.requestInfo.reduce((prev, data) =>
                                                prev.totalSimpleRequest < data.totalSimpleRequest
                                                    ? prev
                                                    : data,
                                            ).totalSimpleRequest - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsB
                    className='col-5 fs-20'
                    progress={{
                        color: 'primary',
                        height: 30,
                        value:
                            (requestInfo.yesterday.totalNormalRequest /
                                (requestInfo.yesterday.totalRequest +
                                    requestInfo.yesterday.totalNonameRequest)) *
                            100,
                    }}
                    text={`일반요청서 ${(
                        (requestInfo.yesterday.totalNormalRequest /
                            (requestInfo.yesterday.totalRequest + requestInfo.yesterday.totalNonameRequest)) *
                        100
                    ).toFixed(1)} % : 시공요청서 ${(
                        (requestInfo.yesterday.totalSimpleRequest /
                            (requestInfo.yesterday.totalRequest + requestInfo.yesterday.totalNonameRequest)) *
                        100
                    ).toFixed(1)} %`}
                    title=''
                    value={`일반요청서 / 시공요청서 비율`}
                />
            </div>
            <div className={'mt-5'}>
                <h3>비율 분석 (전일 기준)</h3>
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-start gap-3 py-3'}>
                <CWidgetStatsB
                    className='mb-3 col-5 fs-20'
                    progress={{
                        color: 'success',
                        value:
                            requestData.ratioOfChange.length > 0
                                ? (requestData.ratioOfChange.reduce(
                                      (prev: number, current) => prev + current.totalCountOfChanged,
                                      0,
                                  ) /
                                      (requestData.requestInfo[requestData.requestInfo.length - 1]
                                          .totalNonameRequest +
                                          requestData.ratioOfChange.reduce(
                                              (prev: number, current) => prev + current.totalCountOfChanged,
                                              0,
                                          ))) *
                                  100
                                : 0,
                    }}
                    text={`비회원요청서 정식요청서 전환 비율 ${
                        requestData.ratioOfChange.length > 0
                            ? (
                                  (requestData.ratioOfChange.reduce(
                                      (prev: number, current) => prev + current.totalCountOfChanged,
                                      0,
                                  ) /
                                      (requestData.requestInfo[requestData.requestInfo.length - 1]
                                          .totalNonameRequest +
                                          requestData.ratioOfChange.reduce(
                                              (prev: number, current) => prev + current.totalCountOfChanged,
                                              0,
                                          ))) *
                                  100
                              ).toFixed(1)
                            : 0
                    } %`}
                    title='비회원요청서 정식요청서 변환'
                    value={`${
                        requestData.ratioOfChange.length > 0
                            ? requestData.ratioOfChange[requestData.ratioOfChange.length - 1]
                                  .totalCountOfChanged
                            : 0
                    }
                    건`}
                />
                <CWidgetStatsB
                    className='mb-3 col-5 fs-20'
                    progress={{
                        color: 'success',
                        value:
                            requestData.ratioOfChange.length > 0
                                ? requestData.ratioOfChange[requestData.ratioOfChange.length - 1]
                                      .ratioOfEstimated * 100
                                : 0,
                    }}
                    text={`견적을 받은 비회원요청서 전환 비율 ${(requestData.ratioOfChange.length > 0
                        ? requestData.ratioOfChange[requestData.ratioOfChange.length - 1].ratioOfEstimated *
                          100
                        : 0
                    ).toFixed(1)} %`}
                    title='한달간 견적을 받은 비회원요청서 변환'
                    value={`${
                        requestData.ratioOfChange.length > 0
                            ? requestData.ratioOfChange[requestData.ratioOfChange.length - 1]
                                  .totalCountOfEstimated
                            : 0
                    }
                    건`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받은 요청서의 수'
                    value={`${
                        requestData.ratioOfEstimated.length > 0 &&
                        requestData.ratioOfEstimated[requestData.ratioOfEstimated.length - 1]
                            .totalCountOfEstimated
                    } 건`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받는 비율'
                    value={`${
                        requestData.ratioOfEstimated.length > 0 &&
                        (
                            requestData.ratioOfEstimated[requestData.ratioOfEstimated.length - 1]
                                .ratioOfEstimated * 100
                        ).toFixed(1)
                    } %`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='요청서당 평균 수신 견적 수'
                    value={`${
                        requestData.ratioOfEstimated.length > 0 &&
                        requestData.ratioOfEstimated[
                            requestData.ratioOfEstimated.length - 1
                        ].averageCountOfEstimated.toFixed(1)
                    } 건`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='견적을 받은 요청서가 거래확정 되는 비율'
                    value={`${
                        requestData.ratioOfEstimated.length > 0 &&
                        (
                            requestData.ratioOfEstimated[requestData.ratioOfEstimated.length - 1]
                                .ratioOfChangeToConstruction * 100
                        ).toFixed(1)
                    } %`}
                />
                <CWidgetStatsF
                    className='mb-3 col-5 fs-20'
                    color='primary'
                    icon={<CIcon icon={cilChartPie} height={24} />}
                    title='가견적 이후 재견적을 받는 비율'
                    value={`${
                        requestData.ratioOfEstimated.length > 0 &&
                        (requestData.ratioOfEstimated[requestData.ratioOfEstimated.length - 1]
                            .ratioOfReEstimated
                            ? (
                                  requestData.ratioOfEstimated[requestData.ratioOfEstimated.length - 1]
                                      .ratioOfReEstimated * 100
                              ).toFixed(1)
                            : '집계된 데이터 없음')
                    } %`}
                />
            </div>
        </div>
    )
}

export default RequestDashboard
