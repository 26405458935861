import { useMutation, useQuery, useQueryClient } from 'react-query'
import React, { useRef, useState } from 'react'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import { Link } from 'react-router-dom'
import { currencyFormatter } from '../../util/formatter'
import axios from '../../util/api'
import { ISplitBillListResponse } from '../../interface/IPaymentResponse'
import { dayjs } from '../../util/dayjs'
import PaginationBar from '../../components/PaginationBar'
import FilterDropDown from '../../components/FilterDropDown'
import { IPagination } from '../../interface/pagination/IPagination'

const SplitPayment = () => {
    const queryClient = useQueryClient()
    const emailRef = useRef<HTMLInputElement>(null)

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [fetchingUri, setFetchingUri] = useState<string>('/list?')

    const { data, error } = useQuery(
        ['getSplitBillList', fetchingUri, pagingParam.page, pagingParam.size],
        () => {
            return axios.get(
                `/api/payment/split${fetchingUri}page=${pagingParam.page}&size=${pagingParam.size}`,
            )
        },
        {
            keepPreviousData: true,
        },
    )

    const searchByEmail = useMutation(
        ['searchByEmail', emailRef.current?.value],
        () => {
            const body = { email: emailRef.current?.value }
            return axios.post('/api/payment/split/search', body)
        },
        {
            onSuccess: (res) => {
                if (res.data.error) {
                    alert(res.data.error)
                } else {
                    queryClient.setQueryData(
                        ['getSplitBillList', pagingParam.page, pagingParam.size],
                        (old: any) => {
                            return {
                                ...old,
                                data: {
                                    ...old.data,
                                    pagination: null,
                                    body: res.data.body,
                                },
                            }
                        },
                    )
                }
            },
        },
    )

    const pagination: IPagination = data?.data.pagination
    const list: ISplitBillListResponse[] = data?.data.body ?? []

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>분할결제 관리</h1>
            </div>
            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={emailRef} type='text' placeholder='고객 email로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => searchByEmail.mutate()}>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs='auto'>
                                <CButton
                                    color='secondary'
                                    onClick={() => {
                                        setFetchingUri('/list?')
                                        queryClient.invalidateQueries([
                                            'getSplitBillList',
                                            fetchingUri,
                                            pagingParam.page,
                                            pagingParam.size,
                                        ])
                                        emailRef.current!.value = ''
                                    }}>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>

                {/*테이블 */}
                <div className='text-center'>
                    <CTable striped>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope='col'>일자</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>고객 email</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>대장</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>견적액</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>분할건수</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>
                                    <FilterDropDown
                                        name={'상태'}
                                        items={[
                                            { name: '완료', value: 'COMPLETE' },
                                            { name: '진행중', value: 'INCOMPLETE' },
                                            { name: '환불', value: 'REFUND' },
                                            { name: '취소', value: 'CANCEL' },
                                            { name: '조치필요', value: 'ERROR' },
                                        ]}
                                        requestUrl={'/filter/'}
                                        onClick={(value: string) => {
                                            setFetchingUri(value.replace('&', '?'))
                                        }}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope='col'>포인트사용/쿠폰사용</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>결제대상금액</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>요청서</CTableHeaderCell>
                                <CTableHeaderCell scope='col'>상세내역보기</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody
                            style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                            {list.map((item) => (
                                <CTableRow key={item.paymentSplitInfoId}>
                                    <CTableDataCell>
                                        {dayjs(item.createdAt).format('YY.MM.DD')}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/user/${item.userId}`}>{item.customerEmail}</Link>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/partner/${item.partnerId}`}>{item.corpName}</Link>
                                    </CTableDataCell>
                                    <CTableDataCell>{currencyFormatter(item.totalAmount)}</CTableDataCell>
                                    <CTableDataCell>{item.countOfSplit}건</CTableDataCell>
                                    <CTableDataCell>
                                        <span
                                            className={
                                                item.state === '조치필요' ? 'fw-bold text-danger' : ''
                                            }>
                                            {item.state}
                                        </span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {item.pointUsed > 0 ? item.pointUsed : '-'}
                                        <br /> {item.couponUsed > 0 ? item.couponUsed : '-'}
                                    </CTableDataCell>
                                    <CTableDataCell>{currencyFormatter(item.amountToBePaid)}</CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/request/${item.requestId}`}>보기</Link>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <Link to={`/payment/split/${item.paymentSplitInfoId}`}>보기</Link>
                                    </CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </div>

                {/*페이징 indicator*/}
                {pagination && (
                    <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
                )}
            </div>
        </div>
    )
}

export default SplitPayment
