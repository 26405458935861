import { CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink } from '@coreui/react'
import React, { ReactNode, useEffect, useState } from 'react'

interface ICardWithNavProps {
    navItems: {
        name: string
        component: ReactNode
    }[]
}

const CardWithNav = ({ navItems }: ICardWithNavProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const ActiveComponent = navItems[activeIndex].component

    const onClickNavItem = (e: React.MouseEvent) => {
        const targetId = e.currentTarget.id
        setActiveIndex(Number(targetId))
    }

    useEffect(() => {}, [activeIndex])

    return (
        <CCard className='text-center'>
            <CCardHeader>
                <CNav variant='tabs' className='card-header-tabs'>
                    {navItems.map((item, index) => (
                        <CNavItem key={index}>
                            <CNavLink
                                href='#'
                                id={index.toString()}
                                active={activeIndex === index}
                                onClick={onClickNavItem}>
                                {item.name}
                            </CNavLink>
                        </CNavItem>
                    ))}
                </CNav>
            </CCardHeader>
            <CCardBody>{ActiveComponent}</CCardBody>
        </CCard>
    )
}

export default CardWithNav
