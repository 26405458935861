import {
    CButton,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CImage,
    CModal,
    CModalBody,
    CModalFooter,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import LoadingButton from '../../components/button/LoadingButton'
import ImageZoom from '../../components/imgae/ImageZoom'
import { IPopupDto, popupDtoInit } from '../../interface/INoticeResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import axios from '../../util/api'

const Popup = () => {
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [viewVisible, setViewVisible] = useState<boolean>(false)
    const [popup, setPopup] = useState<IPopupDto>(popupDtoInit)
    const [checkBox, setCheckBox] = useState<string[]>([])

    const { data } = useQuery(['popupList', pagingParam.page, pagingParam.size], () => {
        return axios.get(`/api/notice/popup/list?page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const pagination: IPagination = data?.data.pagination
    const list: IPopupDto[] = data?.data.body

    const handleDeleteButton = useMutation(
        'deletePopup',
        () => {
            const ids = checkBox.filter((id) => id !== '')
            return axios.put('/api/notice/delete', { ids: ids })
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('삭제되었습니다.')
                    queryClient.invalidateQueries(['bannerList', pagingParam.page, pagingParam.size])
                }
            },
            onError: (error) => {
                alert('삭제하지 못했습니다.' + error)
            },
        },
    )

    useEffect(() => {
        setCheckBox(
            list?.map((item) => {
                return ''
            }),
        )
    }, [data])

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>팝업관리</h1>
            </div>
            <div className='my-5 row'>
                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-1'></CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                생성 일자
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-4'>
                                팝업이미지
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                노출 만료일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                노출 상태
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-3'>
                                연결 공지
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item, index) => (
                            <CTableRow key={item.popup.id}>
                                <CTableDataCell>
                                    <CFormCheck
                                        checked={checkBox[index] !== ''}
                                        onChange={() => {
                                            if (checkBox[index] === '') {
                                                setCheckBox([
                                                    ...checkBox.slice(0, index),
                                                    item.popup.id,
                                                    ...checkBox.slice(index + 1),
                                                ])
                                            } else {
                                                setCheckBox([
                                                    ...checkBox.slice(0, index),
                                                    '',
                                                    ...checkBox.slice(index + 1),
                                                ])
                                            }
                                        }}
                                    />
                                </CTableDataCell>
                                <CTableDataCell>
                                    {dayjs(item.notice.createdAt).format('YY.MM.DD')}
                                </CTableDataCell>
                                <CTableDataCell
                                    onClick={() => {
                                        setPopup(item)
                                        setViewVisible(true)
                                    }}>
                                    <CImage
                                        className={'rounded rounded-2'}
                                        fluid
                                        src={item.popupImage.url}
                                        style={{ height: 'calc(var(--doc-height) * 0.1)' }}
                                    />
                                </CTableDataCell>
                                <CTableDataCell>{item.popup.content}</CTableDataCell>
                                <CTableDataCell>
                                    {item.popup.isShownOnMain === false ? '미노출' : '노출'}
                                </CTableDataCell>
                                <CTableDataCell>{item.notice.title}</CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>
            <div className='d-flex justify-content-between'>
                <LoadingButton
                    type='button'
                    color='danger text-white'
                    isLoading={handleDeleteButton.isLoading}
                    onClick={() => {
                        handleDeleteButton.mutate()
                    }}>
                    선택삭제
                </LoadingButton>
            </div>
            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />

            {/*팝업 상세보기 모달*/}
            <CModal alignment='center' visible={viewVisible} size={'lg'} backdrop={'static'}>
                <CModalBody className={'w-100'}>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <div className='w-100 m-2 p-3 border rounded border-2'>
                            <span className='fs-4'>팝업 노출 이미지</span>
                            <div className='d-flex justify-content-around'>
                                <div className={''}>
                                    <ImageZoom src={popup.popupImage.url} height={0.2} />
                                </div>
                                <div className={'d-flex flex-column justify-content-around'}>
                                    <div>
                                        <CFormLabel htmlFor='title'>생성일</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            id='title'
                                            name='title'
                                            value={dayjs(popup.popup.createdAt).format('YYYY-MM-DD')}
                                        />
                                    </div>
                                    <div>
                                        <CFormLabel htmlFor='title'>노출상태</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            id='title'
                                            name='title'
                                            value={popup.popup.isShownOnMain === false ? '미노출' : '노출'}
                                        />
                                    </div>
                                    <div>
                                        <CFormLabel htmlFor='title'>노출날짜</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            id='title'
                                            name='title'
                                            value={popup.popup.content}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 m-2 p-3 border rounded border-2'>
                            <span className='fs-4'>팝업 연결 공지 내용</span>
                            <div>
                                <CFormLabel htmlFor='title'>제목</CFormLabel>
                                <CFormInput type='text' id='title' name='title' value={popup.notice.title} />
                            </div>
                            <div>
                                <CFormLabel htmlFor='content'>내용</CFormLabel>
                                <CFormTextarea
                                    id='content'
                                    name='content'
                                    rows={15}
                                    style={{ resize: 'none' }}
                                    value={popup.notice.content}
                                />
                            </div>
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setViewVisible(false)
                        }}>
                        확인
                    </CButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default Popup
