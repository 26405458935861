import { CButton, CFormLabel, CFormTextarea } from '@coreui/react'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import LabelAboveInput from '../../components/LabelAboveInput'
import ImageSlide from '../../components/imgae/ImageSlide'
import { IReviewDto, IReviewFileDto } from '../../interface/IReviewResponse'
import axios from '../../util/api'
import { reviewStateConverter } from '../../util/converter/reviewStateConverter'
import { dayjs } from '../../util/dayjs'
import { currencyFormatter } from '../../util/formatter'

const ReviewDetail = () => {
    const { reviewId } = useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [memo, setMemo] = useState<string>()
    const [hidden, setHidden] = useState<boolean>(true)

    const { data } = useQuery(['reviewDetail', reviewId], () => {
        return axios.get(`/api/review/detail/${reviewId}`)
    })

    const review: IReviewDto = data?.data.body.review
    const reviewImages: IReviewFileDto[] = data?.data.body.images
    const author = {
        id: data?.data.body.authorId,
        email: data?.data.body.authorEmail,
    }
    const partner = {
        id: data?.data.body.partnerId,
        name: data?.data.body.partnerName,
    }
    const requestId = data?.data.body.requestId
    const estimateAmount = data?.data.body.amount

    const changeBlockState = useMutation(
        'changeBlockState',
        () => {
            return axios.put(`/api/review/change-block`, {
                id: review.id,
                changeToBlock: !review.isBlocked,
                comment: memo,
            })
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('변경되었습니다.')
                    queryClient.invalidateQueries(['reviewDetail', reviewId])
                    setHidden(true)
                    setMemo('')
                }
            },
        },
    )

    return (
        <>
            <div className='container-fluid w-100'>
                <div className='d-flex flex-row w-100'>
                    {/*이미지 표시영역*/}
                    <div className='col-3 me-3 d-flex align-items-center'>
                        {reviewImages.length === 0 ? (
                            <span>등록된 이미지가 없습니다.</span>
                        ) : (
                            <ImageSlide
                                images={reviewImages.map((image) => {
                                    return { id: image.id, image: image.url }
                                })}
                                height={0.2}
                            />
                        )}
                    </div>
                    {/*form 표시영역*/}
                    <div className='col-9'>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput label='후기 id' col={8} readonly={true} value={review.id} />
                            <LabelAboveInput
                                label='점수'
                                col={4}
                                value={String(review.score)}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='생성일'
                                col={6}
                                value={dayjs(review.createdAt).format('YYYY년 MM월 DD일')}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='편집일'
                                col={6}
                                value={dayjs(review.updatedAt).format('YYYY년 MM월 DD일')}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='상태'
                                col={4}
                                value={reviewStateConverter(review.isBlocked)}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='차단 사유'
                                col={8}
                                value={
                                    review.isBlocked == false
                                        ? '차단되지 않은 리뷰입니다.'
                                        : review.blockReason
                                }
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='작성자 email (클릭하여 고객프로필 보기)'
                                col={6}
                                cursor='pointer'
                                onClick={() => navigate(`/user/${author.id}`)}
                                value={author.email}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='공사한 대장 (클릭하여 대장프로필 보기)'
                                col={6}
                                cursor='pointer'
                                onClick={() => navigate(`/partner/${partner.id}`)}
                                value={partner.name}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <LabelAboveInput
                                label='요청서 id (클릭하여 상세보기)'
                                col={6}
                                cursor='pointer'
                                onClick={() => navigate(`/request/${requestId}`)}
                                value={requestId}
                                readonly={true}
                            />
                            <LabelAboveInput
                                label='견적서 금액'
                                col={6}
                                cursor='pointer'
                                onClick={() => {}}
                                value={currencyFormatter(estimateAmount)}
                                readonly={true}
                            />
                        </div>
                        <div className='col-12 d-flex'>
                            <CFormLabel className='w-100 px-2' style={{ fontSize: '1rem' }}>
                                후기 내용
                                <CFormTextarea
                                    className='w-100 h-100 bg-white'
                                    style={{ resize: 'none' }}
                                    disabled={true}
                                    readOnly={true}
                                    defaultValue={review.content}
                                />
                            </CFormLabel>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end my-4'>
                    {review.isBlocked ? (
                        <CButton
                            className='fw-bold text-white m-2'
                            color='info'
                            style={{ width: '125px' }}
                            onClick={() => {
                                setHidden(!hidden)
                            }}>
                            후기 복원
                        </CButton>
                    ) : (
                        <CButton
                            className='fw-bold text-white m-2'
                            color='danger'
                            style={{ width: '125px' }}
                            onClick={() => {
                                setHidden(!hidden)
                            }}>
                            후기 차단
                        </CButton>
                    )}
                    <CButton
                        className='fw-bold text-white m-2'
                        color='primary'
                        style={{ width: '125px' }}
                        onClick={() => {
                            navigate('/review')
                        }}>
                        확인
                    </CButton>
                </div>
                {/** 복원, 삭제 버튼 클릭시 아래 나타남 */}
                <div className='d-flex flex-column my-4'>
                    <div>
                        <CFormLabel className='w-100 px-2' style={{ fontSize: '1rem' }} hidden={hidden}>
                            차단사유
                            <CFormTextarea
                                className='w-100 h-100 bg-white'
                                style={{ resize: 'none' }}
                                onChange={(e) => setMemo(e.target.value)}
                            />
                        </CFormLabel>
                    </div>
                    <div className='d-flex justify-content-end'>
                        {review.isBlocked ? (
                            <CButton
                                className='fw-bold text-white m-2'
                                color='info'
                                style={{ width: '125px' }}
                                hidden={hidden}
                                onClick={() => changeBlockState.mutate()}>
                                후기 복원처리
                            </CButton>
                        ) : (
                            <CButton
                                className='fw-bold text-white m-2'
                                color='danger'
                                style={{ width: '125px' }}
                                hidden={hidden}
                                onClick={() => changeBlockState.mutate()}>
                                후기 차단처리
                            </CButton>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewDetail
