import { CPagination, CPaginationItem } from '@coreui/react'
import React from 'react'

interface paginationBarProps {
    number: number
    hasPreviousPage: boolean
    hasNextPage: boolean
    beginPage: number
    endPage: number
    totalPages?: number
    totalElements?: number
    isFirstPage?: boolean
    isLastPage?: boolean
    size: string
    onClick: Function
}
// number: 현재 페이지
const paginationBar = ({
    number,
    hasPreviousPage,
    hasNextPage,
    beginPage,
    endPage,
    size,
    onClick,
    totalPages,
}: paginationBarProps) => {
    const onClickHandler = (e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        onClick({ page: e.currentTarget.id, size: size })
    }

    return (
        <>
            <div className='col-12 d-flex justify-content-center my-3'>
                <CPagination aria-label='Page navigation'>
                    <CPaginationItem aria-label='Previous' disabled={!hasPreviousPage}>
                        <span
                            id={`${beginPage - 2}`}
                            aria-hidden='true'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                onClickHandler(e)
                            }}>
                            &laquo;
                        </span>
                    </CPaginationItem>
                    {Array.from({ length: endPage - beginPage + 1 }, (_, i) => beginPage + i).map((page) => (
                        <CPaginationItem key={page} active={page === number} style={{ cursor: 'pointer' }}>
                            <div
                                id={`${page - 1}`}
                                onClick={(e) => {
                                    onClickHandler(e)
                                }}>
                                {page}
                            </div>
                        </CPaginationItem>
                    ))}
                    <CPaginationItem aria-label='Next' disabled={endPage === totalPages}>
                        <span
                            id={`${endPage}`}
                            aria-hidden='true'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                onClickHandler(e)
                            }}>
                            &raquo;
                        </span>
                    </CPaginationItem>
                </CPagination>
            </div>
        </>
    )
}

export default paginationBar
