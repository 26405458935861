import { IFilesResponse } from './IFilesResponse'

export interface INoticeDto {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    type: NoticeType
    title: string
    content: string
    isShownOnMain: boolean
    noticeFile?: IFilesResponse[]
    noticeContentImg?: string
}

export const noticeDtoInit: INoticeDto = {
    id: '',
    title: '',
    content: '',
    type: 'NOTICE',
    createdAt: '',
    updatedAt: '',
    isShownOnMain: true,
}

export type NoticeType = 'NOTICE' | 'BANNER' | 'POPUP'

export interface IBannerDto {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    isShownOnMain: boolean
    titleImage: IFilesResponse
    contentImage?: IFilesResponse[]
}

export const bannerDtoInit: IBannerDto = {
    id: '',
    createdAt: '',
    updatedAt: '',
    isShownOnMain: true,
    titleImage: {
        id: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: '',
        url: '',
    },
}
export interface IPopupDto {
    popup: INoticeDto
    notice: INoticeDto
    popupImage: IFilesResponse
}

export const popupDtoInit: IPopupDto = {
    popup: noticeDtoInit,
    notice: noticeDtoInit,
    popupImage: {
        id: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: '',
        url: '',
    },
}
