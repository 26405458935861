import {
    CButton,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PaginationBar from '../../components/PaginationBar'
import PaginationFilter from '../../components/PaginationFilter'
import LoadingButton from '../../components/button/LoadingButton'
import ImageSelector from '../../components/imgae/ImageSelector'
import { ImageFile } from '../../interface/IFilesResponse'
import { INoticeDto, noticeDtoInit } from '../../interface/INoticeResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import axios from '../../util/api'
import { dayjs } from '../../util/dayjs'

const Notice = () => {
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [fetchingUrl, setFetchingUrl] = useState<string>(`/api/notice/list?`)
    const [visible, setVisible] = useState<boolean>(false)
    const [notice, setNotice] = useState<INoticeDto>(noticeDtoInit)
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const [popupImage, setPopupImage] = useState<ImageFile[]>([])
    const [popupInput, setPopupInput] = useState({
        expireDate: '',
    })

    const contentRef = useRef<HTMLInputElement>(null)
    const titleRef = useRef<HTMLInputElement>(null)

    const [image, setImage] = useState<ImageFile[]>([])
    const [checkBox, setCheckBox] = useState<string[]>([])

    const { data } = useQuery(['noticeList', fetchingUrl, pagingParam.page, pagingParam.size], () => {
        return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const list: INoticeDto[] = data?.data.body
    const pagination: IPagination = data?.data.pagination

    const handleDeleteButton = useMutation(
        'deleteNotice',
        () => {
            const ids = checkBox.filter((id) => id !== '')
            return axios.put('/api/notice/delete', { ids: ids })
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('삭제되었습니다.')
                    queryClient.invalidateQueries([
                        'noticeList',
                        fetchingUrl,
                        pagingParam.page,
                        pagingParam.size,
                    ])
                }
            },
            onError: (error) => {
                alert('삭제하지 못했습니다.' + error)
            },
        },
    )

    const handleConfirmButton = useMutation(
        'createNewNotice',
        () => {
            const formData = new FormData()

            image.map((image) => {
                if (image.file instanceof File) {
                    formData.append('files', image.file)
                }
            })

            const data = {
                title: notice.title,
                content: notice.content,
                type: 'NOTICE',
                isShownOnMain: true,
            }

            formData.append('body', new Blob([JSON.stringify(data)], { type: 'application/json' }))

            return axios.post('/api/notice/create', formData)
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('공지사항이 등록되었습니다.')
                    setVisible(false)
                    queryClient.invalidateQueries([
                        'noticeList',
                        fetchingUrl,
                        pagingParam.page,
                        pagingParam.size,
                    ])
                }
            },
            onError: (error) => {
                alert('공지사항을 등록하지 못했습니다.' + error)
            },
        },
    )

    const modifyNotice = useMutation(
        'modifyNotice',
        () => {
            const formData = new FormData()

            const imageToDelete: string[] = notice.noticeFile ? notice.noticeFile.map((img) => img.id) : ['']
            for (const img of image) {
                if (img.url.includes('blob:')) {
                    formData.append('files', img.file as File)
                    continue
                }
                image.forEach((img) => {
                    if ('id' in img.file && img.url === img.url) {
                        imageToDelete.splice(imageToDelete.indexOf(img.file.id), 1)
                    }
                })
            }

            const body = {
                id: notice.id,
                title: notice.title,
                content: notice.content,
                type: 'NOTICE',
                isShowOnMain: notice.isShownOnMain,
                deleteFileIds: imageToDelete,
            }
            formData.append('body', new Blob([JSON.stringify(body)], { type: 'application/json' }))
            return axios.put('api/notice/modify/notice', formData)
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('공지사항이 수정되었습니다.')
                    setVisible(false)
                    queryClient.invalidateQueries([
                        'noticeList',
                        fetchingUrl,
                        pagingParam.page,
                        pagingParam.size,
                    ])
                }
            },
            onError: (error) => {
                alert('공지사항을 수정하지 못했습니다.' + error)
            },
        },
    )

    const searchNotice = useMutation(
        'searchNotice',
        (type: 'title' | 'content') => {
            let body = {}
            if (type === 'title') body = { title: titleRef.current?.value }
            if (type === 'content') body = { content: contentRef.current?.value }
            return axios.post('api/notice/search', body)
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    queryClient.setQueryData(
                        ['noticeList', fetchingUrl, pagingParam.page, pagingParam.size],
                        (old: any) => {
                            return {
                                ...old,
                                data: {
                                    ...old.data,
                                    pagination: null,
                                    body: data.data.body,
                                },
                            }
                        },
                    )
                }
            },
        },
    )

    const createNewPopup = useMutation(
        'createNewPopup',
        () => {
            const formData = new FormData()

            if (popupImage[0].file instanceof File) {
                formData.append('files', popupImage[0].file)
            }

            const data = {
                title: notice.id,
                content: popupInput.expireDate,
                type: 'POPUP',
                isShowOnMain: true,
            }

            formData.append('body', new Blob([JSON.stringify(data)], { type: 'application/json' }))

            console.log(formData)

            return axios.post('/api/notice/create/popup', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('등록되었습니다.')
                    queryClient.invalidateQueries(['popupList', pagingParam.page, pagingParam.size])
                    setPopupVisible(false)
                    setVisible(false)
                    setPopupInput({ expireDate: '' })
                }
            },
            onError: (error) => {
                alert('등록하지 못했습니다.' + error)
            },
        },
    )

    useEffect(() => {
        setCheckBox(
            list?.map((item) => {
                return ''
            }),
        )
    }, [data])

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>공지사항</h1>
            </div>
            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={contentRef} type='text' placeholder='내용으로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        searchNotice.mutate('content')
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={titleRef} type='text' placeholder='제목으로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        searchNotice.mutate('title')
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    color={'secondary'}
                                    onClick={() =>
                                        queryClient.invalidateQueries([
                                            'noticeList',
                                            fetchingUrl,
                                            pagingParam.page,
                                            pagingParam.size,
                                        ])
                                    }>
                                    리스트 초기화
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-1'></CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                작성일자
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                제묵
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-6'>
                                본문
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item, index) => (
                            <CTableRow key={index}>
                                <CTableDataCell>
                                    <CFormCheck
                                        checked={checkBox[index] !== ''}
                                        onChange={() => {
                                            if (checkBox[index] === '') {
                                                setCheckBox([
                                                    ...checkBox.slice(0, index),
                                                    item.id,
                                                    ...checkBox.slice(index + 1),
                                                ])
                                            } else {
                                                setCheckBox([
                                                    ...checkBox.slice(0, index),
                                                    '',
                                                    ...checkBox.slice(index + 1),
                                                ])
                                            }
                                        }}
                                    />
                                </CTableDataCell>
                                <CTableDataCell>{dayjs(item.createdAt).format('YY.MM.DD')}</CTableDataCell>
                                <CTableDataCell
                                    onClick={() => {
                                        setNotice(item)
                                        setImage(
                                            item.noticeFile?.map((file) => ({
                                                url: file.url,
                                                file: { id: file.id },
                                            })) || [],
                                        )
                                        setVisible(true)
                                    }}>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '300px' }}>
                                        {item.title}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell
                                    onClick={() => {
                                        setNotice(item)
                                        setImage(
                                            item.noticeFile?.map((file) => ({
                                                url: file.url,
                                                file: { id: file.id },
                                            })) || [],
                                        )
                                        setVisible(true)
                                    }}>
                                    <div
                                        className='d-inline-block text-truncate'
                                        style={{ maxWidth: '300px' }}>
                                        {item.content}
                                    </div>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>
            <div className='d-flex justify-content-between'>
                <CButton
                    type='button'
                    color='primary'
                    onClick={() => {
                        setVisible(true)
                    }}>
                    신규등록
                </CButton>
                <LoadingButton
                    type='button'
                    color='danger text-white'
                    isLoading={handleDeleteButton.isLoading}
                    onClick={() => {
                        const check = confirm('선택한 공지사항을 삭제합니다.')
                        check && handleDeleteButton.mutate()
                    }}>
                    선택삭제
                </LoadingButton>
            </div>
            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
            {/*작성 모달*/}
            <CModal
                scrollable={true}
                alignment='center'
                visible={visible}
                backdrop={'static'}
                size={'lg'}
                onClose={() => {
                    setPopupVisible(false)
                    setVisible(false)
                    setNotice(noticeDtoInit)
                    setImage([])
                    setPopupImage([])
                }}>
                <CModalHeader>
                    <CModalTitle>공지열람/등록/수정</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className='d-flex flex-column py-2 w-100'>
                        <div>
                            <CFormLabel htmlFor='title'>
                                {notice.title === '' ? '제목을 입력해 주세요' : '제목'}
                            </CFormLabel>
                            <CFormInput
                                type='text'
                                id='title'
                                name='title'
                                value={notice.title}
                                onChange={(e) => {
                                    setNotice({ ...notice, title: e.target.value })
                                }}
                            />
                        </div>
                        <div>
                            <CFormLabel htmlFor='content'>
                                {notice.content === '' ? '내용을 작성해주세요.' : '내용'}
                            </CFormLabel>
                            <CFormTextarea
                                id='content'
                                name='content'
                                rows={15}
                                style={{ resize: 'none' }}
                                value={notice.content}
                                onChange={(e) => {
                                    setNotice({ ...notice, content: e.target.value })
                                }}
                            />
                        </div>
                        <div>
                            <CButton
                                size={'sm'}
                                color={'primary'}
                                variant={'outline'}
                                onClick={() =>
                                    setNotice({
                                        ...notice,
                                        isShownOnMain: true,
                                    })
                                }
                                active={notice.isShownOnMain}>
                                메인에 노출
                            </CButton>
                            <CButton
                                className={'ms-2'}
                                size={'sm'}
                                color={'danger'}
                                variant={'outline'}
                                onClick={() =>
                                    setNotice({
                                        ...notice,
                                        isShownOnMain: false,
                                    })
                                }
                                active={!notice.isShownOnMain}>
                                메인에서 제거
                            </CButton>
                        </div>
                        <ImageSelector
                            title={'이미지 등록'}
                            warning={false}
                            description={'이미지를 등록할 수 있습니다.'}
                            imageList={image}
                            setImageList={(img: ImageFile[]) => setImage(img)}
                        />
                    </div>

                    {popupVisible && (
                        <div className='border border-2 rounded'>
                            <CModalHeader>
                                <CModalTitle>팝업 등록</CModalTitle>
                            </CModalHeader>
                            <CModalBody>
                                <div className='d-flex flex-column'>
                                    <CForm>
                                        <div>
                                            <ImageSelector
                                                warning={true}
                                                maxLength={1}
                                                description={'노출될 팝업이미지를 등록해주세요'}
                                                imageList={popupImage}
                                                setImageList={setPopupImage}
                                            />
                                        </div>
                                        <div className='w-50 mt-3'>
                                            <div>
                                                <CFormLabel htmlFor='title'>
                                                    팝업 노출 만료 날짜를 설정해 주세요
                                                </CFormLabel>
                                                <CFormInput
                                                    type='date'
                                                    id='expireDate'
                                                    name='expireDate'
                                                    value={popupInput.expireDate}
                                                    onChange={(e) => {
                                                        setPopupInput({
                                                            ...popupInput,
                                                            expireDate: e.target.value,
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CForm>
                                </div>
                            </CModalBody>
                            <CModalFooter>
                                <CButton
                                    color='secondary'
                                    onClick={() => {
                                        setPopupVisible(false)
                                    }}>
                                    취소
                                </CButton>
                                <LoadingButton
                                    color='primary'
                                    isLoading={createNewPopup.isLoading}
                                    onClick={() => {
                                        if (popupImage.length < 1) {
                                            alert('팝업 노출용 이미지를 등록해주세요')
                                            return
                                        }
                                        //노출기간 없는 옵션이 생긴다면, 상용 백엔드에서 날짜체크 null 처리 필요.
                                        if (!popupInput.expireDate) {
                                            alert('팝업 노출 기간을 선택해 주세요')
                                            return
                                        }
                                        createNewPopup.mutate()
                                    }}>
                                    등록
                                </LoadingButton>
                            </CModalFooter>
                        </div>
                    )}
                </CModalBody>
                <CModalFooter>
                    {notice.id && !popupVisible && (
                        <CButton
                            color='primary'
                            onClick={() => {
                                setPopupVisible(true)
                            }}>
                            팝업 생성
                        </CButton>
                    )}
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setPopupVisible(false)
                            setVisible(false)
                        }}>
                        취소
                    </CButton>
                    <LoadingButton
                        color='primary'
                        isLoading={handleConfirmButton.isLoading || modifyNotice.isLoading}
                        onClick={() => {
                            if (notice.title === '' || notice.content === '') {
                                alert('공지사항 제목과 내용을 입력해주세요.')
                                return
                            }
                            if (notice.id === '') {
                                handleConfirmButton.mutate()
                            } else {
                                modifyNotice.mutate()
                            }
                        }}>
                        {notice.id === '' ? '작성' : '수정'}
                    </LoadingButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default Notice
