import { useQuery } from 'react-query'
import axios from '../../util/api'
import { UserData } from '../../interface/IDashboardDataResponse'
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CWidgetStatsA,
} from '@coreui/react'
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilMinus } from '@coreui/icons'
import { CChart, CChartLine } from '@coreui/react-chartjs'
import { dayjs } from '../../util/dayjs'
import { getStyle } from 'chart.js/helpers'

const UserDashboard = () => {
    const root = document.getElementById('root') as HTMLElement

    const { data } = useQuery('userDashboard', () => {
        return axios.get('/api/dashboard/user')
    })

    const userData: UserData = data?.data.body

    const visitorToday = {
        guest: userData.userCountData.at(-1)?.guest ?? 0,
        member: userData.userCountData.at(-1)?.member ?? 0,
    }
    const visitorYesterday = {
        guest: userData.userCountData[userData.userCountData.length - 2]?.guest ?? 0,
        member: userData.userCountData[userData.userCountData.length - 2]?.member ?? 0,
    }
    const join = {
        today: userData.joinUserData.at(-1)?.count ?? 0,
        yesterdayDay: userData.joinUserData[userData.joinUserData.length - 2]?.count ?? 0,
    }

    return (
        <div className={'container d-flex flex-column'}>
            <div>
                <h1>이용자 정보</h1>
            </div>
            <div className={'mt-5'}>
                <h3>방문자 수</h3>
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-around py-3'}>
                <div className={'col-11 h3'}>일간</div>
                <CWidgetStatsA
                    className='col-5'
                    color='info'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>비회원</div>
                            <div className={'ms-3'}>{visitorToday.guest.toString()} 명</div>
                        </div>
                    }
                    title={
                        visitorYesterday.guest != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(100 - (visitorToday.guest / visitorYesterday.guest) * 100).toFixed(
                                    1,
                                )}
                                %{' '}
                                <CIcon
                                    icon={
                                        visitorToday.guest - visitorYesterday.guest === 0
                                            ? cilMinus
                                            : visitorToday.guest - visitorYesterday.guest > 0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartLine
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: userData.userCountData.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '비회원 방문자',
                                        backgroundColor: 'transparent',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        pointBackgroundColor: '#39f',
                                        data: userData.userCountData.map((data) => data.guest),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            userData.userCountData.reduce((prev, data) =>
                                                prev.guest > data.guest ? prev : data,
                                            ).guest + 10,
                                        max:
                                            userData.userCountData.reduce((prev, data) =>
                                                prev.guest < data.guest ? prev : data,
                                            ).guest - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CWidgetStatsA
                    className='col-5'
                    color='success'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>회원</div>
                            <div className={'ms-3'}>{visitorToday.member.toString()} 명</div>
                        </div>
                    }
                    title={
                        visitorYesterday.member != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                (
                                {Math.abs(
                                    100 - (visitorToday.member / visitorYesterday.member) * 100,
                                ).toFixed(1)}
                                %{' '}
                                <CIcon
                                    icon={
                                        visitorToday.member - visitorYesterday.member === 0
                                            ? cilMinus
                                            : visitorToday.member - visitorYesterday.member > 0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartLine
                            className='mt-3 mx-3'
                            style={{ height: '70px' }}
                            data={{
                                labels: userData.userCountData.map((data) =>
                                    dayjs(data.date).format('MM-DD'),
                                ),
                                datasets: [
                                    {
                                        label: '회원 방문자',
                                        backgroundColor: 'transparent',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        pointBackgroundColor: '#2eb85c',
                                        data: userData.userCountData.map((data) => data.member),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            userData.userCountData.reduce((prev, data) =>
                                                prev.member > data.member ? prev : data,
                                            ).member + 10,
                                        max:
                                            userData.userCountData.reduce((prev, data) =>
                                                prev.member < data.member ? prev : data,
                                            ).member - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <CChart
                    className={'col-11 mt-4'}
                    type='line'
                    data={{
                        labels: userData.userCountData.map((data) => dayjs(data.date).format('MM-DD')),
                        datasets: [
                            {
                                label: '비회원',
                                backgroundColor: '#0d6efd',
                                borderColor: '#0d6efd',
                                pointBackgroundColor: '#0d6efd',
                                pointBorderColor: '#fff',
                                data: userData.userCountData.map((data) => data.guest),
                            },
                            {
                                label: '회원',
                                backgroundColor: '#198754',
                                borderColor: '#198754',
                                pointBackgroundColor: '#198754',
                                pointBorderColor: '#fff',
                                data: userData.userCountData.map((data) => data.member),
                            },
                            {
                                label: '합계',
                                backgroundColor: '#4f5d73',
                                borderColor: '#4f5d73',
                                pointBackgroundColor: '#4f5d73',
                                pointBorderColor: '#fff',
                                data: userData.userCountData.map((data) => data.member + data.guest),
                            },
                        ],
                    }}
                    options={{
                        plugins: {
                            legend: {
                                labels: {
                                    color: getStyle(root, '--cui-body-color'),
                                },
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: getStyle(root, '--cui-border-color-translucent'),
                                },
                                ticks: {
                                    color: getStyle(root, '--cui-body-color'),
                                },
                            },
                            y: {
                                grid: {
                                    color: getStyle(root, '--cui-border-color-translucent'),
                                },
                                ticks: {
                                    color: getStyle(root, '--cui-body-color'),
                                },
                            },
                        },
                    }}
                />
                <div className={'col-11 mt-4'}>
                    <span className={'h3'}>월간 누적</span>
                    <CTable stripedColumns className={'col-11'}>
                        <CTableHead>
                            <CTableHeaderCell className={'col-1'}> </CTableHeaderCell>
                            {userData.monthlyUserCountData.map((data) => (
                                <CTableHeaderCell key={`month-${data.month}`} className={'col-1'}>
                                    {data.month.toString().substring(4)}월
                                </CTableHeaderCell>
                            ))}
                        </CTableHead>
                        <CTableBody>
                            <CTableRow>
                                <CTableDataCell>비회원</CTableDataCell>
                                {userData.monthlyUserCountData.map((data) => (
                                    <CTableDataCell key={`guest-${data.month}`}>{data.guest}</CTableDataCell>
                                ))}
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell>회원</CTableDataCell>
                                {userData.monthlyUserCountData.map((data) => (
                                    <CTableDataCell key={`member-${data.month}`}>
                                        {data.member}
                                    </CTableDataCell>
                                ))}
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell>신규가입</CTableDataCell>
                                {userData.monthlyUserCountData.map((data) => (
                                    <CTableDataCell key={`member-${data.month}`}>{data.join}</CTableDataCell>
                                ))}
                            </CTableRow>
                        </CTableBody>
                    </CTable>
                </div>
            </div>
            <div className={'mt-5'}>
                <h3>신규 가입</h3>
            </div>
            <div className={'d-flex flex-row flex-wrap col-11 justify-content-start py-3'}>
                <CWidgetStatsA
                    className='col-5'
                    color='info'
                    value={
                        <div className={'d-flex w-100 justify-content-between'}>
                            <div className={'fs-24'}>신규회원</div>
                            <div className={'ms-3'}>{join.today.toString()} 명</div>
                        </div>
                    }
                    title={
                        join.yesterdayDay != 0 && (
                            <span className='fs-20 fw-600 ms-2'>
                                ({Math.abs(100 - (join.today / join.yesterdayDay) * 100).toFixed(1)}%{' '}
                                <CIcon
                                    icon={
                                        join.today - join.yesterdayDay === 0
                                            ? cilMinus
                                            : join.today - join.yesterdayDay > 0
                                            ? cilArrowTop
                                            : cilArrowBottom
                                    }
                                />
                                )
                            </span>
                        )
                    }
                    chart={
                        <CChartLine
                            className='mt-3 mx-3'
                            style={{ height: '100px' }}
                            data={{
                                labels: userData.joinUserData.map((data) => dayjs(data.date).format('MM-DD')),
                                datasets: [
                                    {
                                        label: '신규 가입자',
                                        backgroundColor: 'transparent',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        pointBackgroundColor: '#39f',
                                        data: userData.joinUserData.map((data) => data.count),
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min:
                                            userData.joinUserData.reduce((prev, data) =>
                                                prev.count > data.count ? prev : data,
                                            ).count + 10,
                                        max:
                                            userData.joinUserData.reduce((prev, data) =>
                                                prev.count < data.count ? prev : data,
                                            ).count - 10,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 3,
                                        tension: 0.4,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
                <span>
                    어제 탈퇴 : {userData.joinUserData[userData.joinUserData.length - 1].quitCount ?? 0}
                </span>
            </div>
            {userData.providerInfoByRegion && userData.providerInfoByClassification && (
                <>
                    <div className={'mt-5'}>
                        <h3>
                            대장 회원 분포 (전체 :
                            {`${userData.providerInfoByRegion.reduce(
                                (prev, current) => prev + current.count,
                                0,
                            )})`}
                        </h3>
                    </div>
                    <div className={'d-flex flex-row flex-wrap col-11 justify-content-center py-3'}>
                        <div className={'col-7 d-flex flex-column'}>
                            <CChart
                                type='doughnut'
                                data={{
                                    labels: userData.providerInfoByRegion.map((data) => data.region),
                                    datasets: [
                                        {
                                            backgroundColor: [
                                                '#cfe2ff',
                                                '#f7d6e6',
                                                '#20c997',
                                                '#fff3cd',
                                                '#d1e7dd',
                                                '#e685b5',
                                                '#cff4fc',
                                                '#dee2e6',
                                                '#3d8bfd',
                                                '#e0cffc',
                                                '#8540f5',
                                                '#feb272',
                                                '#20c997',
                                                '#ffcd39',
                                                '#479f76',
                                                '#f1aeb5',
                                                '#3dd5f3',
                                                '#adb5bd',
                                                '#fff',
                                            ].slice(0, userData.providerInfoByRegion.length),
                                            data: userData.providerInfoByRegion.map((data) => data.count),
                                        },
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            labels: {
                                                color: getStyle(root, '--cui-body-color'),
                                            },
                                        },
                                    },
                                    animation: {
                                        duration: 1,
                                        onComplete: function (this) {
                                            const ctx = this.ctx
                                            ctx.font = getStyle(root, 'font-family')
                                            ctx.fillStyle = getStyle(root, '--cui-black')
                                            ctx.textAlign = 'center'
                                            ctx.textBaseline = 'bottom'

                                            this.data.datasets.forEach((dataset, index) => {
                                                const meta = this.getDatasetMeta(index)
                                                meta.data.forEach((bar, index) => {
                                                    // @ts-ignore
                                                    const data = `${this.data.labels[index]}: ${dataset.data[index]}`
                                                    ctx.fillText(
                                                        data as unknown as string,
                                                        bar.tooltipPosition().x,
                                                        bar.tooltipPosition().y - 5,
                                                    )
                                                })
                                            })
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className={'col-12'}>
                            <CChart
                                type='bar'
                                data={{
                                    labels: userData.providerInfoByClassification.map(
                                        (data) => data.classification,
                                    ),
                                    datasets: [
                                        {
                                            label: '전문분야별 분포',
                                            backgroundColor: '#79b0f8',
                                            data: userData.providerInfoByClassification.map(
                                                (data) => data.count,
                                            ),
                                        },
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                color: getStyle(root, '--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle(root, '--cui-body-color'),
                                            },
                                        },
                                        y: {
                                            grid: {
                                                color: getStyle(root, '--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle(root, '--cui-body-color'),
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default UserDashboard
