import React, { useEffect, useState } from 'react'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import axios from '../../util/api'
import {
    CButton,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CImage,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../../components/PaginationFilter'
import { dayjs } from '../../util/dayjs'
import PaginationBar from '../../components/PaginationBar'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPagination } from '../../interface/pagination/IPagination'
import { bannerDtoInit, IBannerDto } from '../../interface/INoticeResponse'
import ImagePreviewList from '../../components/imgae/ImagePreviewList'
import LoadingButton from '../../components/button/LoadingButton'
import ImageSelector from '../../components/imgae/ImageSelector'
import { ImageFile } from '../../interface/IFilesResponse'
import ImageZoom from '../../components/imgae/ImageZoom'

const Banner = () => {
    const queryClient = useQueryClient()

    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [visible, setVisible] = useState<boolean>(false)
    const [viewVisible, setViewVisible] = useState<boolean>(false)
    const [banner, setBanner] = useState<IBannerDto>(bannerDtoInit)
    const [checkBox, setCheckBox] = useState<string[]>([])
    const [titleImage, setTitleImage] = useState<ImageFile[]>([])
    const [contentImage, setContentImage] = useState<ImageFile[]>([])
    const [linkUrl, setLinkUrl] = useState<{ index: number; url: string }[]>([])

    const { data } = useQuery(['bannerList', pagingParam.page, pagingParam.size], () => {
        return axios.get(`/api/notice/banner/list?page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const pagination: IPagination = data?.data.pagination
    const list: IBannerDto[] = data?.data.body

    const handleDeleteButton = useMutation(
        'deleteBanner',
        () => {
            const ids = checkBox.filter((id) => id !== '')
            return axios.put('/api/notice/delete', { ids: ids })
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('삭제되었습니다.')
                    queryClient.invalidateQueries(['bannerList', pagingParam.page, pagingParam.size])
                }
            },
            onError: (error) => {
                alert('삭제하지 못했습니다.' + error)
            },
        },
    )

    const handleConfirmButton = useMutation(
        'insertNewBanner',
        () => {
            const formData = new FormData()

            if (titleImage[0].file instanceof File) {
                formData.append('title', titleImage[0].file)
            }
            contentImage.map((image) => {
                if (image.file instanceof File) {
                    formData.append('content', image.file)
                }
            })

            const data = {
                title: 'banner',
                content: 'banner',
                type: 'BANNER',
                isShownOnMain: true,
                linkUrls: linkUrl,
            }

            formData.append('body', new Blob([JSON.stringify(data)], { type: 'application/json' }))

            console.log(formData)

            return axios.post('/api/notice/create/banner', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        {
            useErrorBoundary: false,
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('등록되었습니다.')
                    queryClient.invalidateQueries(['bannerList', pagingParam.page, pagingParam.size])
                    setVisible(false)
                }
            },
            onError: (error) => {
                alert('등록하지 못했습니다.' + error)
            },
        },
    )

    const urlInput = () => {
        let element = []
        for (let i = 0; i < contentImage.length; i++) {
            element.push(
                <CFormLabel key={i}>
                    <span>{i + 1}번째 이미지의 링크설정</span>
                    <CFormInput
                        placeholder={'url 을 입력하면 해당 주소로 이동시킵니다.'}
                        value={linkUrl[i]?.url ?? ''}
                        onChange={(e) => {
                            const temp = [...linkUrl]
                            temp[i].url = e.target.value
                            setLinkUrl(temp)
                        }}
                    />
                </CFormLabel>,
            )
        }
        return element
    }

    useEffect(() => {
        setCheckBox(
            list?.map((item) => {
                return ''
            }),
        )
    }, [data])

    useEffect(() => {
        setLinkUrl(
            contentImage.map((image, index) => {
                return { index: index, url: '' }
            }),
        )
    }, [contentImage])

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>배너관리</h1>
            </div>
            <div className='my-5 row'>
                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-1'></CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                작성일자
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                타이틀이미지
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-5'>
                                첨부이미지
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item, index) => (
                            <CTableRow key={item.id}>
                                <CTableDataCell>
                                    <CFormCheck
                                        checked={checkBox[index] !== ''}
                                        onChange={() => {
                                            if (checkBox[index] === '') {
                                                setCheckBox([
                                                    ...checkBox.slice(0, index),
                                                    item.id,
                                                    ...checkBox.slice(index + 1),
                                                ])
                                            } else {
                                                setCheckBox([
                                                    ...checkBox.slice(0, index),
                                                    '',
                                                    ...checkBox.slice(index + 1),
                                                ])
                                            }
                                        }}
                                    />
                                </CTableDataCell>
                                <CTableDataCell>{dayjs(item.createdAt).format('YY.MM.DD')}</CTableDataCell>
                                <CTableDataCell
                                    onClick={() => {
                                        setBanner(item)
                                        setViewVisible(true)
                                    }}>
                                    <CImage
                                        className={'rounded rounded-2'}
                                        fluid
                                        src={item.titleImage.url}
                                        style={{ height: 'calc(var(--doc-height) * 0.1)' }}
                                    />
                                </CTableDataCell>
                                <CTableDataCell
                                    onClick={() => {
                                        setBanner(item)
                                        setViewVisible(true)
                                    }}>
                                    {item.contentImage && (
                                        <ImagePreviewList
                                            slidesPerView={3}
                                            height={0.1}
                                            images={item.contentImage.map((file) => ({
                                                id: file.id,
                                                url: file.url,
                                            }))}
                                        />
                                    )}
                                    {!item.contentImage && <span>첨부 이미지가 없습니다.</span>}
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>
            <div className='d-flex justify-content-between'>
                <CButton
                    type='button'
                    color='primary'
                    onClick={() => {
                        setVisible(true)
                    }}>
                    신규등록
                </CButton>
                <LoadingButton
                    type='button'
                    color='danger text-white'
                    isLoading={handleDeleteButton.isLoading}
                    onClick={() => {
                        handleDeleteButton.mutate()
                    }}>
                    선택삭제
                </LoadingButton>
            </div>
            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
            {/*작성 모달*/}
            <CModal
                alignment='center'
                visible={visible}
                onClose={() => {
                    setVisible(false)
                    setTitleImage([])
                    setContentImage([])
                }}>
                <CModalHeader>
                    <CModalTitle>신규등록</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className='d-flex flex-column'>
                        <CForm>
                            <div>
                                <ImageSelector
                                    warning={true}
                                    maxLength={1}
                                    description={'메인홈에 노출될 배너이미지를 등록해주세요'}
                                    imageList={titleImage}
                                    setImageList={setTitleImage}
                                />
                            </div>
                            <div>
                                <ImageSelector
                                    warning={false}
                                    description={'상세페이지에서 추가로 노출될 이미지를 등록해주세요.'}
                                    imageList={contentImage}
                                    setImageList={setContentImage}
                                />
                            </div>
                        </CForm>
                        {urlInput()}
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setVisible(false)
                        }}>
                        취소
                    </CButton>
                    <LoadingButton
                        color='primary'
                        isLoading={handleConfirmButton.isLoading}
                        onClick={() => {
                            handleConfirmButton.mutate()
                        }}>
                        등록
                    </LoadingButton>
                </CModalFooter>
            </CModal>

            <CModal
                alignment='center'
                visible={viewVisible}
                size={'lg'}
                backdrop={'static'}
                onClose={() => {
                    setViewVisible(false)
                    setBanner(bannerDtoInit)
                }}>
                <CModalBody className={'w-100'}>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <span>메인 노출 이미지</span>
                        <div className={'w-50'}>
                            <ImageZoom src={banner.titleImage.url} height={0.2} />
                        </div>
                        <span>상세 페이지 노출 이미지</span>
                        {banner.contentImage && (
                            <ImagePreviewList
                                slidesPerView={2.1}
                                spaceBetween={10}
                                height={0.25}
                                images={banner.contentImage.map((file) => ({
                                    id: file.id,
                                    url: file.url,
                                }))}
                            />
                        )}
                        {!banner.contentImage && <span>첨부 이미지가 없습니다.</span>}
                        <div className={'mt-2'}>
                            <CButton
                                size={'sm'}
                                color={'primary'}
                                variant={'outline'}
                                onClick={() =>
                                    setBanner({
                                        ...banner,
                                        isShownOnMain: true,
                                    })
                                }
                                active={banner.isShownOnMain}>
                                메인에 노출
                            </CButton>
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color='secondary'
                        onClick={() => {
                            setViewVisible(false)
                        }}>
                        확인
                    </CButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default Banner
