import PaginationBar from '../../components/PaginationBar'
import React, { useEffect, useState } from 'react'
import { IPagingParam, pagingParamInitValue } from '../../interface/pagination/IPagingParam'
import PaginationFilter from '../../components/PaginationFilter'
import axios from '../../util/api'
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { Link } from 'react-router-dom'
import { dayjs } from '../../util/dayjs'
import { useQuery } from 'react-query'
import { IUsersConstructionList } from '../../interface/IConstructionResponse'
import { IPagination } from '../../interface/pagination/IPagination'
import { currencyFormatter } from '../../util/formatter'

interface IUserTransactionProps {
    id: string | undefined
}

const UserTransaction = ({ id }: IUserTransactionProps) => {
    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)

    const { data } = useQuery(
        ['userTransaction', id, pagingParam.page, pagingParam.size],
        () => {
            return axios.get(
                `/api/construction/list-of/${id}?page=${pagingParam.page}&size=${pagingParam.size}`,
            )
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    )

    const list: IUsersConstructionList[] = data?.data.body
    const pagination: IPagination = data?.data.pagination

    useEffect(() => {
        data?.data.pagination &&
            setPagingParam({ ...pagingParam, page: String(Number(data.data.pagination.number - 1)) })
    }, [data])

    return (
        <div className='d-flex flex-column justify-content-center w-100'>
            <div className='d-flex justify-content-between mb-3 col-12'>
                <div className='col-3'>
                    <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
                </div>
                <div className='col-1 my-3 me-5'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-2'>
                                거래확정일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래상태
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                공사시작일/완료일
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                요청서 id
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                최종견적
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                거래업체
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                견적액/결제상태
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list ? (
                            list.map((item) => (
                                <CTableRow key={item.id}>
                                    <CTableDataCell>
                                        {dayjs(item.createdAt).format('YYYY-MM-DD')}
                                    </CTableDataCell>
                                    <CTableDataCell>{item.state}</CTableDataCell>
                                    <CTableDataCell>
                                        <div>
                                            {dayjs(item.startedAt).format('YYYY-MM-DD') === 'Invalid Date'
                                                ? '공사전'
                                                : dayjs(item.startedAt).format('YYYY-MM-DD')}
                                            <br />
                                            {dayjs(item.endAt).format('YYYY-MM-DD') === 'Invalid Date'
                                                ? '미완료'
                                                : dayjs(item.endAt).format('YYYY-MM-DD')}
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div
                                            className='d-inline-block text-truncate'
                                            style={{ maxWidth: '100px' }}>
                                            <Link to={`/request/${item.requestId}`}>{item.requestId}</Link>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>{currencyFormatter(item.totalAmount)}</CTableDataCell>
                                    <CTableDataCell>{item.partnerCorpName}</CTableDataCell>
                                    <CTableDataCell>{item.paymentState}</CTableDataCell>
                                </CTableRow>
                            ))
                        ) : (
                            <CTableRow>
                                <CTableDataCell colSpan={9}>목록이 없습니다.</CTableDataCell>
                            </CTableRow>
                        )}
                    </CTableBody>
                </CTable>
            </div>
        </div>
    )
}

export default UserTransaction
