import { dayjs } from '../util/dayjs'

export interface ICouponResponse {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    code: string
    state: 'ACTIVE' | 'INACTIVE' | '사용가능' | '사용불가'
    specification: {
        amount: string // 할인 금액. 금액할인이 아닐경우 0
        discount: number // 할인율. 퍼센테이지 할인이 아닐경우 0
        targetRole: 'CUSTOMER' | 'PROVIDER' | '고객' | '대장' // 쿠폰 적용 대상
        // 쿠폰 적용 제약조건
        constraint: {
            expiredAt: string // 만료일
            priceGreaterThan: string // 최소 결제금액
            region: string // 지역 대상인 경우 지역명
            classification: string // 종류 대상인 경우 공사종류
            reusable: boolean | '가능' | '불가능' // 한 사람이 여러번 쓸 수 있는지 여부
            totalStock: number | '무제한' // 쿠폰 총 발급 가능 수
        }
    }
}

export interface ICouponListResponse {
    coupon: ICouponResponse
    usedCount: number
    totalUsedAmount: string
}

export const couponInfoConverter = (coupon: ICouponListResponse): ICouponListResponse => {
    const { coupon: couponInfo, usedCount, totalUsedAmount } = coupon
    const { specification } = couponInfo
    const { constraint } = specification
    const { totalStock } = constraint
    return {
        coupon: {
            ...couponInfo,
            state: couponInfo.state === 'ACTIVE' ? '사용가능' : '사용불가',
            specification: {
                ...specification,
                targetRole: specification.targetRole === 'CUSTOMER' ? '고객' : '대장',
                constraint: {
                    ...constraint,
                    expiredAt:
                        constraint.expiredAt === null || constraint.expiredAt === undefined
                            ? '무제한'
                            : dayjs(constraint.expiredAt).format('YY.MM.DD'),
                    reusable: constraint.reusable === true ? '가능' : '불가능',
                    totalStock: totalStock === -1 ? '무제한' : totalStock,
                },
            },
        },
        usedCount,
        totalUsedAmount,
    }
}

export const couponInfoInit: ICouponListResponse = {
    coupon: {
        id: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: '',
        code: '',
        state: 'ACTIVE',
        specification: {
            amount: '',
            discount: 0,
            targetRole: 'CUSTOMER',
            constraint: {
                expiredAt: '',
                priceGreaterThan: '',
                region: '',
                classification: '',
                reusable: false,
                totalStock: 0,
            },
        },
    },
    usedCount: 0,
    totalUsedAmount: '0',
}
