import React, { useRef, useState } from 'react'
import { IPagingParam, pagingParamInitValue } from '../interface/pagination/IPagingParam'
import axios from '../util/api'
import {
    CButton,
    CButtonGroup,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import PaginationFilter from '../components/PaginationFilter'
import FilterDropDown from '../components/FilterDropDown'
import { dayjs } from '../util/dayjs'
import PaginationBar from '../components/PaginationBar'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { INotificationDto } from '../interface/INotificationResponse'
import { IPagination } from '../interface/pagination/IPagination'
import LoadingButton from '../components/button/LoadingButton'

const Alarm = () => {
    const queryClient = useQueryClient()
    const [pagingParam, setPagingParam] = useState<IPagingParam>(pagingParamInitValue)
    const [fetchingUrl, setFetchingUrl] = useState<string>(`/api/notification/list?target=both&`)
    const [visible, setVisible] = useState<boolean>(false)

    const contentRef = useRef<HTMLInputElement>(null)

    const { data } = useQuery(['alarmList', fetchingUrl, pagingParam.page, pagingParam.size], () => {
        return axios.get(`${fetchingUrl}page=${pagingParam.page}&size=${pagingParam.size}`)
    })

    const list: INotificationDto[] = data?.data.body
    const pagination: IPagination = data?.data.pagination

    const handleDeleteButton = useMutation(
        'deleteAlarm',
        () => {
            const checked: NodeListOf<HTMLInputElement> = document.querySelectorAll(
                'input[type=checkbox]:checked',
            )
            let ids: string[] = []
            checked.forEach((item: HTMLInputElement) => {
                ids.push(item.value)
            })
            return axios.delete('/api/alarm/delete', { data: { ids } })
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                } else {
                    alert('삭제되었습니다.')
                    queryClient.invalidateQueries([
                        'alarmList',
                        fetchingUrl,
                        pagingParam.page,
                        pagingParam.size,
                    ])
                }
            },
        },
    )

    const handleSendButton = useMutation(
        'sendAlarm',
        () => {
            const form: HTMLFormElement = document.forms[1]

            const data = {
                //@ts-ignore
                title: form.title.value,
                content: form.content.value,
                //@ts-ignore
                target: form.target.value,
                type: form.options.value,
            }
            return axios.post('/api/alarm/send', data)
        },
        {
            onSuccess: (data) => {
                if (data.data.error) {
                    alert(data.data.error)
                    setVisible(false)
                } else {
                    alert('알림이 전송되었습니다.')
                    setVisible(false)
                }
            },
        },
    )

    return (
        <div className='container d-flex flex-column'>
            <div>
                <h1>알림보내기</h1>
            </div>

            <div className='my-5 row'>
                {/*검색필터 */}
                <div className='col-10'>
                    <CForm className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row me-2'>
                            <CCol xs='auto'>
                                <CFormInput ref={contentRef} type='text' placeholder='내용으로 검색' />
                            </CCol>
                            <CCol xs='auto' className='px-1'>
                                <CButton
                                    type='button'
                                    className='mb-3'
                                    onClick={() => {
                                        setFetchingUrl(
                                            `/api/alarm/search/content?content=${contentRef.current?.value}&`,
                                        )
                                    }}>
                                    검색
                                </CButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>

                {/*페이징 필터*/}
                <div className='col-2 d-flex flex-column justify-content-end my-3'>
                    <PaginationFilter
                        color='secondary'
                        selected={pagingParam.size}
                        page={pagingParam.page}
                        onClick={setPagingParam}
                    />
                </div>
            </div>

            {/*테이블 */}
            <div className='text-center'>
                <CTable striped>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col' className='col-1'></CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                작성일자
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                제묵
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-5'>
                                본문
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-1'>
                                <FilterDropDown
                                    name='대상'
                                    items={[
                                        { name: '대장', value: 'PARTNER' },
                                        { name: '고객', value: 'MEMBER' },
                                        { name: '전체', value: 'ALL' },
                                    ]}
                                    requestUrl='/api/alarm/search/target?target='
                                    onClick={setFetchingUrl}
                                />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='col-2'>
                                <FilterDropDown
                                    name='알림종류'
                                    items={[
                                        { name: 'PUSH', value: 'PUSH' },
                                        { name: 'SMS', value: 'SMS' },
                                    ]}
                                    requestUrl='/api/alarm/search/type?type='
                                    onClick={setFetchingUrl}
                                />
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody style={{ lineHeight: '40px', textAlign: 'center', verticalAlign: 'middle' }}>
                        {list.map((item, index) => (
                            <CTableRow key={item.id}>
                                <CTableDataCell>
                                    <CFormCheck id={`checkbox${index}`} value={item.id} />
                                </CTableDataCell>
                                <CTableDataCell>{dayjs(item.createdAt).format('YY.MM.DD')}</CTableDataCell>
                                <CTableDataCell>{item.title}</CTableDataCell>
                                <CTableDataCell>{item.content}</CTableDataCell>
                                <CTableDataCell>
                                    {item.target === 'PARTNER'
                                        ? '대장'
                                        : item.target === 'MEMBER'
                                        ? '고객'
                                        : '전체'}
                                </CTableDataCell>
                                <CTableDataCell>{item.type}</CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </div>
            <div className='d-flex justify-content-between'>
                <CButton
                    type='button'
                    color='primary'
                    onClick={() => {
                        setVisible(true)
                    }}>
                    신규등록
                </CButton>
                <LoadingButton
                    type='button'
                    color='danger text-white'
                    isLoading={handleDeleteButton.isLoading}
                    onClick={() => {
                        handleDeleteButton.mutate()
                    }}>
                    선택삭제
                </LoadingButton>
            </div>

            {/*페이징 indicator*/}
            <PaginationBar {...pagination} size={pagingParam.size} onClick={setPagingParam} />
            {/*작성 모달*/}
            <CModal alignment='center' visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader>
                    <CModalTitle>신규 알림 작성</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className='d-flex flex-column'>
                        <CForm>
                            <div>
                                <CFormLabel htmlFor='title'>알림 타이틀을 작성해주세요.</CFormLabel>
                                <CFormInput type='text' id='title' name='title' />
                            </div>
                            <div>
                                <CFormLabel htmlFor='content'>알림 내용을 작성해주세요.</CFormLabel>
                                <CFormTextarea
                                    id='content'
                                    name='content'
                                    rows={5}
                                    style={{ resize: 'none' }}
                                />
                            </div>
                            <div className='d-flex flex-column align-items-start mt-2'>
                                알림 대상을 정해주세요.
                                <CButtonGroup
                                    role='group'
                                    aria-label='Basic target radio toggle button group'>
                                    <CFormCheck
                                        type='radio'
                                        button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                        name='target'
                                        id='ALL'
                                        value='ALL'
                                        autoComplete='off'
                                        label='전체'
                                    />
                                    <CFormCheck
                                        type='radio'
                                        button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                        name='target'
                                        id='PARTNER'
                                        value='PARTNER'
                                        autoComplete='off'
                                        label='대장'
                                    />
                                    <CFormCheck
                                        type='radio'
                                        button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                        name='target'
                                        id='MEMBER'
                                        value='MEMBER'
                                        autoComplete='off'
                                        label='고객'
                                    />
                                </CButtonGroup>
                            </div>
                            <div className='d-flex flex-column align-items-start mt-2'>
                                알림 종류를 정해주세요.
                                <div>
                                    <CFormCheck
                                        button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                        type='radio'
                                        name='options'
                                        id='push'
                                        autoComplete='off'
                                        label='PUSH'
                                        value='PUSH'
                                        defaultChecked
                                    />
                                    <CFormCheck
                                        button={{ color: 'danger', variant: 'outline', size: 'sm' }}
                                        type='radio'
                                        name='options'
                                        id='sms'
                                        value='SMS'
                                        autoComplete='off'
                                        label='SMS'
                                    />
                                </div>
                            </div>
                        </CForm>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton color='secondary' onClick={() => setVisible(false)}>
                        취소
                    </CButton>
                    <LoadingButton
                        color='primary'
                        isLoading={handleSendButton.isLoading}
                        onClick={() => {
                            handleSendButton.mutate()
                        }}>
                        전송
                    </LoadingButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default Alarm
