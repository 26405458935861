import { AxiosError } from 'axios'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import ErrorBoundary from './pages/error/ErrorBoundary'
import reportWebVitals from './reportWebVitals'

const rootNode: HTMLElement = document.getElementById('root') as HTMLElement
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError: (error) => {
                const err = error as AxiosError
                if (err.response?.status === 401) {
                    alert('로그인이 만료되었습니다. 다시 로그인해주세요. esc를 눌러주세요')
                    localStorage.clear()
                    location.replace('/login')
                }
                if (err.response?.status === 403) {
                    alert('권한이 없습니다. 관리자 계정으로 로그인 해주세요. esc를 눌러주세요')
                    localStorage.clear()
                    location.replace('/login')
                }
            },
            retry: false,
            refetchOnMount: true,
            suspense: true,
            useErrorBoundary: true,
            refetchOnWindowFocus: true,
            staleTime: 2000,
            cacheTime: 2000,
        },
        mutations: {
            onError: (error) => {
                const err = error as AxiosError
                if (err.response?.status === 401) {
                    alert('로그인이 만료되었습니다. 다시 로그인해주세요. esc를 눌러주세요')
                    localStorage.removeItem('token')
                    location.replace('/login')
                }
                if (err.response?.status === 403) {
                    alert('권한이 없습니다. 관리자 계정으로 로그인 해주세요. esc를 눌러주세요')
                    localStorage.removeItem('token')
                    location.replace('/login')
                }
            },
            retry: false,
            useErrorBoundary: false,
        },
    },
})

ReactDOM.createRoot(rootNode).render(
    <BrowserRouter>
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </ErrorBoundary>
    </BrowserRouter>,
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
