import { PointType, PointTypeKor } from '../../interface/IPointResponse'

export const pointTypeConverter = (type: PointType): PointTypeKor => {
    switch (type) {
        case 'ADJUST':
            return '조정'
        case 'PURCHASE':
            return '구매'
        case 'EVENT':
            return '이벤트'
        case 'USE':
            return '사용'
        case 'EXPIRED':
            return '만료'
    }
}
